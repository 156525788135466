import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  Tag,
  Space,
  Button,
  message,
  Image,
  Typography,
  Avatar,
  Tooltip,
  Modal,
  Input,
} from "antd";
import { helper } from "@src/controls/controlHelper";
import { Order, OrderTableProps } from "../types";
import { formatDateTime, formatMoney } from "../utils";
import styled from "styled-components";
import OrderDetail from "./OrderDetail";
import {
  UserOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ChatDrawer from "./chat/ChatDrawer";
import ChatDialog from "./chat/ChatDialog";
import axios from "axios";
import { connect } from "dva";

interface Message {
  id: string;
  content: string;
  isMine: boolean;
  timestamp: Date;
  type?: "text" | "emoji";
}

interface UserInfo {
  id: number;
  name: string;
  avatar: string;
}

interface Conversation {
  _id: string;
  userId: number;
  sellerId: number;
  userInfo: UserInfo;
  sellerInfo: UserInfo;
  createdAt: number;
  updatedAt: number;
  status: string;
}

const { Text } = Typography;

const TableContainer = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

  @media (max-width: 768px) {
    padding: 16px;
    border-radius: 8px;
  }

  .ant-table-wrapper {
    .ant-table {
      background: transparent;

      .ant-table-thead > tr > th {
        background: #f8fafc;
        font-weight: 600;
        padding: 16px 12px;
        border-bottom: 1px solid #edf2f7;

        @media (max-width: 768px) {
          padding: 12px 8px;
          white-space: nowrap;
        }

        &::before {
          display: none;
        }
      }

      .ant-table-tbody > tr {
        &:hover {
          td {
            background: #f8fafc;
          }
        }

        td {
          transition: all 0.2s ease;
          padding: 16px 12px;
          border-bottom: 1px solid #edf2f7;

          @media (max-width: 768px) {
            padding: 12px 8px;
          }
        }

        &:last-child td {
          border-bottom: none;
        }
      }
    }
  }
`;

const OrderCountText = styled.div`
  margin-bottom: 24px;
  font-size: 24px;
  color: #1a202c;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;

  &:before {
    content: "";
    display: block;
    width: 4px;
    height: 24px;
    background: #9f60fc;
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  max-width: 400px;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
    border-radius: 8px;
    padding: 12px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const ProductImage = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  flex-shrink: 0;
  border: 1px solid #edf2f7;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ProductDetails = styled.div`
  flex: 1;
  min-width: 0;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const ProductName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductVariation = styled(Text)`
  color: #666;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const id = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const CancelledTag = styled(Tag)`
  background: #fff1f0;
  color: #f5222d;
  border: 1px solid #ffa39e;
  font-size: 12px;
  margin-left: 8px;
`;

const PaymentInfo = styled.div`
  color: #666;
  font-size: 12px;
  margin-top: 4px;
`;

const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 12px;
  min-width: 150px;

  @media (max-width: 576px) {
    align-items: flex-start;
    min-width: 100%;
  }
`;

const ShipCarrier = styled.div`
  color: #666;
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 500;
`;

const ShipService = styled.div`
  color: #666;
  font-size: 12px;
  margin-bottom: 4px;
`;

const ShipOrderCode = styled.div`
  color: #666;
  font-size: 12px;
`;

const OrderCode = styled.div`
  color: #666;
  font-size: 12px;
  margin-left: auto;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
  flex-wrap: wrap;

  @media (max-width: 576px) {
    padding: 8px 0;
  }
`;

const CancelTag = styled(Tag)`
  color: #ff4d4f;
  background: #fff2f0;
  border: 1px solid #ffccc7;
  margin-left: 4px;
`;

const statusColors = {
  wait_for_confirmation: "#e6f7ff",
  wait_for_pickup: "#fff7e6",
  confirmed: "#f6ffed",
  shipping: "#e6f7ff",
  completed: "#f6ffed",
  canceled: "#fff1f0",
  refund: "#fff1f0",
  refunded: "#f5f5f5",
};

const statusTextColors = {
  wait_for_confirmation: "#1890ff",
  wait_for_pickup: "#fa8c16",
  confirmed: "#52c41a",
  shipping: "#1890ff",
  completed: "#52c41a",
  canceled: "#f5222d",
  refund: "#f5222d",
  refunded: "#595959",
};

const StatusTag = styled(Tag)<{ $status: string }>`
  min-width: 120px;
  text-align: center;
  padding: 6px 16px;
  height: 32px;
  line-height: 20px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 13px;
  background: ${(props) =>
    statusColors[props.$status as keyof typeof statusColors] || "#f0f0f0"};
  color: ${(props) =>
    statusTextColors[props.$status as keyof typeof statusTextColors] ||
    "#000000"};
  border: none;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  }
`;

const ActionButton = styled(Button)`
  margin: 0 4px;
  height: 36px;
  padding: 0 16px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  }

  &.ant-btn-primary {
    background: #9f60fc;
    border-color: #9f60fc;

    &:hover {
      background: #8c4fe3;
      border-color: #8c4fe3;
    }
  }

  &.ant-btn-dangerous {
    &:hover {
      background: #fff1f0;
      border-color: #ff4d4f;
    }
  }
`;

const ActionSpace = styled(Space)`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .ant-btn {
    height: 32px;
    padding: 0 12px;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-1px);
    }

    &.ant-btn-primary {
      background: #9f60fc;
      border-color: #9f60fc;

      &:hover {
        background: #ff6b4d;
        border-color: #ff6b4d;
      }
    }

    &.ant-btn-dangerous {
      &:hover {
        background: #fff1f0;
      }
    }
  }
`;

const ChatButton = styled(Button)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9f60fc;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;

  &:hover {
    background: #8c4fe3;
    transform: translateY(-2px);
  }

  .anticon {
    font-size: 24px;
    color: white;
  }
`;

interface OrderTableProps {
  pageId: number;
  filters: any;
  buildQueryParams: () => any;
  updatePagination: (current: number, pageSize: number) => void;
  onStatusCountsUpdate?: (counts: any) => void;
  authUser?: any;
}

const OrderTable: React.FC<OrderTableProps> = ({
  pageId,
  filters,
  buildQueryParams,
  updatePagination,
  onStatusCountsUpdate,
  authUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [detailVisible, setDetailVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [chatDrawerVisible, setChatDrawerVisible] = useState(false);
  const [chatDialogVisible, setChatDialogVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [selectedUserInfo, setSelectedUserInfo] = useState<UserInfo>();
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);

  const fetchOrders = useCallback(
    async (params: any) => {
      try {
        setLoading(true);
        const pageInfo = await helper.getPage(pageId);

        const response = await helper.callPageApi(
          pageInfo,
          "get-orders-ship",
          params
        );

        if (response?.data) {
          setOrders(
            Array.isArray(response.data.data) ? response.data.data : []
          );
          setTotal(
            typeof response.data.count === "number" ? response.data.count : 0
          );

          if (response.data.statusCounts && onStatusCountsUpdate) {
            onStatusCountsUpdate(response.data.statusCounts);
          }
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        message.error("Failed to fetch orders");
      } finally {
        setLoading(false);
      }
    },
    [pageId, onStatusCountsUpdate]
  );

  const handleConfirmOrder = async (
    id: number,
    status: "confirm" | "cancel",
    cancelReason?: string
  ) => {
    try {
      const pageInfo = await helper.getPage(pageId);
      const response: any = await helper.callPageApi(
        pageInfo,
        "confirm-order",
        {
          orderShipId: id,
          status,
          cancelReason,
        }
      );
      console.log(response, "response");

      if (response?.data?.code === 0) {
        message.success(
          status === "confirm"
            ? "Order confirmed successfully"
            : "Order cancelled successfully"
        );
        // Refresh data
        fetchOrders(buildQueryParams());
      } else {
        message.error(
          response?.data?.err?.message ||
            response?.data?.message ||
            "An error occurred"
        );
      }
    } catch (error: any) {
      console.error("Error confirming order:", error);
      message.error(error?.message || "An error occurred");
    }
  };

  const showConfirmModal = (order: Order, action: "confirm" | "cancel") => {
    const title = action === "confirm" ? "Confirm Order" : "Cancel Order";
    const content =
      action === "confirm"
        ? "Are you sure you want to confirm this order?"
        : "Are you sure you want to cancel this order?";

    if (action === "cancel") {
      Modal.confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <p>{content}</p>
            <Input.TextArea
              placeholder="Enter cancellation reason"
              onChange={(e) => setCancelReason(e.target.value)}
              rows={3}
            />
          </div>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => handleConfirmOrder(order.id, "cancel", cancelReason),
      });
    } else {
      Modal.confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okText: "Confirm",
        cancelText: "Cancel",
        onOk: () => handleConfirmOrder(order.id, "confirm"),
      });
    }
  };

  useEffect(() => {
    const params = buildQueryParams();
    fetchOrders(params);
  }, [filters, buildQueryParams]);

  const handleTableChange = (pagination: any) => {
    updatePagination(pagination.current, pagination.pageSize);
  };

  const showOrderDetail = (order: Order) => {
    setSelectedOrder(order);
    setDetailVisible(true);
  };

  const getStatusText = (status: string) => {
    const statusMap: Record<string, string> = {
      wait_for_confirmation: "Pending Confirmation",
      wait_for_pickup: "Awaiting Pickup",
      confirmed: "Confirmed",
      shipping: "Shipping",
      completed: "Completed",
      canceled: "Cancelled",
      refund: "Refund",
      refunded: "Refunded",
    };
    return statusMap[status] || status;
  };

  const handleOpenChatDrawer = () => {
    setChatDrawerVisible(!chatDrawerVisible);
  };

  const createConversation = async (customerId: number) => {
    try {
      const response = await axios.post(
        "/admin/chat/create-conversation",
        { customerId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.code === 0) {
        const conversationData = response.data.data;

        // Cập nhật state với thông tin từ API
        setSelectedUserInfo(conversationData.userInfo);
        setSelectedConversation(conversationData);

        // Mở chat dialog
        setChatDialogVisible(true);
      } else {
        message.error("Không thể tạo cuộc hội thoại");
      }
    } catch (error) {
      console.error("Lỗi khi tạo cuộc hội thoại:", error);
      message.error("Đã có lỗi xảy ra khi tạo cuộc hội thoại");
    }
  };

  const handleOpenChat = async (customerId: any) => {
    await createConversation(customerId.id);
  };

  const handleCloseChat = () => {
    setChatDialogVisible(false);
    setSelectedUser(null);
  };

  const handleCloseDrawer = () => {
    setChatDrawerVisible(false);
  };

  const handleSendMessage = (content: string, imageUrl?: string | null) => {
    // Tạo message mới
    const newMessage: Message = {
      id: Date.now().toString(), // Tạm thời dùng timestamp làm id
      content: content,
      isMine: true, // Tin nhắn của shop
      timestamp: new Date(),
      type: "text",
    };

    // Thêm vào danh sách tin nhắn
    setChatMessages((prev) => [...prev, newMessage]);

    // Log để debug
    console.log("Sending message:", {
      content,
      imageUrl,
      to: selectedUser?.id,
    });
  };

  const handleChatSelect = (
    conversationId: string,
    userInfo: UserInfo,
    conversation: Conversation
  ) => {
    setSelectedUserInfo(userInfo);
    setSelectedConversation(conversation);
    setChatDialogVisible(true);
  };

  const columns = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center" as const,
      render: (date: string) => (
        <div data-label="Created Date">{formatDateTime(date)}</div>
      ),
    },
    {
      title: "Product",
      key: "product",
      render: (_: unknown, record: Order) => (
        <div data-label="Product">
          <UserInfo>
            <Avatar size="small" icon={<UserOutlined />} />
            <Text>{record.receiveName}</Text>
            {authUser && authUser.roleSpecial === "SHOP" && (
              <MessageOutlined
                style={{ color: "#9f60fc", cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenChat({
                    id: record.customerId,
                    name: record.receiveName,
                  });
                }}
              />
            )}
            <OrderCode>Order ID: {record.id}</OrderCode>
          </UserInfo>
          {record.orderItemInfos.map((item, index) => (
            <ProductInfo key={index}>
              <ProductImage src={item.images[0]} />
              <ProductDetails>
                <ProductName>{item.name}</ProductName>
                <ProductVariation>
                  Variation: {item.attributeValues}
                </ProductVariation>
              </ProductDetails>
            </ProductInfo>
          ))}
        </div>
      ),
    },

    {
      title: "Total Order",
      dataIndex: "totalMoney",
      key: "totalMoney",
      align: "center" as const,
      render: (amount: number, record: Order) => (
        <div data-label="Total Order">
          <div>x{record.orderItemInfos[0]?.quantity || 1}</div>
          <div>{formatMoney(amount)}</div>
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      align: "center" as const,
      render: (_: unknown, record: Order) => (
        <div>
          <StatusTag $status={record.status}>
            {getStatusText(record.status)}
          </StatusTag>
          {record.status === "canceled" && (
            <Tooltip title={`Cancel reason: ${record.cancelReason}`}>
              <QuestionCircleOutlined
                style={{ marginLeft: 4, color: "#8c8c8c" }}
              />
            </Tooltip>
          )}
          {/* {record.status === 'canceled' && (
            <div style={{ marginTop: '4px', color: '#666' }}>
              Cancelled by Buyer
            </div>
          )} */}
        </div>
      ),
    },
    {
      title: "Shipping Unit",
      key: "delivery",
      align: "center" as const,
      width: 150,
      render: (_: unknown, record: Order) => (
        <DeliveryInfo>
          {record.shipCarrier && (
            <ShipCarrier>{record.shipCarrier}</ShipCarrier>
          )}
          {record.shipServiceName && (
            <ShipService>{record.shipServiceName}</ShipService>
          )}
          {record.shipOrderCodeId && (
            <ShipOrderCode>{record.shipOrderCodeId}</ShipOrderCode>
          )}
        </DeliveryInfo>
      ),
    },
    {
      title: "Actions",
      key: "action",
      align: "center" as const,
      render: (_: unknown, record: Order) => (
        <ActionSpace>
          <Button
            type="link"
            style={{ color: "#1890ff" }}
            onClick={() => showOrderDetail(record)}
          >
            View Details
          </Button>
          {record.status === "wait_for_confirmation" && (
            <>
              <ActionButton
                type="primary"
                onClick={() => showConfirmModal(record, "confirm")}
              >
                Confirm
              </ActionButton>
              <ActionButton
                danger
                onClick={() => showConfirmModal(record, "cancel")}
              >
                Cancel
              </ActionButton>
            </>
          )}
          {record.status === "confirmed" && (
            <ActionButton
              danger
              onClick={() => showConfirmModal(record, "cancel")}
            >
              Cancel
            </ActionButton>
          )}
        </ActionSpace>
      ),
    },
  ];

  return (
    <>
      <TableContainer>
        <OrderCountText>{total} Orders</OrderCountText>

        <Table
          columns={columns}
          dataSource={orders}
          loading={loading}
          rowKey="id"
          pagination={{
            total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} orders`,
            responsive: true,
            position: ["bottomCenter"],
            size: window.innerWidth <= 576 ? "small" : "default",
          }}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
      </TableContainer>

      <OrderDetail
        visible={detailVisible}
        order={selectedOrder}
        onClose={() => {
          setDetailVisible(false);
          setSelectedOrder(null);
        }}
      />

      {authUser && authUser.roleSpecial === "SHOP" && (
        <>
          <ChatDrawer
            visible={chatDrawerVisible}
            onClose={handleCloseDrawer}
            onChatSelect={handleChatSelect}
          />

          <ChatDialog
            visible={chatDialogVisible}
            onClose={handleCloseChat}
            userInfo={selectedUserInfo}
            conversation={selectedConversation}
            messages={chatMessages}
            onSendMessage={handleSendMessage}
          />

          <ChatButton
            type="primary"
            icon={<MessageOutlined />}
            onClick={handleOpenChatDrawer}
            title="Chat"
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ auth }: any) => ({
  authUser: auth.authUser,
});

export default connect(mapStateToProps)(OrderTable);

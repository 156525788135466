import dayjs from 'dayjs';

export const formatDateTime = (timestamp: number): string => {
  return dayjs(timestamp).format('DD/MM/YYYY HH:mm:ss');
};

export const formatMoney = (amount: number): string => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(amount);
}; 
// import React from 'react';
// import Widgets from './Widgets';
// import { Form } from 'antd';
// import { ISchemaEditorProperties } from '@src/controls/editors/SchemaEditor';

// const Base: React.FC<{
//   itemId?: number | string | null;
//   data?: any;
//   schema: ISchemaEditorProperties;
//   disabled?: boolean;
//   invalid?: boolean;
//   value?: any;
//   onChange?: (val: any) => void;
//   labelCol?: Record<string, any>;
//   wrapperCol?: Record<string, any>;
//   formRef?: any;
// }> = (props) => {
//   const { labelCol, wrapperCol, formRef } = props;
//   const error = () => {
//     return `${props.schema.name} là trường dữ liệu bắt buộc!`;
//   };
//   const layoutFormItem = React.useMemo(() => {
//     if (labelCol && wrapperCol) {
//       return {
//         labelCol,
//         wrapperCol,
//       };
//     }
//     return {};
//   }, [labelCol, wrapperCol]);

//   const Widget = Widgets[props.schema.widget];
//   if (!Widget) {
//     return <p>Invalid type {props.schema.widget}</p>;
//   }
//   return (
//     <Form.Item
//       name={props.schema.field}
//       label={props.schema.name}
//       rules={[{ required: props.schema.required, message: error() }]}
//       extra={
//         <span className='gx-text-light gx-font-weight-light gx-fs-sm gx-font-italic'>
//           {props.schema.intro}
//         </span>
//       }
//       {...layoutFormItem}
//     >
//       <Widget
//         // onChange={props.onChange}
//         // value={props.value === undefined ? props.schema.default : props.value}
//         schema={props.schema}
//         disabled={props.schema.disabled}
//         dataForm={props.data}
//         // invalid={this.isInvalid()}
//         formRef={formRef}
//       />
//     </Form.Item>
//   );
// };

// export default React.memo(Base);

import React from 'react';
import Widgets from './Widgets';
import { Form } from 'antd';
import { ISchemaEditorProperties } from '@src/controls/editors/SchemaEditor';
import { clone } from 'lodash';

type WidgetType = keyof typeof Widgets;

const OPERATION_ALIASES = {
  $eq: '$eq',
  $ne: '$ne',
  $gte: '$gte',
  $gt: '$gt',
  $lte: '$lte',
  $lt: '$lt',
  $not: '$not',
  $in: '$in',
  $notIn: '$notIn',
  $like: '$like',
  $notLike: '$notLike',
  $regexp: '$regexp',
  $or: '$or',
  $and: '$and',
};

const processOperationAlias = (
  operationAlias: keyof typeof OPERATION_ALIASES | '=',
  _embed: any,
  _embedExistedValue?: any,
  _embedValue?: any
) => {
  let __embedValue = _embedValue || _embed.value;
  if (__embedValue === '--null') {
    __embedValue = null;
  } else if (__embedValue === '--true') {
    __embedValue = 1;
  } else if (__embedValue === '--false') {
    __embedValue = 0;
  }
  switch (operationAlias || '=') {
    case '=':
    case OPERATION_ALIASES.$eq: {
      return __embedValue;
    }
    case OPERATION_ALIASES.$gt:
    case OPERATION_ALIASES.$gte:
    case OPERATION_ALIASES.$lt:
    case OPERATION_ALIASES.$lte: {
      if (_embedExistedValue) {
        if (
          typeof _embedExistedValue === 'object' &&
          Object.keys(_embedExistedValue)[0] === operationAlias
        ) {
          return { [operationAlias]: __embedValue, ..._embedExistedValue };
        }
      }
      return { [operationAlias]: __embedValue };
    }
    case OPERATION_ALIASES.$in: {
      if (_embedExistedValue) {
        if (
          typeof _embedExistedValue === 'object' &&
          Object.keys(_embedExistedValue)[0] === operationAlias
        ) {
          return { $in: [..._embedExistedValue[operationAlias], __embedValue] };
        }
        return { $in: [_embedExistedValue, __embedValue] };
      }
      return { $in: [__embedValue] };
    }
    case OPERATION_ALIASES.$notIn: {
      if (_embedExistedValue) {
        if (
          typeof _embedExistedValue === 'object' &&
          Object.keys(_embedExistedValue)[0] === operationAlias
        ) {
          return {
            $notIn: [..._embedExistedValue[operationAlias], __embedValue],
          };
        }
        return { $notIn: [_embedExistedValue, __embedValue] };
      }
      return { $notIn: [__embedValue] };
    }
    case OPERATION_ALIASES.$or: {
      if (_embedExistedValue) {
        if (
          typeof _embedExistedValue === 'object' &&
          Object.keys(_embedExistedValue)[0] === operationAlias
        ) {
          return { $or: [..._embedExistedValue[operationAlias], __embedValue] };
        }
        return { $or: [_embedExistedValue, __embedValue] };
      }
      return { $or: [__embedValue] };
    }
    case OPERATION_ALIASES.$and: {
      if (_embedExistedValue) {
        if (
          typeof _embedExistedValue === 'object' &&
          Object.keys(_embedExistedValue)[0] === operationAlias
        ) {
          return {
            $and: [
              ..._embedExistedValue[operationAlias],
              { [_embed.key]: __embedValue },
            ],
          };
        }
        return {
          $and: [
            { [_embed.key]: _embedExistedValue },
            { [_embed.key]: __embedValue },
          ],
        };
      }
      return { $and: [{ [_embed.key]: __embedValue }] };
    }
    default:
      return {};
  }
};

export interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const Base: React.FC<{
  itemId?: number | string | null;
  data?: any;
  schema: ISchemaEditorProperties & { widget: WidgetType };
  disabled?: boolean;
  invalid?: boolean;
  value?: any;
  onChange?: (val: any) => void;
  labelCol?: Record<string, any>;
  wrapperCol?: Record<string, any>;
  formItemProps?: Record<string, any>;
  formRef?: any;
  form?: any;
  fields: FieldData[];
}> = (props) => {
  const {
    schema,
    labelCol,
    wrapperCol,
    data,
    formRef,
    formItemProps,
    form,
    fields,
    ...rest
  } = props;

  const error = () => {
    return `${schema.name} là trường dữ liệu bắt buộc!`;
  };
  const layoutFormItem = React.useMemo(() => {
    if (labelCol && wrapperCol) {
      return {
        labelCol,
        wrapperCol,
      };
    }
    return {};
  }, [labelCol, wrapperCol]);

  const Widget = Widgets[props.schema.widget] as React.ComponentType<{
    schema: typeof props.schema;
    disabled?: boolean;
    data?: any;
    formRef?: any;
    form?: any;
    embeds?: any;
    [key: string]: any;
  }>;
  if (!Widget) {
    return <p>Invalid type {schema.widget}</p>;
  }
  const formItemProp1s: any = {};

  let _embeds: any = [];
  if (schema.embed?.length) {
    schema.embed.forEach(
      (_embed: {
        key: string;
        value: any;
        operationAlias: keyof typeof OPERATION_ALIASES;
      }) => {
        if (
          typeof _embed.value == 'string' &&
          _embed.value.substr(0, 2) === '--'
        ) {
          const k = _embed.value.substr(2);
          if (k === 'true' || k === 'false' || k === 'null') {
            const _embedExisted = _embeds.find(
              (i: { [x: string]: any }) => Object.keys(i)[0] === _embed.key
            );
            if (_embedExisted) {
              const _embedExistedValue = clone(Object.values(_embedExisted)[0]);
              const _newEmbedValue = processOperationAlias(
                _embed.operationAlias,
                _embed,
                _embedExistedValue
              );
              _embeds = _embeds.filter(
                (i: { [x: string]: any }) => Object.keys(i)[0] !== _embed.key
              );
              if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
                _embeds.push(_newEmbedValue);
              } else _embeds.push({ [_embed.key]: _newEmbedValue });
            } else {
              const _newEmbedValue = processOperationAlias(
                _embed.operationAlias,
                _embed
              );
              if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
                _embeds.push(_newEmbedValue);
              } else _embeds.push({ [_embed.key]: _newEmbedValue });
            }
          } else {
            const _embedValue =
              props.formRef?.current?.getFieldsValue()[k] ||
              (fields || []).find((f) => f.name === k)?.value;
            if (_embedValue) {
              const _embedExisted = _embeds.find(
                (i: { [x: string]: any }) => Object.keys(i)[0] === _embed.key
              );
              if (_embedExisted) {
                const _embedExistedValue = clone(
                  Object.values(_embedExisted)[0]
                );
                const _newEmbedValue = processOperationAlias(
                  _embed.operationAlias,
                  _embed,
                  _embedExistedValue,
                  _embedValue
                );
                _embeds = _embeds.filter(
                  (i: { [x: string]: any }) => Object.keys(i)[0] !== _embed.key
                );
                if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
                  _embeds.push(_newEmbedValue);
                } else _embeds.push({ [_embed.key]: _newEmbedValue });
              } else {
                const _newEmbedValue = processOperationAlias(
                  _embed.operationAlias,
                  _embed,
                  null,
                  _embedValue
                );
                if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
                  _embeds.push(_newEmbedValue);
                } else _embeds.push({ [_embed.key]: _newEmbedValue });
              }
              _embeds.push({ [_embed.key]: _embedValue });
            }
          }
        } else {
          const _embedExisted = _embeds.find(
            (i: { [x: string]: any }) => Object.keys(i)[0] === _embed.key
          );
          if (_embedExisted) {
            const _embedExistedValue = clone(Object.values(_embedExisted)[0]);
            const _newEmbedValue = processOperationAlias(
              _embed.operationAlias,
              _embed,
              _embedExistedValue
            );
            _embeds = _embeds.filter(
              (i: { [x: string]: any }) => Object.keys(i)[0] !== _embed.key
            );
            if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
              _embeds.push(_newEmbedValue);
            } else _embeds.push({ [_embed.key]: _newEmbedValue });
          } else {
            const _newEmbedValue = processOperationAlias(
              _embed.operationAlias,
              _embed
            );
            if (Object.keys(_newEmbedValue)[0] === OPERATION_ALIASES.$and) {
              _embeds.push(_newEmbedValue);
            } else _embeds.push({ [_embed.key]: _newEmbedValue });
          }
        }
      }
    );
    formItemProp1s.shouldUpdate = (prevValues: any, curValues: any) => {
      const keyShouldUpdates = _embeds.map((i: any) => Object.keys(i)[0]);
      let isUpdate = false;
      for (let index = 0; index < keyShouldUpdates.length; index++) {
        const element = keyShouldUpdates[index];
        if (prevValues[element] !== curValues[element]) {
          isUpdate = true;

          break;
        }
      }
      return isUpdate;
    };
  }
  const _fieldConditions: any = {};
  if (schema.fieldCondition && schema.fieldCondition.length) {
    for (let index = 0; index < schema.fieldCondition.length; index++) {
      const _fieldCondition = schema.fieldCondition[index];
      let _fieldConditionValue;
      const thisValue = props.formRef.current.getFieldValue(schema.field);
      if (
        typeof _fieldCondition.value == 'string' &&
        _fieldCondition.value.substr(0, 2) === '--'
      ) {
        const k = _fieldCondition.value.substr(2);
        _fieldConditionValue = props.formRef.current.getFieldValue(k);
      } else if (typeof _fieldCondition.value == 'string') {
        _fieldConditionValue = _fieldCondition.value;
      }

      switch (_fieldCondition.operationAlias || '=') {
        case '=':
        case OPERATION_ALIASES.$eq: {
          if (thisValue !== _fieldConditionValue) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$gt: {
          if (thisValue <= _fieldConditionValue) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$gte: {
          if (thisValue < _fieldConditionValue) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$lt: {
          if (thisValue >= _fieldConditionValue) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$lte: {
          if (thisValue > _fieldConditionValue) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$in: {
          let _arrFieldConditionValue = [];
          try {
            _arrFieldConditionValue = JSON.parse(_fieldConditionValue);
          } catch (error) {
            console.log('🚀 ~ file: Base.tsx:504 ~ error:', error);
          }
          if (
            !(
              _arrFieldConditionValue.length > 0 &&
              _arrFieldConditionValue.includes(thisValue)
            )
          ) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$notIn: {
          let _arrFieldConditionValue = [];
          try {
            _arrFieldConditionValue = JSON.parse(_fieldConditionValue);
          } catch (error) {
            console.log('🚀 ~ file: Base.tsx:525 ~ error:', error);
          }
          if (
            _arrFieldConditionValue.length > 0 &&
            _arrFieldConditionValue.includes(thisValue)
          ) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        case OPERATION_ALIASES.$regexp: {
          if (!new RegExp(_fieldConditionValue).test(thisValue)) {
            _fieldConditions[_fieldCondition.key] = {
              hasFeedback: true,
              validateStatus: 'error',
              help: _fieldCondition.help || 'Đã có lỗi xảy ra',
            };
          } else {
            delete _fieldConditions[_fieldCondition.key];
          }
          break;
        }
        default:
          break;
      }
      if (Object.keys(_fieldConditions).length > 0) {
        break;
      }
    }
  }
  const _validate: any = Object.values(_fieldConditions).length
    ? Object.values(_fieldConditions)[0]
    : {};
  return (
    <Form.Item
      name={schema.field}
      label={schema.name}
      rules={[{ required: schema.required, message: error() }]}
      extra={
        <span className='gx-text-light gx-font-weight-light gx-fs-sm gx-font-italic'>
          {schema.intro}
        </span>
      }
      {...layoutFormItem}
      {...formItemProps}
      // shouldUpdate={(prevValues, curValues) => prevValues[`${shouldUpdate.target}`] != curValues[`${shouldUpdate.target}`]}
      {...formItemProp1s}
      {..._validate}
    >
      <Widget
        // onChange={props.onChange}
        // value={props.value === undefined ? props.schema.default : props.value}
        schema={schema}
        disabled={schema.disabled}
        // invalid={this.isInvalid()}
        data={data}
        formRef={formRef}
        form={form}
        embeds={_embeds}
        {...(schema.restProps || {})}
        {...rest}
      />
    </Form.Item>
  );
};

export default Base;

import React, { useRef, useState } from 'react';
import { Input, Upload } from 'antd';
import styled from 'styled-components';
import { SmileOutlined, PictureOutlined, SendOutlined, ShoppingCartOutlined, GiftOutlined } from '@ant-design/icons';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { MESSAGE_TYPE } from '@src/types/chat';

const InputContainer = styled.div`
  padding: 12px;
  background: white;
  border-top: 1px solid #f0f0f0;
  position: relative;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  background: #f0f2f5;
  border-radius: 20px;
  padding: 8px 12px;
`;

const StyledInput = styled(Input.TextArea)`
  border: none;
  background: transparent;
  resize: none;
  padding: 0;

  &.ant-input {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
`;

const IconButton = styled.button<{ $active?: boolean }>`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: ${(props) => (props.$active ? "#0073e6" : "#0084ff")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transform: ${(props) => (props.$active ? "scale(1.1)" : "scale(1)")};

  &:hover {
    color: #0073e6;
  }
`;

const EmojiPickerWrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 84px;
  right: ${(props) => (props.visible ? "650px" : "640px")};
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  transform-origin: bottom right;
  transition: all 0.2s ease;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) =>
    props.visible
      ? "scale(1) translate(0, 0)"
      : "scale(0.7) translate(50%, 20%)"};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};

  .EmojiPickerReact {
    border: none !important;
    box-shadow: none !important;
  }
`;

const ImagePreviewContainer = styled.div`
  padding: 8px 12px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
`;

const PreviewImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
  object-fit: cover;
`;

const RemovePreviewButton = styled.button`
  cursor: pointer;
  padding: 4px;
  color: #999;
  transition: all 0.3s;
  margin-left: 8px;
  background: none;
  border: none;

  &:hover {
    color: #666;
  }
`;

interface ChatInputProps {
  onSendMessage: (content: string, type?: MESSAGE_TYPE, attachments?: string[]) => void;
  onImageUpload: (file: File) => Promise<string | null>;
  onTypingStart?: () => void;
  onTypingEnd?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  showOrderButton?: boolean;
  onOrderButtonClick?: () => void;
  showProductButton?: boolean;
  onProductButtonClick?: () => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  onImageUpload,
  onTypingStart,
  onTypingEnd,
  onFocus,
  onBlur,
  showOrderButton = false,
  onOrderButtonClick,
  showProductButton = false,
  onProductButtonClick
}) => {
  const [inputValue, setInputValue] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const inputRef = useRef<any>(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();

  const handleSendMessage = () => {
    if (inputValue.trim() || previewImageUrl) {
      if (previewImageUrl) {
        onSendMessage(inputValue.trim(), MESSAGE_TYPE.IMAGE, [previewImageUrl]);
      } else {
        onSendMessage(inputValue.trim());
      }
      setInputValue('');
      setPreviewImageUrl(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    
    if (onTypingStart && onTypingEnd) {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      onTypingStart();

      typingTimeoutRef.current = setTimeout(() => {
        onTypingEnd();
      }, 1500);
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      const imageUrl = await onImageUpload(file);
      if (imageUrl) {
        setPreviewImageUrl(imageUrl);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
    return false;
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    const cursor = inputRef.current?.resizableTextArea?.textArea?.selectionStart || inputValue.length;
    const text = inputValue;
    const newText = text.slice(0, cursor) + emojiData.emoji + text.slice(cursor);
    setInputValue(newText);
    
    if (inputRef.current?.resizableTextArea?.textArea) {
      const input = inputRef.current.resizableTextArea.textArea;
      input.focus();
      const newCursor = cursor + emojiData.emoji.length;
      input.setSelectionRange(newCursor, newCursor);
    }
  };

  return (
    <InputContainer>
      <EmojiPickerWrapper
        visible={showEmojiPicker}
        className="emoji-picker-container"
      >
        <EmojiPicker 
          onEmojiClick={onEmojiClick} 
          width={320} 
          height={400} 
        />
      </EmojiPickerWrapper>

      {previewImageUrl && (
        <ImagePreviewContainer>
          <PreviewImage src={previewImageUrl} alt="Preview" />
          <RemovePreviewButton onClick={() => setPreviewImageUrl(null)}>×</RemovePreviewButton>
        </ImagePreviewContainer>
      )}

      <InputWrapper>
        <IconButton
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="emoji-button"
          $active={showEmojiPicker}
        >
          <SmileOutlined />
        </IconButton>

        <StyledInput
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onPressEnter={(e) => {
            if (!e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder="Nhập tin nhắn..."
          autoSize={{ minRows: 1, maxRows: 4 }}
          bordered={false}
        />

        <Upload
          beforeUpload={handleImageUpload}
          showUploadList={false}
          accept="image/*"
        >
          <IconButton>
            <PictureOutlined />
          </IconButton>
        </Upload>

        {showOrderButton && (
          <IconButton onClick={onOrderButtonClick}>
            <ShoppingCartOutlined />
          </IconButton>
        )}

        {showProductButton && (
          <IconButton onClick={onProductButtonClick}>
            <GiftOutlined />
          </IconButton>
        )}

        {(inputValue.trim() || previewImageUrl) && (
          <IconButton onClick={handleSendMessage}>
            <SendOutlined />
          </IconButton>
        )}
      </InputWrapper>
    </InputContainer>
  );
};

export default ChatInput; 
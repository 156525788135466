import React, { useEffect, useState } from "react";
import { Card, Button, Typography, message, Spin, Form, Input } from "antd";
import { KeyOutlined } from "@ant-design/icons";
import "./Account.css";
import Captcha from "@src/packages/pro-component/schema/Captcha";
import axios from "axios";
axios.defaults.baseURL = `${
  process.env.REACT_APP_URL ? process.env.REACT_APP_URL : window.location.origin
}/api`;

const { Title, Text } = Typography;

// Types
interface AccountInfo {
  storeName: string;
  email: string;
  createdAt: string;
  logo: string;
  address: string;
  username: string;
}

interface CreateAccountForm {
  username: string;
  password: string;
  confirmPassword: string;
}

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const Account: React.FC = (props: any) => {
  // Hooks
  const [loading, setLoading] = useState<boolean>(true);
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
  const [captchaValue, setCaptchaValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [isCheckComplete, setIsCheckComplete] = useState<boolean>(false);
  const [resetPasswordForm] = Form.useForm();
  const [showResetForm, setShowResetForm] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [resetCaptchaValue, setResetCaptchaValue] = useState<string>("");

  const token = localStorage.getItem("token");
  const storeId = Number(props.query.id);

  // API Calls
  const checkAccountStatus = async () => {
    try {
      const response = await axios.post(
        "/admin/seller/check-account-exist",
        {
          storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data;
      console.log("data shop", data);
      setHasAccount(data.exist);
      if (data.exist) {
        setAccountInfo(data.accountInfo);
      }
      setIsCheckComplete(true);
    } catch (error) {
      message.error("Failed to check account status");
      setIsCheckComplete(false);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (values: ResetPasswordForm) => {
    if (!resetCaptchaValue) {
      message.error("Please enter the captcha");
      return;
    }

    setIsResetting(true);
    try {
      const captcha = resetCaptchaValue.split(",");
      const response = await axios.post(
        "/admin/seller/reset-password",
        {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          captchaText: captcha[1],
          tokenCapcha: captcha[0],
          storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.code === 0) {
        message.success("Password reset successfully");
        setShowResetForm(false);
        resetPasswordForm.resetFields();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to reset password";
      message.error(errorMessage);
    } finally {
      setIsResetting(false);
    }
  };

  const handleCreateAccount = async (values: CreateAccountForm) => {
    if (!captchaValue) {
      message.error("Please enter the captcha");
      return;
    }

    setIsSubmitting(true);
    try {
      const captcha = captchaValue.split(",");
      const response = await axios.post(
        "/admin/seller/create-account",
        {
          username: values.username,
          password: values.password,
          confirmPassword: values.confirmPassword,
          captchaText: captcha[1],
          tokenCapcha: captcha[0],
          storeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.code === 0) {
        message.success("Account created successfully");
        await checkAccountStatus();
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to create account";
      message.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Effects
  useEffect(() => {
    checkAccountStatus();
  }, []);

  // Render helpers
  const renderCreateAccountForm = () => (
    <div className="create-account-form">
      <Title level={3}>Create Seller Account</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleCreateAccount}
        className="form"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please enter username" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter password" },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
              message:
                "Password must be at least 8 characters and contain both letters and numbers",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label="Verification Code">
          <Captcha onChange={(value) => setCaptchaValue(value)} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            className="submit-btn"
            block
          >
            Create Account
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const renderResetPasswordForm = () => (
    <div className="reset-password-form">
      <Title level={4}>Reset Password</Title>
      <Form
        form={resetPasswordForm}
        layout="vertical"
        onFinish={handleResetPassword}
        className="form"
      >
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please enter password" },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
              message:
                "Password must be at least 8 characters and contain both letters and numbers",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm new password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item label="Verification Code">
          <Captcha onChange={(value) => setResetCaptchaValue(value)} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isResetting}
            className="submit-btn"
            block
          >
            Reset Password
          </Button>
          <Button
            onClick={() => setShowResetForm(false)}
            className="cancel-btn"
            block
            style={{ marginTop: 8 }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const renderAccountInfo = () => (
    <div className="account-info">
      <Title level={3}>Seller Account Information</Title>
      {accountInfo?.logo && (
        <div className="info-item logo-container">
          <img src={accountInfo.logo} alt="Store Logo" className="store-logo" />
        </div>
      )}
      <div className="info-item">
        <Text strong>Username:</Text>
        <Text>{accountInfo?.username}</Text>
      </div>
      <div className="info-item">
        <Text strong>Store Name:</Text>
        <Text>{accountInfo?.storeName}</Text>
      </div>
      <div className="info-item">
        <Text strong>Email:</Text>
        <Text>{accountInfo?.email}</Text>
      </div>
      <div className="info-item">
        <Text strong>Address:</Text>
        <Text>{accountInfo?.address}</Text>
      </div>
      <div className="info-item">
        <Text strong>Created At:</Text>
        <Text>{accountInfo?.createdAt}</Text>
      </div>
      {!showResetForm ? (
        <Button
          type="primary"
          icon={<KeyOutlined />}
          onClick={() => setShowResetForm(true)}
          className="reset-password-btn"
        >
          Reset Password
        </Button>
      ) : (
        renderResetPasswordForm()
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="account-container">
        <Spin size="large" />
      </div>
    );
  }

  if (!isCheckComplete) {
    return (
      <div className="account-container">
        <Card className="account-card">
          <Text>
            Unable to load account information. Please try again later.
          </Text>
        </Card>
      </div>
    );
  }

  return (
    <div className="account-container">
      <Card className="account-card">
        {!hasAccount ? renderCreateAccountForm() : renderAccountInfo()}
      </Card>
    </div>
  );
};

export default Account;

import React, { useState, useEffect, useRef } from 'react';
import { Input, Spin } from 'antd';
import styled from 'styled-components';
import { helper } from "@src/controls/controlHelper";
import { debounce } from 'lodash';

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const SuggestionItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const LoadMoreContainer = styled.div`
  text-align: center;
  padding: 8px;
  border-top: 1px solid #f0f0f0;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

interface VariationNameInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  pageId: number;
}

const PAGE_SIZE = 4;

const VariationNameInput: React.FC<VariationNameInputProps> = ({
  value,
  onChange,
  placeholder,
  pageId
}) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchSuggestions = async (text: string, currentPage: number, append: boolean = false) => {
    try {
      setLoading(true);
      const pageInfo = await helper.getPage(pageId);
      const queryInput = text ? JSON.stringify({
        name: {
          contains: text
        }
      }) : {};
      
      const response: any = await helper.callPageApi(
        pageInfo,
        "find_attribute",
        { 
          queryInput,
          limit: PAGE_SIZE,
          skip: (currentPage - 1) * PAGE_SIZE
        }
      );

      if (response?.data) {
        setTotal(response.data.count || 0);
        if (append) {
          setSuggestions(prev => [...prev, ...response.data.data]);
        } else {
          setSuggestions(response.data.data);
        }
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = debounce((text: string) => {
    setPage(1);
    fetchSuggestions(text, 1);
  }, 2000);

  useEffect(() => {
    if (value) {
      setSearchText(value);
      debouncedFetch(value);
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [value]);

  const handleInputFocus = () => {
    setPage(1);
    fetchSuggestions('', 1);
    setShowSuggestions(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSuggestionClick = (suggestion: any) => {
    onChange(suggestion.name);
    setShowSuggestions(false);
  };

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchSuggestions(searchText, nextPage, true);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && !loading && suggestions.length < total) {
      handleLoadMore();
    }
  };

  return (
    <div style={{ position: 'relative' }} ref={inputRef}>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const newValue = e.target.value;
          onChange(newValue);
          setSearchText(newValue);
        }}
        onFocus={handleInputFocus}
      />
      {showSuggestions && (suggestions.length > 0 || loading) && (
        <SuggestionsContainer ref={containerRef} onScroll={handleScroll}>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={`${suggestion.id}-${index}`}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.name}
            </SuggestionItem>
          ))}
          {loading && (
            <LoadMoreContainer>
              <Spin size="small" />
            </LoadMoreContainer>
          )}
          {!loading && suggestions.length < total && (
            <LoadMoreContainer onClick={handleLoadMore}>
              Load more
            </LoadMoreContainer>
          )}
        </SuggestionsContainer>
      )}
    </div>
  );
};

export default VariationNameInput; 
import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Modal } from 'antd';
import { EyeOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import Image from './Image';
import { Image as AntImage } from 'antd';
import styled from 'styled-components';

interface ArrayImageProps {
  value?: string[] | { url: string }[];
  onChange?: (value: string[]) => void;
  onAddClick?: () => void;
  schema?: any;
}

const ArrayImage: React.FC<ArrayImageProps> = ({
  value,
  onChange,
  onAddClick,
  schema,
}) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      const urls = value.map((item) =>
        typeof item === 'string' ? item : item.url
      );
      setImages(urls);
    } else {
      setImages([]);
    }
  }, [value]);

  const handleImageChange = (index: number) => (newValue: any) => {
    const newImages = [...images];
    newImages[index] =
      typeof newValue === 'string' ? newValue : newValue.file.response;
    setImages(newImages);
    onChange?.(newImages);
  };

  const handleAddImage = () => {
    if (images.length < 5) {
      setImages([...images, '']);
    }
  };

  const handleRemoveImage = (index: number) => {
    Modal.confirm({
      title: 'Xoá hình ảnh',
      content: 'Bạn có chắc chắn muốn xóa hình ảnh này?',
      okText: 'Đồng ý',
      onOk: () => {
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
        onChange?.(newImages);
      },
    });
  };

  return (
    <AntImage.PreviewGroup>
      <ImageGrid gutter={[8, 8]}>
        {images.map((image, index) => (
          <Col key={index} xs={12} sm={8} md={6} lg={4}>
            <ImageContainer>
              {image ? (
                <StyledAntImage
                  src={image}
                  alt={`Image ${index + 1}`}
                  preview={{
                    mask: <EyeOutlined style={{ fontSize: '18px' }} />,
                  }}
                />
              ) : (
                <Image
                  value={image}
                  onChange={handleImageChange(index)}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              )}
              <DeleteButton
                icon={<DeleteOutlined style={{ fontSize: '10px' }} />}
                onClick={() => handleRemoveImage(index)}
              />
            </ImageContainer>
          </Col>
        ))}
        {images.length < 5 && (
          <Col xs={12} sm={8} md={6} lg={4}>
            <AddImageButton onClick={handleAddImage}>
              <PlusOutlined style={{ fontSize: 20, color: '#1890ff' }} />
              <span>Add</span>
            </AddImageButton>
          </Col>
        )}
      </ImageGrid>
    </AntImage.PreviewGroup>
  );
};

const ImageGrid = styled(Row)`
  margin-top: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  width: 100%;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StyledAntImage = styled(AntImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  padding: 0;
  font-size: 10px;

  .anticon {
    font-size: 10px;
  }

  &:hover {
    background: rgba(255, 59, 48, 0.8);
    color: white;
  }
`;

const AddImageButton = styled.div`
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  aspect-ratio: 1 / 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: #1890ff;
    background-color: #f0f8ff;
  }

  span {
    margin-top: 4px;
    color: #1890ff;
    font-size: 12px;
  }
`;

export default ArrayImage;

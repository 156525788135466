import React, { useState } from "react";
import {
  Card,
  Tabs,
  Input,
  Button,
  Space,
  Select,
  Radio,
  RadioChangeEvent,
  DatePicker,
} from "antd";
import { helper } from "@src/controls/controlHelper";
import OrderTable from "./components/OrderTable";
import { OrderStatus } from "./types";
import { useOrderFilters } from "./hooks/useOrderFilters";
import styled from "styled-components";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const PAGE_ID = 317;

const StyledCard = styled(Card)`
  margin: 24px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  .ant-card-body {
    padding: 0;
  }

  @media (max-width: 768px) {
    margin: 12px;
    border-radius: 12px;
  }
`;

const FilterContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #edf2f7;

  @media (max-width: 768px) {
    padding: 16px;
    gap: 12px;
  }

  .ant-picker {
    height: 40px;
    border-radius: 8px;
    border-color: #e2e8f0;
    
    .ant-picker-input > input {
      font-size: 14px;
    }
  }
`;

const SearchGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex: 1;
  min-width: 300px;

  .ant-select {
    min-width: 160px;
    
    .ant-select-selector {
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border-color: #e2e8f0;
    }

    .ant-select-arrow {
      position: absolute;
      right: 11px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      top: 0;
      transform: none;
      margin: 0;
    }
  }

  .ant-input-search {
    flex: 1;
    min-width: 200px;
    max-width: 320px;
    
    .ant-input-wrapper {
      .ant-input {
        height: 40px;
        border-radius: 8px 0 0 8px;
        border-color: #e2e8f0;
      }
      .ant-input-search-button {
        height: 40px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .ant-btn {
    height: 36px;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const SubTabs = styled(Radio.Group)`
  margin: 16px 0;
  padding: 0 16px;

  .ant-radio-button-wrapper {
    height: 32px;
    line-height: 30px;
    border-radius: 16px;
    margin-right: 8px;
    padding: 0 16px;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;

    &:first-child {
      border-radius: 16px;
    }

    &:last-child {
      border-radius: 16px;
    }

    &::before {
      display: none;
    }

    &:hover {
      color: #9f60fc;
      border-color: #9f60fc;
    }

    &.ant-radio-button-wrapper-checked {
      border-color: #9f60fc;
      color: #9f60fc;
      background: #fff1f0;
    }
  }

  @media (max-width: 768px) {
    padding: 0 12px;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  border-bottom: 1px solid #f0f0f0;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    padding: 0 16px;
    
    .ant-tabs-tab {
      padding: 12px 0;
      margin: 0 16px 0 0;
      transition: all 0.3s;
      
      &:hover {
        color: #9f60fc;
      }
      
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #9f60fc;
        font-weight: 500;
      }
    }

    .ant-tabs-ink-bar {
      background: #9f60fc;
    }
  }

  @media (max-width: 768px) {
    .ant-tabs-nav {
      padding: 0 12px;
      
      .ant-tabs-tab {
        margin: 0 12px 0 0;
        padding: 8px 0;
      }
    }
  }
`;

export default function OrderListCtrl() {
  const [activeTab, setActiveTab] = useState<string>("all");
  const {
    filters,
    updateFilter,
    resetFilters,
    buildQueryParams,
    updatePagination,
  } = useOrderFilters();
  const [searchType, setSearchType] = useState("id");
  const [searchValue, setSearchValue] = useState("");
  const [statusCounts, setStatusCounts] = useState({
    wait_for_confirmation: 0,
    wait_for_pickup: 0,
    shipping: 0,
    completed: 0,
    canceled: 0,
    refund: 0,
    refunded: 0,
  });
  const [dateRange, setDateRange] = useState<any>(null);

  const searchPlaceholders: Record<string, string> = {
    id: "Enter Order ID",
    buyerName: "Enter Buyer Name",
    product: "Enter Product Name",
    shipCode: "Enter Shipping Code",
    date: "Chọn khoảng thời gian",
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    updateFilter("status", key === "all" ? "" : key);
    updateFilter("subStatus", "all");
  };

  const handleSubStatusChange = (e: RadioChangeEvent) => {
    updateFilter("subStatus", e.target.value);
  };

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value);
    setSearchValue("");
    if (value !== 'date') {
      updateFilter('dateRange', undefined);
    }
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleApplySearch = () => {
    const queryInput: Record<string, any> = {};

    if (searchValue && searchType !== 'date') {
      switch (searchType) {
        case "id":
          queryInput.id = searchValue;
          break;
        case "buyerName":
          queryInput.receiveName = { contains: searchValue };
          break;
        case "product":
          queryInput["orderItemInfos.name"] = { contains: searchValue };
          break;
        case "shipCode":
          queryInput.shipOrderCodeId = searchValue;
          break;
      }
      updateFilter("queryInput", queryInput);
    }
  };

  const getTotalOrders = () => {
    return Object.values(statusCounts).reduce((acc, curr) => acc + curr, 0);
  };

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
    if (dates) {
      updateFilter('dateRange', [
        dates[0].startOf('day').valueOf(),
        dates[1].endOf('day').valueOf()
      ]);
    } else {
      updateFilter('dateRange', undefined);
    }
  };

  return (
    <StyledCard>
      <TabsContainer>
        <StyledTabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab={`All`} key="all" />
          <TabPane
            tab={`Pending Confirmation (${statusCounts.wait_for_confirmation})`}
            key="wait_for_confirmation"
          />
          <TabPane
            tab={`Awaiting Pickup (${statusCounts.wait_for_pickup})`}
            key="wait_for_pickup"
          />
          <TabPane tab={`Shipping (${statusCounts.shipping})`} key="shipping" />
          <TabPane
            tab={`Completed (${statusCounts.completed})`}
            key="completed"
          />
          <TabPane
            tab={`Return/Refund/Cancel (${
              statusCounts.canceled +
              statusCounts.refund +
              statusCounts.refunded
            })`}
            key="canceled"
          />
        </StyledTabs>

        {activeTab === "wait_for_confirmation" && (
          <SubTabs value={filters.subStatus} onChange={handleSubStatusChange}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="pending">
              Unprocessed ({statusCounts.wait_for_confirmation})
            </Radio.Button>
            <Radio.Button value="processed">Processed (0)</Radio.Button>
          </SubTabs>
        )}
      </TabsContainer>

      <FilterContainer>
        <SearchGroup>
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            style={{ width: 140 }}
            className="search-select"
          >
            <Select.Option value="id">Order ID</Select.Option>
            <Select.Option value="buyerName">Buyer Name</Select.Option>
            <Select.Option value="product">Product</Select.Option>
            <Select.Option value="shipCode">Shipping Code</Select.Option>
            <Select.Option value="date">Created Date</Select.Option>
          </Select>
          
          {searchType === 'date' ? (
            <RangePicker 
              value={dateRange}
              onChange={handleDateRangeChange}
              style={{ width: 280 }}
              placeholder={['From Date', 'To Date']}
              format="DD/MM/YYYY"
            />
          ) : (
            <Input.Search
              placeholder={searchPlaceholders[searchType]}
              value={searchValue}
              onChange={handleSearchValueChange}
              onSearch={handleApplySearch}
            />
          )}
          
          <ActionButtons>
            <Button
              type="primary"
              style={{ backgroundColor: "#9f60fc", borderColor: "#9f60fc" }}
              onClick={handleApplySearch}
            >
              Apply
            </Button>
            <Button
              onClick={() => {
                resetFilters();
                setSearchValue("");
                setDateRange(null);
                setSearchType("id");
              }}
            >
              Reset
            </Button>
          </ActionButtons>
        </SearchGroup>
      </FilterContainer>

      <OrderTable
        pageId={PAGE_ID}
        filters={filters}
        buildQueryParams={buildQueryParams}
        updatePagination={updatePagination}
        onStatusCountsUpdate={setStatusCounts}
      />
    </StyledCard>
  );
}

import React, { forwardRef } from 'react';
import { Avatar, Tag } from 'antd';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  UserOutlined,
  ShoppingCartOutlined,
  CopyOutlined,
  DownOutlined,
  UpOutlined,
  TagOutlined
} from '@ant-design/icons';
import { MESSAGE_TYPE, ORDER_STATUS } from '@src/types/chat';
import { formatCurrency } from '@src/util/format';

const MessageListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  background: #f5f5f5;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const MessageItem = styled.div<{ $isMine: boolean }>`
  display: flex;
  flex-direction: ${props => props.$isMine ? 'row-reverse' : 'row'};
  align-items: flex-end;
  gap: 8px;
  max-width: 75%;
  ${props => props.$isMine ? 'margin-left: auto;' : 'margin-right: auto;'}
  margin-bottom: 2px;
  position: relative;
`;

const MessageContent = styled.div<{ $isMine: boolean; $isImage?: boolean }>`
  background: ${props => props.$isImage ? '#f5f5f5' : props.$isMine ? "#e7ffdb" : "#ffffff"};
  color: #111b21;
  padding: ${props => props.$isImage ? '4px' : '8px 12px'};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  max-width: 100%;
  word-wrap: break-word;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  position: relative;
`;

const MessageImage = styled.img`
  max-width: 180px;
  max-height: 180px;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s ease;
  object-fit: cover;
  
  &:hover {
    transform: scale(1.02);
  }
`;

const MessageTime = styled.div<{ $isMine?: boolean }>`
  font-size: 12px;
  color: #999999;
  margin-top: 4px;
  padding: 0 4px;
  position: relative;
  right: ${props => props.$isMine ? '8px' : '0'};
  bottom: -2px;
  display: inline-block;
`;

const OrderMessageContent = styled.div`
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 8px;
  width: 240px;
`;

const OrderItemsList = styled.div`
  margin-top: 8px;
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
`;

const OrderItem = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 0;
  font-size: 12px;
  
  &:not(:last-child) {
    border-bottom: 1px dashed #f0f0f0;
  }
`;

const OrderItemImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
`;

const OrderItemInfo = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const OrderItemName = styled.div`
  font-size: 12px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OrderItemDetails = styled.div`
  font-size: 11px;
  color: #666;
`;

const OrderMessageWrapper = styled.div`
  width: 100%;
`;

const OrderHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 12px;
  margin-bottom: 8px;
`;

const OrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OrderSummary = styled.div`
  display: flex;
  gap: 8px;
`;

const OrderImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
`;

const OrderInfo = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const OrderId = styled.div`
  font-weight: 500;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OrderPrice = styled.div`
  color: #ee4d2d;
  font-weight: 500;
  font-size: 12px;
`;

const OrderStatus = styled(Tag)`
  font-size: 11px;
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  background: #fff9f3;
  color: #ee4d2d;
  border-color: #ffd6c9;
  width: fit-content;
`;

const OrderIdWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: #ee4d2d;
  }

  .anticon {
    font-size: 12px;
  }
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  margin-left: auto;

  &:hover {
    color: #333;
  }

  .anticon {
    font-size: 12px;
  }
`;

const OrderItemsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  color: #666;
  font-size: 12px;
  border-top: 1px solid #f0f0f0;
  margin-top: 8px;
  padding-top: 8px;
`;

const ProductMessageContent = styled.div`
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 8px;
  width: 240px;
`;

const ProductHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 12px;
  margin-bottom: 8px;
`;

const ProductDetails = styled.div`
  display: flex;
  gap: 8px;
`;

const ProductImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
`;

const ProductInfo = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ProductName = styled.div`
  font-weight: 500;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductPrice = styled.div`
  color: #ee4d2d;
  font-weight: 500;
  font-size: 12px;
`;

const ProductOriginalPrice = styled.span`
  text-decoration: line-through;
  color: #999;
  font-size: 11px;
  margin-left: 4px;
`;

interface Message {
  id: string;
  content: string;
  isMine: boolean;
  timestamp: Date;
  type: MESSAGE_TYPE;
  attachments?: string[];
  orderInfo?: {
    orderId: number;
    image: string;
    price: number;
    orderStatus: ORDER_STATUS;
    orderItemInfos: Array<{
      images: string[];
      name: string;
      attributeValues: string;
      quantity: number;
      totalPrice: number;
    }>;
  };
  productInfo?: {
    productTypeId: number;
    name: string;
    image: string;
    price: number;
    originalPrice: number;
  };
}

interface MessageListProps {
  messages: Message[];
  loading?: boolean;
  userAvatar?: string;
  userName?: string;
  expandedMessages: { [key: string]: boolean };
  onExpandMessage: (messageId: string) => void;
  onImageClick?: (imageUrl: string) => void;
  locale?: 'vi' | 'en';
}

const getStatusColor = (status?: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.completed:
      return 'success';
    case ORDER_STATUS.shipping:
      return 'processing';
    case ORDER_STATUS.canceled:
      return 'error';
    default:
      return 'warning';
  }
};

const getStatusText = (status?: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.pending:
      return 'Pending';
    case ORDER_STATUS.processing:
      return 'Processing';
    case ORDER_STATUS.wait_for_confirmation:
      return 'Wait for confirmation';
    case ORDER_STATUS.wait_for_pickup:
      return 'Wait for pickup';
    case ORDER_STATUS.confirmed:
      return 'Confirmed';
    case ORDER_STATUS.canceled:
      return 'Canceled';
    case ORDER_STATUS.shipping:
      return 'Shipping';
    case ORDER_STATUS.completed:
      return 'Completed';
    case ORDER_STATUS.refunded:
      return 'Refunded';
    case ORDER_STATUS.refund:
      return 'Refund';
    default:
      return 'Unknown';
  }
};

const MessageList = forwardRef<HTMLDivElement, MessageListProps>(({
  messages,
  loading,
  userAvatar,
  userName,
  expandedMessages,
  onExpandMessage,
  onImageClick,
  locale = 'enUs'
}, ref) => {
  return (
    <MessageListContainer ref={ref}>
      {loading && <div>Loading...</div>}
      {messages.map((message, index) => {
        // Check if this is the last message in a sequence of user messages
        const nextMessage = messages[index + 1];
        const isLastInSequence = message.isMine !== nextMessage?.isMine;
        
        return (
          <MessageItem key={message.id} $isMine={message.isMine}>
            {!message.isMine && isLastInSequence && (
              <Avatar src={userAvatar} size={28}>
                {userName?.[0]}
              </Avatar>
            )}
            {!message.isMine && !isLastInSequence && (
              <div style={{ width: 28 }} /> 
            )}
            <div>
              {message.type === MESSAGE_TYPE.ORDER ? (
                <OrderMessageWrapper>
                  <OrderMessageContent>
                    <OrderHeader>
                      <ShoppingCartOutlined />
                      <span>Order Details</span>
                    </OrderHeader>
                    <OrderDetails>
                      <OrderSummary>
                        <OrderImage src={message.orderInfo?.image} alt="Order" />
                        <OrderInfo>
                          <OrderIdWrapper>
                            <OrderId>Order Id #{message.orderInfo?.orderId}</OrderId>
                            <CopyButton 
                              onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(message.orderInfo?.orderId?.toString() || '');
                              }}
                            >
                              <CopyOutlined />
                            </CopyButton>
                          </OrderIdWrapper>
                          <OrderPrice>{formatCurrency(message.orderInfo?.price || 0)}</OrderPrice>
                          <OrderStatus color={getStatusColor(message.orderInfo?.orderStatus)}>
                            {getStatusText(message.orderInfo?.orderStatus)}
                          </OrderStatus>
                        </OrderInfo>
                      </OrderSummary>
                      {message.orderInfo?.orderItemInfos && message.orderInfo.orderItemInfos.length > 0 && (
                        <>
                          <OrderItemsHeader>
                            <span>Product Details ({message.orderInfo.orderItemInfos.length})</span>
                            <ExpandButton
                              onClick={() => onExpandMessage(message.id)}
                            >
                              {expandedMessages[message.id] ? <UpOutlined /> : <DownOutlined />}
                            </ExpandButton>
                          </OrderItemsHeader>
                          {expandedMessages[message.id] && (
                            <OrderItemsList>
                              {message.orderInfo.orderItemInfos.map((item, index) => (
                                <OrderItem key={index}>
                                  <OrderItemImage src={item.images[0]} alt={item.name} />
                                  <OrderItemInfo>
                                    <OrderItemName>{item.name}</OrderItemName>
                                    <OrderItemDetails>
                                      {item.attributeValues} x {item.quantity} | {formatCurrency(item.totalPrice)}
                                    </OrderItemDetails>
                                  </OrderItemInfo>
                                </OrderItem>
                              ))}
                            </OrderItemsList>
                          )}
                        </>
                      )}
                    </OrderDetails>
                  </OrderMessageContent>
                </OrderMessageWrapper>
              ) : message.type === MESSAGE_TYPE.PRODUCT ? (
                <ProductMessageContent>
                  <ProductHeader>
                    <TagOutlined />
                    <span>Product</span>
                  </ProductHeader>
                  <ProductDetails>
                    <ProductImage src={message.productInfo?.image} alt={message.productInfo?.name} />
                    <ProductInfo>
                      <ProductName>{message.productInfo?.name}</ProductName>
                      <ProductPrice>
                        {formatCurrency(message.productInfo?.price || 0)}
                        {message.productInfo?.originalPrice > message.productInfo?.price && (
                          <ProductOriginalPrice>
                            {formatCurrency(message.productInfo?.originalPrice)}
                          </ProductOriginalPrice>
                        )}
                      </ProductPrice>
                    </ProductInfo>
                  </ProductDetails>
                </ProductMessageContent>
              ) : (
                <MessageContent 
                  $isMine={message.isMine}
                  $isImage={message.type === MESSAGE_TYPE.IMAGE}
                >
                  {message.type === MESSAGE_TYPE.IMAGE ? (
                    <div>
                      {message.content && <div style={{ marginBottom: '8px' }}>{message.content}</div>}
                      <MessageImage
                        src={message.attachments?.[0]}
                        alt="Image"
                        onClick={() => onImageClick?.(message.attachments?.[0] || '')}
                      />
                    </div>
                  ) : (
                    message.content
                  )}
                </MessageContent>
              )}
              {isLastInSequence && (
                <MessageTime $isMine={message.isMine}>
                  {message.timestamp instanceof Date && !isNaN(message.timestamp.getTime())
                    ? formatDistanceToNow(message.timestamp, {
                        addSuffix: true,
                        locale: enUS,
                      })
                    : 'Invalid time'}
                </MessageTime>
              )}
            </div>
          </MessageItem>
        );
      })}
    </MessageListContainer>
  );
});

MessageList.displayName = 'MessageList';

export default MessageList; 
export const SOCKET_EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect", 
  ERROR: "error",
  JOIN_ROOM: "join_room",
  LEAVE_ROOM: "leave_room",
  NEW_MESSAGE: "new_message",
  MESSAGE_DELIVERED: "message_delivered",
  MESSAGE_READ: "message_read",
  TYPING_START: "typing_start",
  TYPING_END: "typing_end",
  PRESENCE_UPDATE: "presence_update",
  UPDATE_STATUS: "update_status",
  FOCUS: "focus",
  BLUR: "blur"
} as const;

export interface ITypingStatus {
  userId: number;
  userType: string;
  conversationId: string;
} 
import React from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CategorySelectProps } from "./types";
import { useCategorySelect } from "./useCategorySelect";
import * as S from "./styles";

const CategorySelect: React.FC<CategorySelectProps> = ({
  valueInitial,
  onChange,
  pageId,
}) => {
  const {
    visible,
    setVisible,
    categories,
    loading,
    searchQuery,
    selectedCategory,
    selectedPath,
    selectedParentCategory,
    handleSearch,
    handleSelect,
    handleParentSelect,
    handleScroll,
    handleKeyPress,
    getParentCategories,
    getDisplayValue,
    setSearchQuery,
    initialLoadComplete,
  } = useCategorySelect(pageId, valueInitial);

  const handleConfirm = () => {
    if (selectedCategory) {
      const result = {
        categoryId:
          selectedCategory.parentId === 0
            ? selectedCategory.id
            : selectedCategory.parentId,
        subCategoryId:
          selectedCategory.parentId === 0 ? undefined : selectedCategory.id,
      };
      onChange?.(result);
      setVisible(false);
    }
  };

  if (!initialLoadComplete) {
    return <S.LoadingText>Loading categories...</S.LoadingText>;
  }

  return (
    <>
      <S.SelectButton onClick={() => setVisible(true)}>
        {getDisplayValue()}
      </S.SelectButton>

      <S.StyledModal
        title="Edit Category"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleConfirm}
            disabled={!selectedCategory}
          >
            Confirm
          </Button>,
        ]}
        width={800}
      >
        <S.SearchContainer>
          <S.SearchInput
            placeholder="Please enter at least 1 character"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            allowClear
            suffix={
              <SearchOutlined
                style={{ cursor: "pointer" }}
                onClick={handleSearch}
              />
            }
          />
        </S.SearchContainer>

        <S.CategoryContainer>
          <S.CategoryColumn onScroll={handleScroll}>
            {searchQuery
              ? categories.map((category) => (
                  <S.CategoryItem
                    key={category.id}
                    onClick={() => handleParentSelect(category)}
                    isSelected={
                      selectedCategory?.id === category.id ||
                      selectedParentCategory?.id === category.id
                    }
                    hasChildren={(category.children?.length ?? 0) > 0}
                  >
                    {category.name}
                  </S.CategoryItem>
                ))
              : getParentCategories().map((category) => (
                  <S.CategoryItem
                    key={category.id}
                    onClick={() => handleParentSelect(category)}
                    isSelected={
                      selectedCategory?.id === category.id ||
                      selectedParentCategory?.id === category.id
                    }
                    hasChildren={(category.children?.length ?? 0) > 0}
                  >
                    {category.name}
                  </S.CategoryItem>
                ))}
            {loading && <S.LoadingText>Loading...</S.LoadingText>}
          </S.CategoryColumn>

          {selectedParentCategory && (
            <S.CategoryColumn>
              {selectedParentCategory.children?.map((subCategory) => (
                <S.CategoryItem
                  key={subCategory.id}
                  onClick={() => handleSelect(subCategory)}
                  isSelected={selectedCategory?.id === subCategory.id}
                >
                  {subCategory.name}
                </S.CategoryItem>
              ))}
            </S.CategoryColumn>
          )}
        </S.CategoryContainer>

        {selectedPath && (
          <S.SelectedPath>
            <span>{selectedPath}</span>
          </S.SelectedPath>
        )}
      </S.StyledModal>
    </>
  );
};

export default CategorySelect;

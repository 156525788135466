import enLang from './entries/en-US';
import viLang from './entries/vi_VN';

// import {  } from "react-intl";

const AppLocale: any = {
  en: enLang,
  vi: viLang,
};
// addLocaleData(AppLocale.vi.data);
// addLocaleData(AppLocale.en.data);

export default AppLocale;

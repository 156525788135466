import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import { IMessage, MESSAGE_TYPE, IConversation, SENDER_TYPE, MESSAGE_STATUS, ORDER_STATUS } from '@src/types/chat';
import Socket from '@src/util/Socket';
import { ITypingStatus, SOCKET_EVENTS } from '@src/constants/socket';
import local from '@src/util/local';
import ChatInput from '../ChatInput';
import * as request from '@src/util/request';
import axios from 'axios';
import OrderModal from '@src/controls/layouts/schemaTemplate/order/components/chat/OrderModal';
import ProductModal from '@src/controls/layouts/schemaTemplate/order/components/chat/ProductModal';
import MessageList from '../MessageList';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TypingIndicator = styled.div`
  padding: 8px 12px;
  // color: #666;
  font-size: 13px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 8px;

  .word {
    display: inline-block;
    animation: jump 1.4s linear infinite;
  }

  .word:nth-child(2) { animation-delay: 0.2s; }
  .word:nth-child(3) { animation-delay: 0.4s; }
  .word:nth-child(4) { animation-delay: 0.6s; }
  .word:nth-child(5) { animation-delay: 0.8s; }

  @keyframes jump {
    0%, 80%, 100% { 
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
  }
`;

interface ConversationProps {
  conversation: IConversation | null;
  selectedUser: {
    id: number;
    name: string;
    avatar?: string;
  };
}

interface Message {
  id: string;
  content: string;
  isMine: boolean;
  timestamp: Date;
  type: MESSAGE_TYPE;
  status?: MESSAGE_STATUS;
  attachments?: string[];
  senderId?: number;
  senderType?: SENDER_TYPE;
  recipientId?: number;
  orderInfo?: {
    orderId: number;
    image: string;
    price: number;
    orderStatus: ORDER_STATUS;
    orderItemInfos: Array<{
      images: string[];
      name: string;
      attributeValues: string;
      quantity: number;
      totalPrice: number;
    }>;
  };
  productInfo?: {
    productTypeId: number;
    name: string;
    image: string;
    price: number;
    originalPrice: number;
  };
}

export interface ConversationRef {
  addMessage: (message: Message) => void;
}

const Conversation = forwardRef<ConversationRef, ConversationProps>(({ conversation, selectedUser }, ref) => {
  const messageListRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [socketInstance, setSocketInstance] = useState<any>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>();
  const [expandedMessages, setExpandedMessages] = useState<{[key: string]: boolean}>({});
  const [showProductModal, setShowProductModal] = useState(false);
  const limit = 20;

  useImperativeHandle(ref, () => ({
    addMessage: (message: Message) => {
      setMessages(prev => [...prev, message]);
    }
  }));

  const fetchMessageHistory = async (pageNumber: number) => {
    if (!conversation?._id) return;

    try {
      setLoading(true);
      const response = await axios.get('/admin/chat/get-list-message', {
        params: {
          conversationId: conversation._id,
          skip: (pageNumber - 1) * limit,
          limit,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.code === 0) {
        const { data, total: totalItems } = response.data;
        
        const formattedMessages = data.map((msg: any) => ({
          id: msg._id,
          content: msg.content || '',
          isMine: msg.senderType === 'SELLER',
          timestamp: new Date(Number(msg.timestamp)),
          type: msg.messageType,
          status: msg.status,
          attachments: msg.attachments,
          orderInfo: msg.orderInfo,
          productInfo: msg.productInfo,
          senderId: msg.senderId,
          senderType: msg.senderType,
          recipientId: msg.recipientId
        }));

        const reversedMessages = [...formattedMessages].reverse();
        
        if (pageNumber === 1) {
          setMessages(reversedMessages);
        } else {
          setMessages(prev => [...reversedMessages, ...prev]);
        }
        
        setTotal(totalItems);
        setHasMore(messages.length < totalItems);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (conversation?._id) {
      setPage(1);
      setMessages([]);
      fetchMessageHistory(1);
    }
  }, [conversation?._id]);

  const handleScroll = () => {
    if (!messageListRef.current) return;

    const { scrollTop } = messageListRef.current;
    if (scrollTop === 0 && !loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMessageHistory(nextPage);
    }
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, [page, loading, hasMore]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (conversation?._id) {
      const token = localStorage.getItem('token_chat');
      const socket = new Socket({
        token: token || '',
        receiveMessageCallback: (msg: IMessage) => {
          if (msg.conversationId === conversation._id) {
            if (msg.senderType !== SENDER_TYPE.SELLER) {
              console.log('Received new message:', msg);
              
              const newMessage: Message = {
                id: msg._id?.toString() || Date.now().toString(),
                content: msg.content || '',
                isMine: false,
                timestamp: new Date(msg.timestamp),
                type: msg.messageType,
                status: msg.status,
                attachments: msg.attachments,
                senderId: msg.senderId,
                senderType: msg.senderType,
                recipientId: msg.recipientId
              };

              setMessages(prev => {
                const messageExists = prev.some(m => m.id === newMessage.id);
                if (!messageExists) {
                  return [...prev, newMessage];
                }
                return prev;
              });

              if (messageListRef.current) {
                setTimeout(() => {
                  messageListRef.current?.scrollTo({
                    top: messageListRef.current.scrollHeight,
                    behavior: 'smooth'
                  });
                }, 100);
              }
            }
          }
        }
      });

      const socketAdapter = socket.getSocketAdapter();
      setSocketInstance(socketAdapter);

      console.log('Joining room:', conversation._id);
      socketAdapter.emit(SOCKET_EVENTS.JOIN_ROOM, conversation._id);

      socketAdapter.on(SOCKET_EVENTS.ERROR, (error: any) => {
        console.error('Socket error:', error);
      });

      socketAdapter.on('connect', () => {
        console.log('Socket connected');
      });

      socketAdapter.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      socketAdapter.on(SOCKET_EVENTS.TYPING_START, (data: ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(true);
        }
      });

      socketAdapter.on(SOCKET_EVENTS.TYPING_END, (data: ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(false);
        }
      });

      socketAdapter.on(SOCKET_EVENTS.FOCUS, (data: ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(true);
        }
      });

      socketAdapter.on(SOCKET_EVENTS.BLUR, (data : ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(false);
        }
      });

      return () => {
        console.log('Leaving room:', conversation._id);
        socketAdapter.emit(SOCKET_EVENTS.LEAVE_ROOM, conversation._id);
        socketAdapter.disconnect();
        setSocketInstance(null);
      };
    }
  }, [conversation?._id]);

  const handleSendMessage = (content: string, type: MESSAGE_TYPE = MESSAGE_TYPE.TEXT, attachments?: string[]) => {
    const userInfo = local.get('user_id');
    const senderId = userInfo?.id;

    if (socketInstance && conversation?._id && senderId) {
      const messageData: IMessage = {
        conversationId: conversation._id,
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId,
        content: content,
        messageType: type,
        timestamp: Date.now(),
        status: MESSAGE_STATUS.SENT,
        attachments
      };

      socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

      const newMessage: Message = {
        id: Date.now().toString(),
        content: content,
        isMine: true,
        timestamp: new Date(),
        type: type,
        status: MESSAGE_STATUS.SENT,
        attachments,
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId
      };

      setMessages(prev => [...prev, newMessage]);
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("images", file);
      const rs = await request.upload("/api/file/upload-image", formData);
      if (rs.created[0]?.url) {
        return rs.created[0].url;
      }
      return null;
    } catch (error) {
      console.error('Lỗi khi upload ảnh:', error);
      return null;
    }
  };

  const handleTypingStart = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.TYPING_START, {
        conversationId: conversation._id,
        userId: local.get('user_id')?.id,
        userType: SENDER_TYPE.SELLER
      });
    }
  };

  const handleTypingEnd = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.TYPING_END, {
        conversationId: conversation._id,
        userId: local.get('user_id')?.id,
        userType: SENDER_TYPE.SELLER
      });
    }
  };

  const handleInputFocus = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.FOCUS, conversation._id);
    }
  };

  const handleInputBlur = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.BLUR, conversation._id);
    }
  };

  const handleSelectOrder = (order: any) => {
    if (socketInstance && conversation?._id) {
      const userInfo = local.get('user_id');
      const senderId = userInfo?.id;

      const messageData: IMessage = {
        conversationId: conversation._id,
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId,
        content: `Order #${order.id}`,
        messageType: MESSAGE_TYPE.ORDER,
        timestamp: Date.now(),
        status: MESSAGE_STATUS.SENT,
        orderInfo: {
          orderId: order.id,
          image: order.image,
          price: order.totalMoney,
          orderStatus: order.status,
          orderItemInfos: order.orderItemInfos
        }
      };

      socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

      const newMessage: Message = {
        id: Date.now().toString(),
        content: `Order #${order.id}`,
        isMine: true,
        timestamp: new Date(),
        type: MESSAGE_TYPE.ORDER,
        status: MESSAGE_STATUS.SENT,
        orderInfo: {
          orderId: order.id,
          image: order.image,
          price: order.totalMoney,
          orderStatus: order.status,
          orderItemInfos: order.orderItemInfos
        },
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId
      };

      setMessages(prev => [...prev, newMessage]);
    }
    setShowOrderModal(false);
  };

  const handleSelectProduct = (product: any) => {
    if (socketInstance && conversation?._id) {
      const userInfo = local.get('user_id');
      const senderId = userInfo?.id;

      const productInfo = {
        productTypeId: product.id,
        name: product.name,
        image: product.thumbnail || product.images?.[0],
        price: product.paymentCash,
        originalPrice: product.originPrice
      };

      const messageData: IMessage = {
        conversationId: conversation._id,
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId,
        content: product.name,
        messageType: MESSAGE_TYPE.PRODUCT,
        timestamp: Date.now(),
        status: MESSAGE_STATUS.SENT,
        productInfo
      };

      socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

      const newMessage: Message = {
        id: Date.now().toString(),
        content: product.name,
        isMine: true,
        timestamp: new Date(),
        type: MESSAGE_TYPE.PRODUCT,
        status: MESSAGE_STATUS.SENT,
        senderId: senderId,
        senderType: SENDER_TYPE.SELLER,
        recipientId: conversation.userId,
        productInfo
      };

      setMessages(prev => [...prev, newMessage]);
      if (messageListRef.current) {
        setTimeout(() => {
          messageListRef.current?.scrollTo({
            top: messageListRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
    setShowProductModal(false);
  };

  return (
    <ChatContainer>
      <MessageList
        ref={messageListRef}
        messages={messages}
        loading={loading}
        userAvatar={selectedUser.avatar}
        userName={selectedUser.name}
        expandedMessages={expandedMessages}
        onExpandMessage={(messageId) => setExpandedMessages(prev => ({
          ...prev,
          [messageId]: !prev[messageId]
        }))}
        onImageClick={(url) => {
          setPreviewImage(url);
          setPreviewVisible(true);
        }}
        locale="en"
      />

      {isTyping && (
        <TypingIndicator>
          <span className="word">Is</span>
          <span className="word">typing</span>
          <span className="word">...</span>
        </TypingIndicator>
      )}

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>

      <ChatInput
        onSendMessage={handleSendMessage}
        onImageUpload={handleImageUpload}
        onTypingStart={handleTypingStart}
        onTypingEnd={handleTypingEnd}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        showOrderButton={true}
        onOrderButtonClick={() => setShowOrderModal(true)}
        showProductButton={true}
        onProductButtonClick={() => setShowProductModal(true)}
      />

      {showOrderModal && (
        <OrderModal
          visible={showOrderModal}
          onClose={() => setShowOrderModal(false)}
          onSelectOrder={handleSelectOrder}
          customerId={selectedUser.id}
        />
      )}

      {showProductModal && (
        <ProductModal
          visible={showProductModal}
          onClose={() => setShowProductModal(false)}
          onSelectProduct={handleSelectProduct}
        />
      )}
    </ChatContainer>
  );
});

export default Conversation;

import React, { useState, useEffect } from 'react';
import { Modal, Input, List, Avatar, Tag, Spin } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { formatCurrency } from '@src/util/format';
import local from '@src/util/local';
import request from '@src/util/request';

const { Search } = Input;

const ProductContainer = styled.div`
  padding: 16px;
`;

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #f5f5f5;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
  margin-left: 12px;
`;

const ProductName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const ProductPrice = styled.div`
  color: #f5222d;
  font-weight: 500;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
  margin-left: 8px;
`;

const StoreInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;

const StoreLogo = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: cover;
`;

interface ProductModalProps {
  visible: boolean;
  onClose: () => void;
  onSelectProduct: (product: any) => void;
}

interface Product {
  id: number;
  name: string;
  images: string[];
  thumbnail: string;
  paymentCash: number;
  originPrice: number;
  storeName: string;
  storeLogo: string;
  discountRate: number;
}

const ProductModal = React.memo(({ visible, onClose, onSelectProduct }: ProductModalProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const limit = 10;

  const fetchProducts = async (pageNumber: number, search?: string) => {
    try {
      setLoading(true);
      const storeId = local.get('store_id');
      
      const response = await request({
        url: '/admin/product/get-list-product-types',
        options: {
          method: 'get',
          params: {
            skip: (pageNumber - 1) * limit,
            limit,
            queryInput: {
            storeId,
              ...(search ? { name: search } : {})
            }
          }
        }
      });

      if (response.data.code === 0) {
        const newProducts = response.data.data;
        const totalItems = response.data.total;
        setTotal(totalItems);
        
        if (pageNumber === 1) {
          setProducts(newProducts);
        } else {
          setProducts(prev => [...prev, ...newProducts]);
        }

        const currentTotal = pageNumber === 1 ? newProducts.length : products.length + newProducts.length;
        setHasMore(currentTotal < totalItems);
      }
    } catch (error) {
      console.error('Lỗi khi lấy danh sách sản phẩm:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      setPage(1);
      fetchProducts(1, searchValue);
    }
  }, [visible]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
    fetchProducts(1, value);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (!target) return;

    const { scrollTop, clientHeight, scrollHeight } = target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!loading && hasMore) {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchProducts(nextPage, searchValue);
      }
    }
  };

  return (
    <Modal
      title="Chọn sản phẩm"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <ProductContainer>
        <Search
          placeholder="Tìm kiếm sản phẩm..."
          onSearch={handleSearch}
          style={{ marginBottom: 16 }}
          allowClear
        />
        
        <div 
          style={{ height: '400px', overflowY: 'auto' }} 
          onScroll={handleScroll}
          id="product-list-container"
        >
          <List
            dataSource={products}
            loading={loading}
            renderItem={(product) => (
              <ProductItem onClick={() => onSelectProduct(product)}>
                <Avatar src={product.thumbnail || product.images?.[0]} size={64} shape="square" />
                <ProductInfo>
                  <ProductName>{product.name}</ProductName>
                  <ProductPrice>
                    {formatCurrency(product.paymentCash)}
                    {product.originPrice > product.paymentCash && (
                      <OriginalPrice>{formatCurrency(product.originPrice)}</OriginalPrice>
                    )}
                  </ProductPrice>
                  <StoreInfo>
                    <StoreLogo src={product.storeLogo} alt={product.storeName} />
                    {product.storeName}
                  </StoreInfo>
                </ProductInfo>
                {product.discountRate > 0 && (
                  <Tag color="red" style={{ marginLeft: 8 }}>
                    -{product.discountRate}%
                  </Tag>
                )}
              </ProductItem>
            )}
          />
          {loading && <div style={{ textAlign: 'center', padding: '20px' }}><Spin /></div>}
        </div>
      </ProductContainer>
    </Modal>
  );
}, (prevProps, nextProps) => {
  return prevProps.visible === nextProps.visible;
});

ProductModal.displayName = 'ProductModal';

export default ProductModal; 
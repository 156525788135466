import React from 'react';
import { Form, Card, Typography, Space } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const AttributeSection = styled(Card)`
  margin-bottom: 24px;
`;

const AttributeDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AttributeRow = styled.div`
  display: flex;
  align-items: center;
`;

const AttributeLabel = styled(Text)`
  min-width: 80px;
  font-weight: bold;
`;

const AttributeValue = styled(Space)`
  flex-wrap: wrap;
`;

const AttributeTag = styled.span`
  background-color: #f0f0f0;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

interface AttributionProps {
  attribution: any[];
  setAttribution: React.Dispatch<React.SetStateAction<any[]>>;
  form: any;
}

const Attribution: React.FC<AttributionProps> = ({
  attribution,
  setAttribution,
  form,
}) => {
  console.log('attribution', attribution);
  return (
    <Form.Item>
      <AttributeSection title='Thuộc tính hiện có'>
        <AttributeDisplay>
          {attribution.map((attr, index) => (
            <AttributeRow key={index}>
              <AttributeLabel>{attr.property}:</AttributeLabel>
              <AttributeValue>
                {attr.values.map((value: string, valueIndex: number) => (
                  <AttributeTag key={valueIndex}>{value}</AttributeTag>
                ))}
              </AttributeValue>
            </AttributeRow>
          ))}
        </AttributeDisplay>
      </AttributeSection>
    </Form.Item>
  );
};

export default Attribution;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Image,
  Button,
  Switch,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  message,
} from 'antd';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { DragOutlined, EyeOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';
import { helper } from '@src/controls/controlHelper';

import '../schemaTemplate/flashSale.css';
import ArrayModel from '@src/packages/pro-component/schema/ArrayModel';
import ImageModel from '@src/packages/pro-component/schema/Image';
import ArrayImageModel from '@src/packages/pro-component/schema/ArrayImage';
import { toDot } from '@src/util/utils';

const PAGE_ID = 271;

export const getCurrentState = async (useState: any): Promise<any> => {
  return new Promise((rs) => {
    useState((pre: any) => {
      rs(pre);
      return pre;
    });
  });
};

const DragHandle = SortableHandle(() => (
  <DragOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);

const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

export default function BlockListCtl() {
  const [blockList, setBlockList] = useState<any[]>([]);
  const [pageInfo, setPageInfo] = useState<any>();
  const [init, setInit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [blockActive, setBlockActive] = useState<any>();
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  useEffect(() => {
    getPageInfo();
  }, []);

  const getPageInfo = async () => {
    const _pageInfo = await helper.getPage(PAGE_ID);
    setPageInfo(_pageInfo);
  };

  const getBlockList = useCallback(async () => {
    if (!pageInfo) return;
    try {
      const rs: any = await helper.callPageApi(pageInfo, 'find-block', { limit: 2000 });
      setBlockList(rs.data.data.sort((a: any, b: any) => a.position - b.position));
    } catch (error) {
      console.error('Error fetching block list:', error);
    }
    setInit(true);
  }, [pageInfo]);

  useEffect(() => {
    if (pageInfo) getBlockList();
  }, [pageInfo, getBlockList]);

  const updateBlock = async ({ id, key, value }: { id: number; key: string; value: any }) => {
    try {
      const newBlock = blockList.map((item) => ({
        ...item,
        [key]: item.id === id ? value : item[key],
      }));
      setBlockList(newBlock);
      await helper.callPageApi(pageInfo, 'update-block', { id, [key]: value });
      await getBlockList();
    } catch (error) {
      console.error('Error updating block:', error);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(blockList.slice(), oldIndex, newIndex)
        .filter((el: any) => !!el)
        .map((item: any, index: number) => ({
          ...item,
          position: index + 1,
        }));
      setBlockList(newData);
      newData.forEach((item) => {
        updateBlock({ id: item.id, key: 'position', value: item.position });
      });
    }
  };

  const handleCreateBlock = () => {
    setBlockActive(undefined);
    setIsCreateModalVisible(true);
  };

  const handleDeleteBlock = async (id: number) => {
    try {
      const blockToDelete = blockList.find(block => block.id === id);
      if (blockToDelete && ['FLASHSALE', 'DEALHOT'].includes(blockToDelete.type)) {
        message.error('Không thể xóa block kiểu FLASHSALE hoặc DEALHOT');
        return;
      }

      Modal.confirm({
        title: 'Xác nhận xóa',
        content: 'Bạn có chắc chắn muốn xóa block này?',
        okText: 'Xóa',
        okType: 'danger',
        cancelText: 'Hủy',
        onOk: async () => {
          await helper.callPageApi(pageInfo, 'del-block', { id });
          message.success('Xoá block thành công');
          getBlockList();
        },
      });
    } catch (error) {
      console.error('Error deleting block:', error);
      message.error('Có lỗi xảy ra khi xoá block');
    }
  };

  const defaultColumns: any[] = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      valueType: 'digit',
    },
    {
      title: 'Tên',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Loại',
      key: 'type',
      dataIndex: 'type',
      render: (value: any) => {
        switch (value) {
          case 'FLASHSALE':
            return 'FlashSale';
          // case 'FREE':
          //   return 'Ưu đãi 0 đồng';
          // case 'MONOPOLY':
          //   return 'Độc quyền';
          case 'DEALHOT':
            return 'DEALHOT';
          case 'CUSTOM':
            return 'Custom';
          default:
            return value;
        }
      },
    },
    {
      title: 'Kích hoạt (Webview)',
      key: 'isActive',
      dataIndex: 'isActive',
      render: (value: any, record: any) => {
        return (
          <Switch
            checked={value}
            onChange={(checked) => {
              updateBlock({ id: record.id, key: 'isActive', value: checked });
            }}
          />
        );
      },
    },
    {
      title: 'Hiển thị (Home App)',
      key: 'isIntegration',
      dataIndex: 'isIntegration',
      render: (value: any, record: any) => {
        return (
          <Switch
            checked={value}
            onChange={(checked) => {
              updateBlock({
                id: record.id,
                key: 'isIntegration',
                value: checked,
              });
            }}
          />
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_: any, record: any) => (
        <>
          <Button
            type='text'
            onClick={() => {
              setBlockActive(record);
              setVisible(true);
            }}
          >
            Edit
          </Button>
          {!['FLASHSALE', 'DEALHOT'].includes(record.type) && (
            <Button
              type='text'
              danger
              onClick={() => handleDeleteBlock(record.id)}
            >
              Delete
            </Button>
          )}
        </>
      ),
    },
  ];

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    const index = blockList.findIndex(
      (x) => x.position === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  if (!init) return <Loading />;

  return (
    <div>
      <Button
        type="primary"
        onClick={handleCreateBlock}
        style={{ marginBottom: 16 }}
      >
        Create New Block
      </Button>
      <Table
        pagination={false}
        dataSource={blockList}
        columns={defaultColumns}
        rowKey='position'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <ModalForm
        pageInfo={pageInfo}
        visible={visible || isCreateModalVisible}
        blockActive={blockActive}
        onCancel={() => {
          setVisible(false);
          setIsCreateModalVisible(false);
        }}
        onOk={() => {
          getBlockList();
          setVisible(false);
          setIsCreateModalVisible(false);
        }}
        disableTypeSelection={['FLASHSALE', 'DEALHOT']}
      />
    </div>
  );
}

const ModalForm = (props: any) => {
  const { visible, onCancel, onOk, pageInfo, blockActive, disableTypeSelection } = props;
  const [form] = Form.useForm();
  const [productTypes, setProductTypes] = useState<any[]>([]);
  const [typeProductType, setTypeProductType] = useState<any>('');
  const [formData, setFormData] = useState<any>(null);

  const getProductTypes = useCallback(
    async (ids: any[]) => {
      try {
        if (!ids.length) return setProductTypes([]);
        let sequence: any = {};
        ids.forEach((item: any, index: number) => {
          sequence[item] = index + 1;
        });
        const rs: any = await helper.callPageApi(pageInfo, 'findProductType', {
          queryInput: JSON.stringify({
            id: ids,
          }),
          limit: 2000,
          select: 'id,name,images,paymentCash,paymentPoint,payment',
        });
        if (rs.status !== 200) throw rs;
        let newProductType = rs.data.data
          .map((item: any, index: number) => {
            return {
              ...item,
              sequence: sequence[item.id] || index + 1,
            };
          })
          .sort((a: any, b: any) => a.sequence - b.sequence);
        setProductTypes(newProductType);
      } catch (error) {
        console.log('error get product type info: ', error);
      }
    },
    [pageInfo, setProductTypes]
  );

  useEffect(() => {
    if (blockActive && visible) {
      setTypeProductType(blockActive.type);
      getProductTypes(blockActive.productIds);
      const newFormData = {
        name: blockActive.name,
        type: blockActive.type,
        height: blockActive.height,
        productTypeInfos: blockActive.productIds,
        icon: blockActive.icon,
        images: blockActive.images,
      };
      setFormData(newFormData);
      form.setFieldsValue(newFormData);
    } else if (!visible) {
      form.resetFields();
      setFormData(null);
      setTypeProductType('');
    } else {
      // New block creation
      setFormData({
        name: '',
        type: '',
        height: '',
        productTypeInfos: [],
        icon: '',
        images: [],
      });
      form.setFieldsValue({
        name: '',
        type: '',
        height: '',
        productTypeInfos: [],
        icon: '',
        images: [],
      });
    }
  }, [blockActive, visible, form, getProductTypes]);

  const onSubmit = async () => {
    try {
      const formValues = form.getFieldsValue();
      const payload = {
        ...formValues,
        icon: formValues.icon?.[0]?.url || formValues.icon,
        images: formValues.images?.map((img: any) => img.url || img) || [],
        productIds: formValues.productTypeInfos || [], // Use productTypeInfos for productIds
      };

      if (blockActive) {
        // Existing block update logic
        const rs: any = await helper.callPageApi(pageInfo, 'update-block', {
          id: blockActive.id,
          ...payload,
        });
        if (rs.status !== 200) throw rs;
        onOk();
        message.success('Cập nhật thành công');
      } else {
        // New block creation logic
        if (['DEALHOT', 'FLASHSALE'].includes(payload.type)) {
          message.error(`Cannot create block type ${payload.type}`);
          return;
        }
        const rs: any = await helper.callPageApi(pageInfo, 'create-block', payload);
        if (rs.status !== 200) throw rs;
        onOk();
        message.success('Create new block successfully');
      }
    } catch (error) {
      console.log(error);
      message.error('An error occurred');
    }
  };

  const defaultColumns: any[] = [
    {
      title: 'Sort',
      dataIndex: 'sequence',
      width: 60,
      className: 'drag-visible',
      render: (value: number) => (
        <>
          <span>{value}</span>
          <DragHandle />
        </>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Tên',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Ảnh',
      key: 'images',
      dataIndex: 'images',
      render: (value: any) => {
        // return <Image src={value[0]} width={80} />;
        return (
          <div
            style={{
              width: '80px',
              height: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #d9d9d9',
              borderRadius: '2px',
              overflow: 'hidden',
            }}
          >
            <Image
              src={value[0]}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
              }}
              preview={{
                src: value[0],
                mask: <EyeOutlined style={{ fontSize: '16px' }} />,
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Loại thanh toán',
      dataIndex: 'payment',
      render: (value: any, record: any) => {
        switch (record.payment) {
          case 'both':
            return 'Tiền + Điểm';
          case 'point':
            return 'Điểm';
          case 'cash':
            return 'Tiền';
          default:
            return 'Chưa cập nhật';
        }
      },
    },
    {
      title: 'Giá trị',
      dataIndex: 'payment',
      render: (value: any, record: any) => {
        switch (record.payment) {
          case 'both':
            return toDot(record.paymentCash) || toDot(record.paymentPoint) || 0;
          case 'point':
            return record.paymentPoint ? toDot(record.paymentPoint) : 0;
          case 'cash':
            return record.paymentCash ? toDot(record.paymentCash) : 0;
          default:
            return 0;
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (_: any, record: any) =>
        productTypes.length >= 1 ? (
          <Button
            type='text'
            danger
            onClick={() => {
              let newProductType = productTypes.filter((item) => item.id !== record.id);
              setProductTypes(newProductType);
              form.setFieldsValue({
                productTypeInfos: newProductType.map((i: any) => i.productTypeId || i.id),
              });
            }}
          >
            Xoá
          </Button>
        ) : null,
    },
  ];

  const getApiName = () => {
    switch (typeProductType) {
      case 'FLASHSALE':
        return 'findProductType';
      // case 'FREE':
      //   return 'findProductTypeFree';
      // case 'MONOPOLY':
      //   return 'findProductTypeMonopoly';
      case 'DEALHOT':
        return 'findProductTypeHot';
      default:
        return 'findProductType';
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(productTypes.slice(), oldIndex, newIndex)
        .filter((el: any) => !!el)
        .map((item: any, index: number) => ({
          ...item,
          sequence: index + 1,
        }));
      setProductTypes(newData);
      form.setFieldsValue({
        productTypeInfos: newData.map((item: any) => item.id),
      });
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    const index = productTypes.findIndex(
      (x) => x.sequence === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Modal
      title={blockActive ? 'Sửa block' : 'Thêm block'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={'80%'}
      footer={[
        <Button key='back' onClick={onCancel}>
          Hủy
        </Button>,
        <Button key='submit' type='primary' onClick={() => form.submit()}>
          {blockActive ? 'Cập nhật' : 'Tạo mới'}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit} initialValues={formData || {}}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label='Tên'
              name='name'
              rules={[
                { required: true, message: 'Vui lòng điền tên block' },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label='Kiểu' 
              name='type'
              rules={[
                { required: true, message: 'Vui lòng chọn kiểu block' },
              ]}
            >
              <Select
                onChange={(value) => {
                  setTypeProductType(value);
                  if (value !== 'FLASHSALE') {
                    setProductTypes([]);
                    form.setFieldsValue({
                      productTypeInfos: [],
                    });
                  }
                }}
                disabled={!!blockActive} // Disable for existing blocks
              >
                <Select.Option value=''>Không chọn</Select.Option>
                <Select.Option value='DEALHOT' disabled>Deal hot</Select.Option>
                {/* <Select.Option value='MONOPOLY'>Độc quyền</Select.Option> */}
                {/* <Select.Option value='FREE'>Ưu đãi 0đ</Select.Option> */}
                <Select.Option value='FLASHSALE' disabled>Flash Sale</Select.Option>
                {/* <Select.Option value='CAMPAIGN'>Campaign</Select.Option> */}
                <Select.Option value='CUSTOM'>Custom</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item 
              label='Chiều cao block' 
              name='height'
              rules={[
                { required: true, message: 'Vui lòng nhập chiều cao block' },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        {/* {typeProductType === 'CAMPAIGN' && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item 
                label='Ảnh nền' 
                name='icon'
                rules={[
                  { required: true, message: 'Vui lòng chọn ảnh nền' },
                ]}
              >
                <ImageModel />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item 
                label='Ảnh chạy' 
                name='images'
                rules={[
                  { required: true, message: 'Vui lòng chọn ảnh chạy' },
                ]}
              >
                <ArrayImageModel />
              </Form.Item>
            </Col>
          </Row>
        )} */}
        {typeProductType !== 'FLASHSALE' && typeProductType !== '' && (
          <>
            <Row style={{ marginTop: 14 }} key={typeProductType + 'productType'}>
              <Form.Item
                label='Chọn sản phẩm'
                name='productTypeInfos'
                rules={[{ required: true, message: 'Chọn sản phẩm' }]}
              >
                <CustomModel
                  apiName={getApiName()}
                  getProductTypes={getProductTypes}
                />
              </Form.Item>
            </Row>
            <Table
              scroll={{ y: 700 }}
              pagination={false}
              dataSource={productTypes}
              columns={defaultColumns}
              rowKey='sequence'
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
          </>
        )}
      </Form>
    </Modal>
  );
};

const CustomModel = ({
  value,
  onChange,
  getProductTypes,
  apiName = 'find-product-type',
}: any) => {
  return (
    <ArrayModel
      schema={{
        name: 'Danh sách loại sản phẩm',
        field: 'productIds',
        widget: 'ArrayModel',
        type: 'number',
        api: apiName,
        modelSelectField: 'id$$ID,name$$Tên,images$$Hình ảnh',
        pageId: PAGE_ID,
      }}
      value={value}
      onChange={(ids) => {
        onChange(ids);
        getProductTypes(ids);
      }}
    />
  );
};

import React, { useState } from 'react';
import { Table, Tag, Space, Image, Button, InputNumber, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { IProductItem } from '../product.interface';
import { EditTwoTone, SaveTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

const getColor = (property: string, value: string): string => {
  const hash = property.length + value.length;
  return `hsl(${(hash * 137.508) % 360}, 50%, 75%)`;
};

// Helper function to format number to VNĐ
const formatToVND = (price: number): string => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};

const StyledTable = styled(Table)`
  .ant-table {
    border-radius: 12px;
    overflow: auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  }

  .ant-table-container {
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
      &:hover {
        background: #555;
      }
    }
  }

  .ant-table-thead > tr > th {
    background-color: #fafafa;
    font-weight: 600;
    padding: 16px;
    border-bottom: 2px solid #f0f0f0;
    white-space: nowrap;
  }

  .ant-table-tbody > tr > td {
    padding: 16px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #f5f5f5;
  }
`;

const PriceTag = styled.span`
  background: #e6f7ff;
  color: #1890ff;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
`;

const EditablePrice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-input-number {
    border-radius: 6px;
    
    &:hover, &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .action-icons {
    display: flex;
    gap: 8px;
    
    .anticon {
      font-size: 18px;
      cursor: pointer;
      transition: transform 0.2s ease;
      
      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

const TableProduct: React.FC<{
  productList: IProductItem[];
  form: any;
}> = ({ productList, form }) => {
  const [editingKey, setEditingKey] = useState<string>('');
  const [editingPrice, setEditingPrice] = useState<number>(0);

  const isEditing = (record: IProductItem) => record.key === editingKey;

  const edit = (record: IProductItem) => {
    setEditingKey(record.key);
    setEditingPrice(record.priceMembership || 0);
    
    setTimeout(() => {
      const editCell = document.querySelector('.editing-price-cell');
      if (editCell) {
        editCell.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
      }
    }, 100);
  };

  const save = async (record: IProductItem) => {
    try {
      const productList = form.getFieldValue('productList');
      const newData = [...productList];
      const index = newData.findIndex(item => item.key === record.key);
      
      if (index > -1) {
        const updatedRecord = {
          ...newData[index],
          priceMembership: editingPrice
        };
        newData[index] = updatedRecord;
        
        // Cập nhật form
        form.setFieldsValue({ 
          productList: newData,
          // Cập nhật trường riêng lẻ nếu có
          [`${record.key}`]: {
            ...form.getFieldValue(record.key),
            priceMembership: editingPrice
          }
        });

        // Hiển thị thông báo thành công
        message.success('Cập nhật giá member thành công');
      }
    } catch (errInfo) {
      message.error('Lưu thất bại!');
    } finally {
      setEditingKey('');
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns: ColumnsType<IProductItem> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Thuộc tính',
      dataIndex: 'attValues',
      key: 'attValues',
      render: (attValues: { [key: string]: string }) => (
        <Space size={[0, 4]} wrap>
          {Object.entries(attValues).map(([property, value]) => (
            <Tag key={`${property}-${value}`} color={getColor(property, value)}>
              {`${property}: ${value}`}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'images',
      key: 'images',
      render: (images: string[]) => (
        <Space>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Product ${index + 1}`}
              width={50}
              height={50}
              style={{ objectFit: 'cover' }}
            />
          ))}
        </Space>
      ),
    },
    {
      title: 'Giá gốc',
      dataIndex: 'originPrice',
      key: 'originPrice',
      render: (price: number) => formatToVND(price),
    },
    {
      title: 'Giá bán',
      dataIndex: 'paymentCash',
      key: 'paymentCash',
      render: (price: number) => formatToVND(price),
    },
    {
      title: 'Giá Member',
      dataIndex: 'priceMembership',
      key: 'priceMembership',
      render: (price: number, record: IProductItem) => {
        const editable = isEditing(record);
        return editable ? (
          <EditablePrice className="editing-price-cell">
            <InputNumber
              value={editingPrice}
              onChange={(value) => setEditingPrice(value || 0)}
              formatter={value => `${value}đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value!.replace(/\D/g, '')}
              style={{ width: '150px' }}
            />
            <div className="action-icons">
              <SaveTwoTone 
                onClick={() => save(record)}
                twoToneColor="#52c41a"
              />
              <CloseCircleTwoTone 
                onClick={cancel}
                twoToneColor="#ff4d4f"
              />
            </div>
          </EditablePrice>
        ) : (
          <EditablePrice>
            <PriceTag>{formatToVND(price || 0)}</PriceTag>
            <EditTwoTone 
              onClick={() => edit(record)}
              twoToneColor="#1890ff"
            />
          </EditablePrice>
        );
      },
    }
  ];

  return (
    <StyledTable
      dataSource={form.getFieldValue('productList') || productList}
      columns={columns}
      rowKey='key'
      pagination={false}
    />
  );
};

export default TableProduct;

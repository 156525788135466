import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, Typography, Table, Tag, Space, Button } from 'antd';
import {
  CalendarOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  CarOutlined,
  RedoOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  GiftOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { helper } from '@src/controls/controlHelper';
import Loading from '@src/components/Loading';
import './OrderItemFormCtrl.css';
const { Text } = Typography;

const PAGE_ID = process.env.REACT_APP_PAGE_ORDER_ITEM_ID
  ? Number(process.env.REACT_APP_PAGE_ORDER_ITEM_ID)
  : 299;

const OrderItemFormCtrl: React.FC = (props: any) => {
  const params = props.query;
  const [orderItem, setOrderItem] = useState<any>([]);
  const [orderShip, setOrderShip] = useState<any>({});
  const [init, setInit] = useState(false);
  const [store, setStore] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});
  const [, setPageInfo] = useState<any>({});
  const getOrderItemInfo = async () => {
    try {
      const _pageInfo = await helper.getPage(PAGE_ID);
      setPageInfo(_pageInfo);
      const orderShip: any = await helper.callPageApi(
        _pageInfo,
        'get_order_ship',
        {
          queryInput: JSON.stringify({ id: params.id }),
        }
      );
      const { orderItemInfos, storeInfo } = orderShip?.data?.data[0] || {};
      const orderShipData = orderShip?.data?.data[0] || {};
      const customerData: any = await helper.callPageApi(
        _pageInfo,
        'get_customer',
        {
          queryInput: JSON.stringify({ id: orderShipData.customerId }),
        }
      );

      setStore(storeInfo);
      setCustomer(customerData?.data?.data[0]);
      setOrderItem(orderItemInfos);
      setOrderShip(orderShipData);
      setInit(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderItemInfo();
  }, [params.id]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Ảnh',
      dataIndex: 'images',
      key: 'images',
      render: (value: any) => {
        return (
          <img
            src={value[0]}
            alt='product'
            style={{ width: '100px', height: '100px' }}
          />
        );
      },
    },
    {
      title: 'Giá bán',
      dataIndex: 'itemPrice',
      key: 'itemPrice',
      render: (value: number) => {
        return `${formatNumber(value)} VNĐ`;
      },
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (value: number) => {
        return `${formatNumber(value)} VNĐ`;
      },
    },
    {
      title: 'Cửa hàng',
      dataIndex: 'storeId',
      key: 'storeId',
      render: (storeId: string) => {
        const storeObj = store.id === storeId ? store : null;
        const storeName = storeObj ? storeObj.name : storeId;
        return <Tag color='blue'>{storeName}</Tag>;
      },
    },
  ];

  const data =
    orderItem.length > 0
      ? orderItem.map((item: any, index: number) => ({
          key: index,
          id: item.typeId,
          name: item.name,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          images: item.images,
          storeId: item.storeId,
          itemPrice: item.itemPrice,
        }))
      : [];

  const formatNumber = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const statusOrder = (
    status: string
  ): { text: string; icon: React.ReactNode } => {
    switch (status) {
      case 'wait_payment':
        return {
          text: 'Chờ thanh toán',
          icon: <ClockCircleOutlined style={{ color: '#faad14' }} />,
        };
      case 'pending':
        return {
          text: 'Chờ xử lý',
          icon: <ClockCircleOutlined style={{ color: '#faad14' }} />,
        };
      case 'wait_for_confirmation':
        return {
          text: 'Chờ xác nhận',
          icon: <ClockCircleOutlined style={{ color: '#1890ff' }} />,
        };
      case 'processing':
        return {
          text: 'Đang xử lý',
          icon: <SyncOutlined spin style={{ color: '#1890ff' }} />,
        };
      case 'completed':
        return {
          text: 'Đã hoàn thành',
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        };
      case 'shipping':
        return {
          text: 'Đang giao hàng',
          icon: <CarOutlined style={{ color: '#52c41a' }} />,
        };
      case 'refund':
        return {
          text: 'Đang hoàn tiền',
          icon: <RedoOutlined style={{ color: '#ff4d4f' }} />,
        };
      case 'refunded':
        return {
          text: 'Đã hoàn tiền',
          icon: <RedoOutlined style={{ color: '#ff4d4f' }} />,
        };
      case 'canceled':
        return {
          text: 'Đã hủy',
          icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
        };
      default:
        return {
          text: status,
          icon: <QuestionCircleOutlined style={{ color: '#8c8c8c' }} />,
        };
    }
  };

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.toLocaleString('vi-VN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const parseShipMsg = (shipMsg: any): React.ReactNode => {
    if (
      Array.isArray(shipMsg) &&
      shipMsg.length > 0 &&
      typeof shipMsg === 'object'
    ) {
      const sortedShipMsg = [...shipMsg].sort((a, b) => b.time - a.time);
      return (
        <ul className='ship-msg-list'>
          {sortedShipMsg.map((msg, index) => {
            const { time, status } = msg;
            const timeUpdate = formatDate(time);
            return (
              <li key={index} className='ship-msg-item'>
                <span className='ship-msg-item-dot'>
                  {index === 0 && <CheckOutlined />}
                </span>
                <div className='ship-msg-item-content'>
                  <span className='ship-msg-item-datetime'>{timeUpdate}</span>
                  <span className='ship-msg-item-status'>{status}</span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  };

  if (!init) return <Loading />;
  return (
    <div className='order-info-container'>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text strong>
            {/* <span className='section-title-customer'><ShoppingOutlined /> Thông tin đơn hàng</span>
                        <Divider /> */}
            <div className='info-items mt-2'>
              <InfoItem
                label='Mã hoá đơn'
                value={`#${orderShip?.orderId}`}
                span={24}
              />
              <InfoItem
                label='Mã thanh toán'
                value={`#${
                  orderShip?.paymentTransactionId || 'Không có thông tin'
                }`}
                span={24}
              />
              <InfoItem
                label='Ngày đặt hàng'
                value={formatDate(orderShip?.createdAt)}
                icon={<CalendarOutlined />}
                span={24}
              />
              {(() => {
                const { text, icon } = statusOrder(orderShip?.status);
                return (
                  <InfoItem
                    label='Trạng thái'
                    value={text}
                    icon={icon}
                    span={24}
                  />
                );
              })()}
            </div>
          </Text>

          <Text strong>
            <span className='section-title-customer'>
              <InfoCircleOutlined /> Thông tin khách hàng
            </span>
            <Divider />
            <div className='info-items mt-2'>
              <InfoItem label='Tài khoản' value={customer?.name} span={24} />
              <InfoItem
                label='Người nhận'
                value={orderShip?.receiveName || ''}
                span={24}
              />
              <InfoItem
                label='Số điện thoại'
                value={orderShip?.receivePhone || ''}
                icon={<PhoneOutlined />}
                span={24}
              />
              <InfoItem
                label='Tỉnh/Thành phố'
                value={`${orderShip?.receiveProvince || ''}`}
                icon={<EnvironmentOutlined />}
                span={24}
              />
              <InfoItem
                label='Quận/Huyện'
                value={`${orderShip?.receiveDistrict || ''}`}
                icon={<EnvironmentOutlined />}
                span={24}
              />
              <InfoItem
                label='Phường/Xã'
                value={`${orderShip?.receiveWard || ''}`}
                icon={<EnvironmentOutlined />}
                span={24}
              />
              <InfoItem
                label='Địa chỉ'
                value={orderShip?.receiveAddress}
                span={24}
              />
              <InfoItem
                label='Ghi chú'
                value={orderShip?.note || 'Không'}
                span={24}
              />
            </div>
          </Text>

          {orderShip?.voucherInfo && (
            <Text strong>
              <span className='section-title-customer'>
                <GiftOutlined /> Voucher áp dụng
              </span>
              <Divider />
              <div className='info-items mt-2'>
                <InfoItem
                  label='ID Voucher'
                  value={orderShip?.voucherInfo?.voucherId || 0}
                  span={24}
                />
                <InfoItem
                  label='Tên'
                  value={
                    orderShip?.voucherInfo?.voucherName || 'Chưa có thông tin'
                  }
                  span={24}
                />
                <InfoItem
                  label='Giá trị áp dụng'
                  value={
                    orderShip?.voucherInfo
                      ? `Giảm ${
                          orderShip?.voucherInfo?.voucherTypeDiscount === 1
                            ? `${formatNumber(
                                orderShip?.voucherInfo?.valueDiscount
                              )} VNĐ`
                            : orderShip?.voucherInfo?.voucherTypeDiscount === 2
                            ? `${formatNumber(
                                orderShip?.voucherInfo?.valuePercentDiscount
                              )}%`
                            : '0'
                        }`
                      : 'Chưa có thông tin'
                  }
                  span={24}
                />
                <InfoItem
                  label='Giá trị giảm tối đa'
                  value={
                    orderShip?.voucherInfo?.valueMaxDiscount
                      ? `${formatNumber(
                          orderShip?.voucherInfo?.valueMaxDiscount
                        )} VNĐ`
                      : 'Không áp dụng'
                  }
                  span={24}
                />
                <InfoItem
                  label='Áp dụng cho hoá đơn tối thiểu'
                  value={
                    orderShip?.voucherInfo?.valueMaxDiscount
                      ? `${formatNumber(
                          orderShip?.voucherInfo?.minAmountPayment
                        )} VNĐ`
                      : 'Không áp dụng'
                  }
                  span={24}
                />
              </div>
            </Text>
          )}

          {orderShip?.shipOrderCodeId && (
            <Text strong>
              <span className='section-title-customer'>
                <CarOutlined /> Trạng thái vận chuyển
              </span>
              <Divider />
              <div className='info-items mt-2'>
                <InfoItem
                  label='Mã vận chuyển'
                  value={orderShip?.shipOrderCodeId || 'Chưa có thông tin'}
                  span={24}
                />
                <Col span={24}>
                  <div className='info-item'>
                    <Text type='secondary' className='label'>
                      Link theo dõi đơn hàng:
                    </Text>
                    <Text strong className='info-value'>
                      {orderShip?.linkFulfillmentPartnerShip ? (
                        <a
                          className='link-tracking'
                          href={orderShip?.linkFulfillmentPartnerShip}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {orderShip?.linkFulfillmentPartnerShip || ''}
                        </a>
                      ) : (
                        'Chưa có thông tin'
                      )}
                    </Text>
                  </div>
                </Col>
                <Col span={24}>
                  <div className='info-item'>
                    <Text type='secondary' className='label'>
                      Dịch vụ:
                    </Text>
                    <Text strong className='info-value'>
                      <span className='info-icon'>
                        {orderShip.shipServiceCode} -{' '}
                        <Tag
                          color={
                            orderShip.shipCarrier === 'Siêu Tốc'
                              ? 'green'
                              : 'default'
                          }
                        >
                          {orderShip.shipCarrier}
                        </Tag>
                      </span>
                    </Text>
                  </div>
                </Col>
                {(() => {
                  const shipStatusInfo = parseShipMsg(orderShip?.shipMsg);
                  return (
                    <Col span={24}>
                      <div className='info-item'>
                        <Text strong className='info-value'>
                          {shipStatusInfo ||
                            'Chưa có thông tin hành trình vận chuyển'}
                        </Text>
                      </div>
                    </Col>
                  );
                })()}
              </div>
            </Text>
          )}
        </Col>
      </Row>

      <Text strong>
        <span className='section-title-customer'>
          <ShoppingCartOutlined /> Sản phẩm
        </span>
        <Divider />
        <Table columns={columns} dataSource={data} pagination={false} />
      </Text>

      <Col span={24} className='section-title-customer'>
        <Text strong>
          <span className='section-title-customer'>
            <DollarOutlined /> Thông tin thanh toán
          </span>
          <div className='info-items mt-2'>
            <InfoItem
              label='Sản phẩm'
              value={`${formatNumber(orderShip?.moneyCash)} VNĐ`}
              span={24}
            />
            <InfoItem
              label='Vận chuyển'
              value={`${formatNumber(orderShip?.shipMoney)} VNĐ`}
              span={24}
            />
            <InfoItem
              label='Chiết khấu'
              value={`${formatNumber(orderShip?.discount)} VNĐ`}
              span={24}
            />
            <InfoItem
              label='Điểm sử dụng'
              value={`${orderShip?.mpoint || 0} mPoint`}
              span={24}
            />
            <InfoItem
              label='Tổng tiền'
              value={`${formatNumber(orderShip?.totalMoney)} VNĐ`}
              span={24}
            />
          </div>
        </Text>
      </Col>

      <Space
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 14 }}
      >
        <Button
          onClick={() => props.onClose()}
          type='dashed'
          icon={<LeftOutlined />}
        >
          Quay lại
        </Button>
      </Space>
    </div>
  );
};

interface InfoItemProps {
  label: string;
  value: string;
  icon?: React.ReactNode;
  span: number;
  color?: string;
  style?: React.CSSProperties;
}

const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  icon,
  span,
  style,
}) => (
  <Col span={span}>
    <div className='info-item'>
      <Text type='secondary' className='label' style={style}>
        {label}:
      </Text>
      <Text strong className='info-value'>
        {icon && <span className='info-icon'>{icon}</span>}
        {value}
      </Text>
    </div>
  </Col>
);

const InfoItemTag: React.FC<InfoItemProps> = ({
  label,
  value,
  icon,
  span,
  color,
}) => (
  <Col span={span}>
    <div className='info-item'>
      <Text type='secondary' className='label'>
        {label}:
      </Text>
      <Text strong className='info-value'>
        {icon && <span className='info-icon'>{icon}</span>}
        <Tag color={color}>{value}</Tag>
      </Text>
    </div>
  </Col>
);

export default OrderItemFormCtrl;

import React, { useState } from "react";
import { Modal, Typography, Timeline, Space, Image, Tag, Button } from "antd";
import { Order } from "../types";
import { formatDateTime } from "../utils";
import styled from "styled-components";
import {
  FileTextOutlined,
  EnvironmentOutlined,
  CarOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";

const { Text, Title } = Typography;

interface OrderDetailProps {
  visible: boolean;
  order: Order | null;
  onClose: () => void;
}

const Container = styled.div`
  background: #fff;
`;

const StatusHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px 24px;
  border-bottom: 1px solid #edf2f7;
  margin: -24px -24px 24px;
  background: #fff;

  @media (max-width: 768px) {
    padding: 16px;
    margin: -16px -16px 16px;
  }
`;

const StatusText = styled.div`
  font-size: 16px;
  color: #9f60fc;
  display: flex;
  align-items: center;
  gap: 8px;

  .anticon {
    color: #9f60fc;
    font-size: 18px;
  }
`;

const Section = styled.div`
  margin-bottom: 24px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid #edf2f7;
  transition: all 0.3s ease;
  
  &:hover {
    border-color: #e2e8f0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    border-radius: 8px;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #edf2f7;
  font-size: 15px;
`;

const TitleLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  color: #1a202c;

  .anticon {
    color: #9f60fc;
    font-size: 18px;
  }
`;

const InfoContent = styled.div`
  padding: 20px;
  color: #4a5568;
  line-height: 1.8;
  
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ShipmentInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
  
  .label {
    color: #666;
    min-width: 80px;
  }
`;

const ProductImage = styled(Image)`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
`;

const TimelineSection = styled(Section)<{ $expanded: boolean }>`
  position: relative;
  padding-bottom: ${(props) => (props.$expanded ? "24px" : "0")};
  overflow: hidden;
`;

const TimelineContainer = styled.div<{ $expanded: boolean }>`
  padding: 20px 20px 20px 52px;
  height: ${(props) => (props.$expanded ? "auto" : "120px")};
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const ShipmentTag = styled(Tag)`
  background: #e6f7ff;
  color: #1890ff;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 13px;
  margin: 0;
`;

const ExpandButton = styled(Button)`
  color: #718096;
  padding: 6px 12px;
  height: auto;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    color: #9f60fc;
    border-color: #9f60fc;
    transform: translateY(-1px);
  }

  .anticon {
    transition: transform 0.3s ease;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 0;
    background: transparent;
  }

  .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
    padding: 24px;
    
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 3px;
    }
  }

  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #1a202c;
  }

  .ant-modal-close {
    top: 20px;
    right: 20px;
    
    &:hover {
      background: #f8fafc;
    }
  }

  @media (max-width: 768px) {
    .ant-modal-header {
      padding: 16px 16px 0;
    }
    
    .ant-modal-body {
      padding: 16px;
    }
    
    .ant-modal-title {
      font-size: 18px;
    }
  }
`;

const TimelineDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #9f60fc;
  border: 3px solid #fff;
  box-shadow: 0 0 0 2px #9f60fc;

  &.gray {
    background: #cbd5e0;
    box-shadow: 0 0 0 2px #cbd5e0;
  }
`;

const StyledTimeline = styled(Timeline)`
  .ant-timeline-item {
    padding-bottom: 24px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .ant-timeline-item-tail {
    border-left: 2px solid #e2e8f0;
    left: 7px;
  }

  .ant-timeline-item-head {
    background: transparent;
    width: auto;
    height: auto;
    padding: 0;
  }
`;

const TimelineContent = styled.div`
  .message {
    color: #2d3748;
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 500;
  }
  .time {
    color: #718096;
    font-size: 13px;
  }
`;

const PaymentSection = styled(Section)`
  .payment-row {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    line-height: 20px;

    @media (max-width: 768px) {
      padding: 12px 16px;
    }
  }

  .payment-total {
    font-size: 16px;
    color: #9f60fc;
    font-weight: 600;
    border-top: 1px solid #edf2f7;
    margin-top: 12px;
    padding-top: 16px;
  }

  .amount {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-weight: 500;
    color: #2d3748;
  }
`;

const formatPrice = (amount: number | null | undefined) => {
  if (amount == null) return '₫0';
  return `₫${amount.toLocaleString('vi-VN')}`;
};

const getStatusText = (status: string) => {
  const statusMap: Record<string, string> = {
    wait_for_confirmation: 'Pending Confirmation',
    wait_for_pickup: 'Awaiting Pickup',
    confirmed: 'Confirmed',
    shipping: 'Shipping',
    completed: 'Completed',
    canceled: 'Cancelled',
    refund: 'Refund',
    refunded: 'Refunded',
  };
  return statusMap[status] || status;
};

const OrderDetail: React.FC<OrderDetailProps> = ({
  visible,
  order,
  onClose,
}) => {
  const [expanded, setExpanded] = useState(false);

  if (!order) return null;

  const sortedShipMsg = [...order.shipMsg].reverse();

  return (
    <StyledModal
      visible={visible}
      onCancel={onClose}
      width={window.innerWidth > 768 ? 800 : '95%'}
      footer={null}
      title="Order Details"
    >
      <Container>
        <StatusHeader>
          <StatusText>
            <FileTextOutlined />
            {getStatusText(order.status)}
          </StatusText>
        </StatusHeader>

        <Section>
          <SectionTitle>
            <TitleLeft>
              <FileTextOutlined />
              <span>Order ID</span>
            </TitleLeft>
          </SectionTitle>
          <InfoContent>
            <Text>{order.id}</Text>
          </InfoContent>
        </Section>

        <Section>
          <SectionTitle>
            <TitleLeft>
              <EnvironmentOutlined />
              <span>Delivery Address</span>
            </TitleLeft>
          </SectionTitle>
          <InfoContent>
            <div>{order.receivePhone}</div>
            <div>{order.receiveAddress}</div>
          </InfoContent>
        </Section>

        <Section>
          <SectionTitle>
            <TitleLeft>
              <CarOutlined />
              <span>Shipping Information</span>
            </TitleLeft>
          </SectionTitle>
          <InfoContent>
            <ShipmentInfo>
              {order.shipCarrier}
              <Space>
                <ShipmentTag>{order.shipServiceName}</ShipmentTag>
                {order.shipOrderCodeId && (
                  <Text type="secondary">#{order.shipOrderCodeId}</Text>
                )}
              </Space>
            </ShipmentInfo>
            <Space align="start">
              <ProductImage src={order.orderItemInfos[0]?.images[0]} />
              <Text>Total {order.orderItemInfos.length} products</Text>
            </Space>
          </InfoContent>
        </Section>

        <PaymentSection>
          <SectionTitle>
            <TitleLeft>
              <FileTextOutlined />
              <span>Buyer Payment</span>
            </TitleLeft>
          </SectionTitle>
          <div>
            <div className="payment-row">
              <span>Product Total</span>
              <span className="amount">{formatPrice(order?.totalMoney)}</span>
            </div>
            <div className="payment-row">
              <span>Shipping Fee</span>
              <span className="amount">{formatPrice(order?.shipMoney)}</span>
            </div>
            <div className="payment-row payment-total">
              <span>Total Payment</span>
              <span className="amount">
                {formatPrice((order?.totalMoney || 0) + (order?.shipMoney || 0))}
              </span>
            </div>
          </div>
        </PaymentSection>

        <Section>
          <SectionTitle>
            <TitleLeft>
              <FileTextOutlined />
              <span>Order History</span>
            </TitleLeft>
            <ExpandButton type="text" onClick={() => setExpanded(!expanded)}>
              {expanded ? "Collapse" : "Expand"}{" "}
              <DownOutlined rotate={expanded ? 180 : 0} />
            </ExpandButton>
          </SectionTitle>
          <TimelineContainer $expanded={expanded}>
            <StyledTimeline>
              {sortedShipMsg.map((msg, index) => (
                <Timeline.Item
                  key={index}
                  dot={<TimelineDot className={index !== 0 ? "gray" : ""} />}
                >
                  <TimelineContent>
                    <div className="message">{msg.message}</div>
                    <div className="time">{formatDateTime(msg.time)}</div>
                  </TimelineContent>
                </Timeline.Item>
              ))}
            </StyledTimeline>
          </TimelineContainer>
        </Section>
      </Container>
    </StyledModal>
  );
};

export default OrderDetail; 

import React, { useState, useEffect } from 'react';
import { Input, List, Avatar, Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';
import axios from 'axios';
import { PictureOutlined, DownOutlined, CheckOutlined, CheckCircleOutlined, ExportOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import {
  fetchMessages,
  markMessageAsRead,
  getPinnedConversations,
  savePinnedConversations,
  handlePinConversation
} from '@src/utils/chatHelpers';

const { Search } = Input;

const DrawerContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  right: ${props => props.visible ? '0' : '-320px'};
  width: 320px;
  height: 100vh;
  background: white;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  transition: right 0.3s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;
`;

const DrawerHeader = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #f15a24;
`;

const SearchContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  
  .ant-input-search {
    .ant-input {
      border-radius: 20px;
      &:focus {
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }
    
    .ant-input-search-button {
      border-radius: 0 20px 20px 0;
    }
  }
`;

const StyledList = styled(List).attrs<{ dataSource: Conversation[] }>({
})`
  flex: 1;
  overflow-y: auto;
  
  .ant-list-item {
    padding: 12px 16px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #f5f5f5;
    }
  }
`;

const ChatList = StyledList as typeof List & { Item: typeof List.Item };

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatInfo = styled.div`
  flex: 1;
  margin-left: 12px;
  overflow: hidden;
`;

const ChatName = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #262626;
  
  &:hover {
    color: #1890ff;
  }
`;

const LastMessage = styled.div`
  color: #8c8c8c;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ChatTime = styled.div`
  font-size: 12px;
  color: #8c8c8c;
  text-align: right;
  margin-bottom: 4px;
`;

const UnreadBadge = styled.div`
  background: #ff4d4f;
  color: white;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  font-size: 12px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const DropdownIcon = styled(DownOutlined)`
  font-size: 12px;
  color: #8c8c8c;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${ChatItem}:hover & {
    opacity: 1;
  }
`;

const TimeAndBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 60px;
`;

const BadgeAndDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImageIcon = styled(PictureOutlined)`
  font-size: 14px;
`;

const StyledMenuItem = styled(Menu.Item)`
  padding: 8px 16px;
  font-size: 14px;
  
  &:hover {
    background: #f5f5f5;
  }
`;

const PinIcon = styled.span`
  margin-left: 4px;
  color: #1890ff;
`;

const MessageContent = styled(LastMessage)<{ isUnread?: boolean }>`
  color: ${props => props.isUnread ? '#262626' : '#8c8c8c'};
  font-weight: ${props => props.isUnread ? '500' : 'normal'};
  font-size: 13px;
  transition: all 0.3s ease;
  
  &:hover {
    color: ${props => props.isUnread ? '#1890ff' : '#595959'};
  }
`;

const MessageStatus = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: #8c8c8c;
  
  .anticon {
    margin-left: 2px;
  }
  
  .sent {
    color: #8c8c8c;
  }
  
  .delivered {
    color: #1890ff;
  }
  
  .read {
    color: #52c41a;
  }
`;

const MessageStatusIcon: React.FC<{ status: MESSAGE_STATUS }> = ({ status }) => {
  switch (status) {
    case MESSAGE_STATUS.SENT:
      return <CheckOutlined className="sent" />;
    case MESSAGE_STATUS.DELIVERED:
      return <CheckOutlined className="delivered" />;
    case MESSAGE_STATUS.READ:
      return <CheckCircleOutlined className="read" />;
    default:
      return null;
  }
};

enum MESSAGE_STATUS {
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  READ = "READ",
}

interface UserInfo {
  id: number;
  name: string;
  avatar: string;
}

interface LastMessage {
  _id: string;
  conversationId: string;
  senderId: number;
  senderType: string;
  recipientId: number;
  content: string;
  messageType: string;
  timestamp: number;
  status: string;
}

interface Conversation {
  _id: string;
  userId: number;
  sellerId: number;
  userInfo: UserInfo;
  sellerInfo: UserInfo;
  createdAt: number;
  updatedAt: number;
  status: string;
  userType: string;
  lastMessage?: LastMessage;
  isPinned?: boolean;
  isUnread?: boolean;
  unreadCount?: number;
}

interface ChatDrawerProps {
  visible: boolean;
  onClose: () => void;
  onChatSelect: (conversationId: string, userInfo: UserInfo, conversation: Conversation) => void;
}

interface MenuInfo {
  key: string;
  keyPath: string[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
}

const WebchatLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  font-size: 14px;
  color: #1890ff;
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(24, 144, 255, 0.1);
    color: #40a9ff;
  }

  .anticon {
    font-size: 16px;
  }
`;

const ChatDrawer: React.FC<ChatDrawerProps> = ({
  visible,
  onClose,
  onChatSelect,
}) => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const limit = 20;

  const fetchConversations = async (pageNumber: number) => {
    try {
      setLoading(true);
      const params = {
        skip: (pageNumber - 1) * limit,
        limit
      };

      const response = await axios.get('/admin/chat/get-list-conversation', {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.code === 0) {
        const { data, total: totalItems } = response.data.data;
        const pinnedConversations = getPinnedConversations();
        const unreadConversations = localStorage.getItem('unreadConversations');
        const unreadList = unreadConversations ? JSON.parse(unreadConversations) : [];
        
        const conversationsWithStatus = data.map(conv => ({
          ...conv,
          isPinned: pinnedConversations.includes(conv._id),
          isUnread: unreadList.includes(conv._id)
        }));

        if (pageNumber === 1) {
          setConversations(conversationsWithStatus);
        } else {
          setConversations(prev => [...prev, ...conversationsWithStatus]);
        }
        setTotal(totalItems);
        setHasMore(conversations.length < totalItems);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePinConversationClick = async (conversationId: string, isPinned: boolean) => {
    await handlePinConversation(conversationId, isPinned, setConversations);
  };

  const handleMarkAsUnread = async (conversationId: string, isUnread: boolean) => {
    try {
      if (isUnread) {
        await markMessageAsRead(conversationId);
          setConversations(prev => prev.map(conv => 
            conv._id === conversationId 
              ? { ...conv, isUnread: false }
              : conv
          ));
      } else {
        setConversations(prev => prev.map(conv => 
          conv._id === conversationId 
            ? { ...conv, isUnread: true }
            : conv
        ));
      }
    } catch (error) {
      console.error('Error marking conversation as read/unread:', error);
    }
  };

  const handleChatSelect = async (conversation: Conversation) => {
    try {
      if (conversation.isUnread && conversation.lastMessage) {
        await markMessageAsRead(conversation._id, conversation.lastMessage._id);
        setConversations(prev => prev.map(conv => 
          conv._id === conversation._id 
            ? { ...conv, isUnread: false }
            : conv
        ));
      }
      onChatSelect(conversation._id, conversation.userInfo, conversation);
    } catch (error) {
      console.error('Error selecting chat:', error);
      onChatSelect(conversation._id, conversation.userInfo, conversation);
    }
  };

  const menu = (conversation: Conversation) => (
    <Menu>
      <Menu.Item 
        key="unread"
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          handleMarkAsUnread(conversation._id, !!conversation.isUnread);
        }}
      >
        {conversation.isUnread ? 'Mark as read' : 'Mark as unread'}
      </Menu.Item>
      <Menu.Item 
        key="pin"
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          handlePinConversationClick(conversation._id, !!conversation.isPinned);
        }}
      >
        {conversation.isPinned ? 'Unpin conversation' : 'Pin conversation'}
      </Menu.Item>
      <Menu.Item 
        key="mute"
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          console.log('Mute notifications:', conversation._id);
        }}
      >
        Mute notifications
      </Menu.Item>
      <Menu.Item 
        key="delete"
        onClick={({ domEvent }) => {
          domEvent.stopPropagation();
          console.log('Delete conversation:', conversation._id);
        }}
        danger
      >
        Delete conversation
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (visible) {
      setPage(1);
      setConversations([]);
      fetchConversations(1);
    }
  }, [visible]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) { // Load more khi còn 50% cuối danh sách
      if (!loading && conversations.length < total) {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchConversations(nextPage);
      }
    }
  };

  return (
    <DrawerContainer visible={visible}>
      <DrawerHeader>
        Chat
        <WebchatLink 
          to={`/list?page=${process.env.REACT_APP_PAGE_ID_CHAT}`} 
          target="_blank"
        >
          <ExportOutlined />
          Webchat
        </WebchatLink>
      </DrawerHeader>
      
      <SearchContainer>
        <Search
          placeholder="Search by name"
          allowClear
        />
      </SearchContainer>

      <ChatList
        loading={loading}
        dataSource={[...conversations].sort((a, b) => {
          if (a.isPinned && !b.isPinned) return -1;
          if (!a.isPinned && b.isPinned) return 1;
          return b.updatedAt - a.updatedAt;
        })}
        onScroll={handleScroll}
        renderItem={(conversation: Conversation) => (
          <List.Item onClick={() => handleChatSelect(conversation)}>
            <ChatItem>
              <Avatar src={conversation.userInfo.avatar}>
                {conversation.userInfo.name[0]}
              </Avatar>
              <ChatInfo>
                <ChatName>
                  {conversation.userInfo.name}
                  {conversation.isPinned && <PinIcon>📌</PinIcon>}
                </ChatName>
                <MessageContent isUnread={conversation.isUnread}>
                  {conversation.lastMessage?.messageType === 'IMAGE' ? (
                    <>
                      <ImageIcon />
                      {conversation.lastMessage.content || 'Image'}
                    </>
                  ) : (
                    <>
                      {conversation.lastMessage?.content || 'No messages yet'}
                      {conversation.lastMessage && (
                        <MessageStatus>
                          <MessageStatusIcon status={conversation.lastMessage.status as MESSAGE_STATUS} />
                        </MessageStatus>
                      )}
                    </>
                  )}
                </MessageContent>
              </ChatInfo>
              <TimeAndBadgeContainer>
                <ChatTime>
                  {formatDistanceToNow(new Date(conversation.updatedAt), { 
                    addSuffix: true,
                    locale: enUS 
                  })}
                </ChatTime>
                <BadgeAndDropdownContainer>
                  {conversation.isUnread && (
                    <UnreadBadge>
                      {conversation.unreadCount > 0 
                        ? conversation.unreadCount > 99 
                          ? '99+' 
                          : conversation.unreadCount
                        : 1
                      }
                    </UnreadBadge>
                  )}
                  <Dropdown 
                    overlay={menu(conversation)}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <DropdownIcon onClick={(e) => e.stopPropagation()} />
                  </Dropdown>
                </BadgeAndDropdownContainer>
              </TimeAndBadgeContainer>
            </ChatItem>
          </List.Item>
        )}
      />
    </DrawerContainer>
  );
};

export default ChatDrawer; 
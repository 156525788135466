import React, { Component } from 'react';
import { Avatar, Button, Drawer, Input, Tabs, Upload } from 'antd'
import CustomScrollbars from '@src/util/CustomScrollbars'
import { formatDistanceToNow } from 'date-fns'
import { vi } from 'date-fns/locale'
import { connect } from 'dva'
import ChatUserList from '@src/components/chat/ChatUserList'
import Conversation, { ConversationRef } from '@src/components/chat/Conversation'
import ContactList from '@src/components/chat/ContactList'
import IntlMessages from '@src/util/IntlMessages'
import SearchBox from '@src/components/SearchBox'
import CircularProgress from '@src/components/CircularProgress'
import { StoreState } from '@src/interfaces'
import Socket from '@src/util/Socket'
import * as chatServices from '@src/services/chat'
import HttpStatusCode from '@src/constants/HttpStatusCode'
import { IMessage, IConversation, SENDER_TYPE, MESSAGE_TYPE, MESSAGE_STATUS } from '@src/types/chat'
import axios from 'axios'
import * as request from '@src/util/request'
import {
  fetchMessages,
  uploadImage,
  markMessageAsRead,
  createMessage,
  createLocalMessage,
  handlePinConversation
} from '@src/utils/chatHelpers'
import local from '@src/util/local'
import { SOCKET_EVENTS } from '@src/constants/socket';
import styled from 'styled-components';

const { TextArea } = Input
const TabPane = Tabs.TabPane

const MessageContent = styled.div<{ $isMine: boolean; $isImage?: boolean }>`
  background: ${props => props.$isImage ? '#f5f5f5' : props.$isMine ? "#0084ff" : "#ffffff"};
  color: ${props => props.$isMine && !props.$isImage ? "#fff" : "#000"};
  padding: ${props => props.$isImage ? '8px' : '8px 12px'};
  border-radius: 8px;
  font-size: 14px;
  max-width: 100%;
  word-wrap: break-word;
  box-shadow: ${props => props.$isImage ? '0 1px 2px rgba(0, 0, 0, 0.1)' : props.$isMine ? "none" : "0 1px 2px rgba(0, 0, 0, 0.1)"};
`;

export interface ChatCtrlProps {
  dispatch?: any
  drawerState?: any
  authUser?: any
  chatUsers?: Array<any>
  conversationList?: Array<IConversation>
}

export interface ChatCtrlState {
  message: string
  selectedUser: any
  conversation: IConversation | null
  userState: number
  searchChatUser: string
  selectedSectionId: string
  userNotFound: string
  contactList: Array<any>
  selectedTabIndex: number
  loader: boolean
  conversationList: Array<IConversation>
  drawerState: boolean
  chatToken?: string
  previewImage?: string
  previewVisible: boolean
  loading: boolean
  hasMore: boolean 
  page: number
  total: number
  limit: number
}

interface ChatUser {
  _id: string;
  userId: number;
  sellerId: number;
  userInfo: {
    id: number;
    name: string;
    avatar: string | null;
  };
  sellerInfo: {
    id: number;
    name: string;
    avatar: string;
  };
  lastMessage?: {
    _id: string;
    conversationId: string;
    senderId: number;
    senderType: SENDER_TYPE;
    recipientId: number;
    content: string;
    messageType: MESSAGE_TYPE;
    timestamp: number;
    status: MESSAGE_STATUS;
  };
  createdAt: number;
  updatedAt: number;
  status: string;
  userType: string;
  isPinned?: boolean;
  isUnread?: boolean;
}

class ChatCtrl extends Component<ChatCtrlProps, ChatCtrlState> {
  private _isMounted: boolean = false;
  socketAdapter: any;
  conversationRef: React.RefObject<ConversationRef>;

  constructor(props: ChatCtrlProps) {
    super(props);
    this.conversationRef = React.createRef();
    this.state = {
      loader: false,
      userNotFound: 'Không tìm thấy người dùng',
      drawerState: false,
      selectedSectionId: '',
      selectedTabIndex: 1,
      userState: 1,
      searchChatUser: '',
      contactList: [],
      selectedUser: null,
      message: '',
      conversationList: props.conversationList || [],
      conversation: null,
      previewVisible: false,
      loading: false,
      hasMore: true,
      page: 1,
      total: 0,
      limit: 20
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchConversations(1);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.socketAdapter) {
      this.socketAdapter.disconnect();
    }
  }

  fetchConversations = async (pageNumber: number) => {
    try {
      if (!this._isMounted) return;
      
      this.setState({ loading: true });
      const params = {
        skip: (pageNumber - 1) * this.state.limit,
        limit: this.state.limit
      };

      const response = await axios.get('/admin/chat/get-list-conversation', {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!this._isMounted) return;

      if (response.data.code === 0) {
        const { data, total: totalItems } = response.data.data;
        const pinnedConversations = this.getPinnedConversations();
        const unreadConversations = localStorage.getItem('unreadConversations');
        const unreadList = unreadConversations ? JSON.parse(unreadConversations) : [];
        
        const conversationsWithStatus = data.map((conv: ChatUser) => ({
          ...conv,
          isPinned: pinnedConversations.includes(conv._id),
          isUnread: unreadList.includes(conv._id)
        }));

        if (pageNumber === 1) {
          this.setState({ 
            conversationList: conversationsWithStatus,
            total: totalItems
          });
        } else {
          this.setState(prevState => ({
            conversationList: [...prevState.conversationList, ...conversationsWithStatus],
            total: totalItems
          }));
        }
        
        this.setState({
          hasMore: this.state.conversationList.length < totalItems
        });
      }
    } catch (error) {
      console.error('Error getting conversation list:', error);
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  getPinnedConversations = (): string[] => {
    const pinned = localStorage.getItem('pinnedConversations');
    return pinned ? JSON.parse(pinned) : [];
  };

  handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!this.state.loading && this.state.hasMore) {
        const nextPage = this.state.page + 1;
        this.setState({ page: nextPage });
        this.fetchConversations(nextPage);
      }
    }
  };

  onSelectUser = async (user: any) => {
    try {
      if (user.isUnread && user.lastMessage?._id) {
        await markMessageAsRead(user._id, user.lastMessage._id);
      }

      let token = localStorage.getItem('token_chat');
      if (!token) {
        throw new Error('Unable to get chat token');
      }

      const socket = new Socket({
        token,
        receiveMessageCallback: (msg: IMessage) => {
          if (!this._isMounted) return;
          
          if (msg.senderId !== this.props.authUser.id && this.state.conversation) {
            const updatedConversation = {
              ...this.state.conversation,
              lastMessage: {
                _id: msg._id,
                content: msg.content,
                messageType: msg.messageType,
                senderId: msg.senderId,
                senderType: msg.senderType,
                recipientId: msg.recipientId
              }
            };
            
            this.setState({
              conversation: updatedConversation,
              conversationList: this.state.conversationList.map(conv => 
                conv._id === updatedConversation._id ? updatedConversation : conv
              )
            });

            if (this.conversationRef.current) {
              this.conversationRef.current.addMessage({
                id: msg._id || Date.now().toString(),
                content: msg.content,
                isMine: false,
                timestamp: new Date(msg.timestamp),
                type: msg.messageType,
                attachments: msg.attachments,
                senderId: msg.senderId,
                senderType: msg.senderType,
                recipientId: msg.recipientId
              });
            }
          }
        }
      });

      this.socketAdapter = socket.getSocketAdapter();

      if (user._id) {
        this.socketAdapter.emit('join room', user._id);
      }

      this.setState({
        loader: true,
        selectedSectionId: user.id,
        drawerState: this.props.drawerState,
        selectedUser: user,
        conversation: {
          ...user,
          _id: user._id,
          userId: user.id,
          lastMessage: user.lastMessage || {
            content: '',
            messageType: MESSAGE_TYPE.TEXT,
            senderId: this.props.authUser.id,
            senderType: SENDER_TYPE.SELLER,
            recipientId: user.id
          }
        }
      });

      setTimeout(() => {
        if (this._isMounted) {
          this.setState({ loader: false });
        }
      }, 1500);

    } catch (error) {
      console.error('Error selecting user:', error);
      if (this._isMounted) {
        this.setState({
          loader: true,
          selectedSectionId: user.id,
          drawerState: this.props.drawerState,
          selectedUser: user,
          conversation: {
            ...user,
            lastMessage: user.lastMessage || {
              content: '',
              messageType: MESSAGE_TYPE.TEXT,
              senderId: this.props.authUser.id,
              senderType: SENDER_TYPE.SELLER,
              recipientId: user.id
            }
          }
        });
      }
    }
  }

  Communication = () => {
    const { selectedUser, conversation } = this.state;
    
    return (
      <div className="gx-chat-main">
        <div className="gx-chat-main-header">
          <span className="gx-d-block gx-d-lg-none gx-chat-btn">
            <i className="gx-icon-btn icon icon-chat" onClick={this.onToggleDrawer} />
          </span>
          <div className="gx-chat-main-header-info">
            <div className="gx-chat-avatar gx-mr-2">
              <div className="gx-status-pos">
                <Avatar src={selectedUser?.avatar} className="gx-rounded-circle gx-size-60" alt="" />
                <span className={`gx-status gx-${selectedUser?.status || 'offline'}`} />
              </div>
            </div>
            <div className="gx-chat-contact-name">{selectedUser?.name}</div>
          </div>
        </div>

        <CustomScrollbars className="gx-chat-list-scroll">
          <Conversation 
            ref={this.conversationRef}
            conversation={conversation}
            selectedUser={selectedUser}
            onMessageReceived={(msg: IMessage) => {
              if (msg.senderId !== this.props.authUser.id && this.state.conversation) {
                const updatedConversation = {
                  ...this.state.conversation,
                  lastMessage: {
                    _id: msg._id,
                    content: msg.content,
                    messageType: msg.messageType,
                    senderId: msg.senderId,
                    senderType: msg.senderType,
                    recipientId: msg.recipientId,
                    attachments: msg.attachments
                  }
                };
                
                this.setState({
                  conversation: updatedConversation,
                  conversationList: this.state.conversationList.map(conv => 
                    conv._id === updatedConversation._id ? updatedConversation : conv
                  )
                });

                if (this.conversationRef.current) {
                  this.conversationRef.current.addMessage({
                    id: msg._id || Date.now().toString(),
                    content: msg.messageType === MESSAGE_TYPE.IMAGE 
                      ? (msg.content || 'Image')
                      : msg.content,
                    isMine: false,
                    timestamp: new Date(msg.timestamp),
                    type: msg.messageType,
                    attachments: msg.attachments,
                    senderId: msg.senderId,
                    senderType: msg.senderType,
                    recipientId: msg.recipientId
                  });
                }
              }
            }}
          />
        </CustomScrollbars>
      </div>
    );
  };

  showCommunication = () => {
    return (
      <div className="gx-chat-box">
        {this.state.selectedUser === null ? (
          <div className="gx-comment-box">
            <div className="gx-fs-80">
              <i className="icon icon-chat gx-text-muted" />
            </div>
            <h1 className="gx-text-muted">
              <IntlMessages id="chat.selectUserChat" />
            </h1>
            <Button
              className="gx-d-block gx-d-lg-none"
              type="primary"
              onClick={this.onToggleDrawer}
            >
              <IntlMessages id="chat.selectContactChat" />
            </Button>
          </div>
        ) : (
          this.Communication()
        )}
      </div>
    );
  };

  onToggleDrawer = () => {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  };

  ChatUsers = () => {
    return (
      <div className="gx-chat-sidenav-main">
        <div className="gx-chat-sidenav-header">
          <div className="gx-chat-user-hd">
            <div className="gx-chat-avatar gx-mr-3">
              <div className="gx-status-pos">
                <Avatar src={this.props.authUser?.avatar} className="gx-size-50" alt="" />
                <span className="gx-status gx-online" />
              </div>
            </div>

            <div className="gx-module-user-info gx-flex-column gx-justify-content-center">
              <div className="gx-module-title">
                <h5 className="gx-mb-0">{this.props.authUser?.name}</h5>
              </div>
              <div className="gx-module-user-detail">
                <span className="gx-text-grey gx-link">{this.props.authUser?.email}</span>
              </div>
            </div>
          </div>

          <div className="gx-chat-search-wrapper">
            <SearchBox
              styleName="gx-chat-search-bar gx-lt-icon-search-bar-lg"
              placeholder="Search"
              onChange={(e) => this.setState({ searchChatUser: e.target.value })}
              value={this.state.searchChatUser}
            />
          </div>
        </div>

        <div className="gx-chat-sidenav-content">
          <CustomScrollbars 
            className="gx-chat-sidenav-scroll"
            onScroll={this.handleScroll}
          >
            <ChatUserList
              chatUsers={[...this.state.conversationList]
                .sort((a, b) => {
                  if (a.isPinned && !b.isPinned) return -1;
                  if (!a.isPinned && b.isPinned) return 1;
                  return b.updatedAt - a.updatedAt;
                })
                .map(conv => ({
                  _id: conv._id,
                  id: conv.userId,
                  name: conv.userInfo?.name || '',
                  avatar: conv.userInfo?.avatar || '',
                  status: conv.status,
                  lastMessage: conv.lastMessage ? {
                    _id: conv.lastMessage._id,
                    content: conv.lastMessage.messageType === MESSAGE_TYPE.IMAGE 
                      ? (conv.lastMessage.content || 'Image')
                      : (conv.lastMessage.content || ''),
                    messageType: conv.lastMessage.messageType,
                    timestamp: conv.lastMessage.timestamp,
                    status: conv.lastMessage.status,
                    senderId: conv.lastMessage.senderId,
                    senderType: conv.lastMessage.senderType,
                    recipientId: conv.lastMessage.recipientId,
                    attachments: conv.lastMessage.attachments
                  } : null,
                  isPinned: conv.isPinned,
                  isUnread: conv.isUnread,
                  updatedAt: conv.updatedAt,
                  userType: conv.userType
                }))
              }
              selectedSectionId={this.state.selectedSectionId}
              onSelectUser={this.onSelectUser}
            />
            {this.state.loading && (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            )}
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  handleSendMessage = () => {
    if (this.state.message.trim() && this.state.conversation) {
      const userInfo = local.get('user_id');
      const senderId = userInfo?.id;

      if (this.socketAdapter && this.state.conversation._id && senderId) {
        const messageData = createMessage(
          this.state.message.trim(),
          MESSAGE_TYPE.TEXT,
          this.state.conversation._id,
          this.state.conversation.userId || this.state.selectedUser.id
        );

        console.log('Sending message:', messageData);

        this.socketAdapter.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

        const newMessage = createLocalMessage(
          this.state.message.trim(),
          MESSAGE_TYPE.TEXT,
          senderId,
          this.state.conversation.userId || this.state.selectedUser.id
        );

        if (this.conversationRef.current) {
          this.conversationRef.current.addMessage(newMessage);
        }

        this.setState({ message: '' });
      }
    }
  };

  handleImageUpload = async (file: File) => {
    try {
      const imageUrl = await uploadImage(file);
      if (imageUrl && this.state.conversation) {
        const userInfo = local.get('user_id');
        const senderId = userInfo?.id;

        if (this.socketAdapter && senderId) {
          const messageData = createMessage(
            '',
            MESSAGE_TYPE.IMAGE,
            this.state.conversation._id,
            this.state.conversation.userId || this.state.selectedUser.id,
            [imageUrl]
          );

          console.log('Sending image message:', messageData);

          this.socketAdapter.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

          const newMessage = createLocalMessage(
            '',
            MESSAGE_TYPE.IMAGE,
            senderId,
            this.state.conversation.userId || this.state.selectedUser.id,
            [imageUrl]
          );

          if (this.conversationRef.current) {
            this.conversationRef.current.addMessage(newMessage);
          }
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
    return false;
  };

  render() {
    const { loader, drawerState } = this.state;

    return (
      <div className="gx-main-content">
        <div className="gx-app-module gx-chat-module">
          <div className="gx-chat-module-box">
            <div className="gx-d-block gx-d-lg-none">
              <Drawer
                placement="left"
                closable={false}
                visible={drawerState}
                onClose={this.onToggleDrawer}
              >
                {this.ChatUsers()}
              </Drawer>
            </div>
            <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
              {this.ChatUsers()}
            </div>
            {loader ? (
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            ) : (
              this.showCommunication()
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, chat }: StoreState) => {
  const { users: chatUsers, conversationList } = chat
  const { authUser } = auth
  return { authUser, chatUsers, conversationList }
}

export default connect(mapStateToProps)(ChatCtrl)

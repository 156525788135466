import styled from "styled-components";
import { Modal, Input, Button } from "antd";
import { CategoryItemProps } from "./types";

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px;
    min-height: 400px;
  }
`;

export const SearchInput = styled(Input)`
  margin-bottom: 16px;
`;

export const CategoryContainer = styled.div`
  display: flex;
  gap: 16px;
  min-height: 300px;
`;

export const CategoryColumn = styled.div`
  flex: 1;
  border: 1px solid #f0f0f0;
  overflow-y: auto;
  height: 300px;
  padding: 0;
`;

export const CategoryItem = styled.div<CategoryItemProps>`
  padding: 8px 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "#f0f7ff" : "transparent"};
  color: ${(props) => (props.isSelected ? "#1890ff" : "inherit")};
  position: relative;

  &:hover {
    background-color: #f5f5f5;
  }

  ${(props) =>
    props.hasChildren &&
    `
    &:after {
      content: '>';
      position: absolute;
      right: 16px;
      color: #999;
    }
  `}
`;

export const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
`;

export const SearchResultItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SelectButton = styled(Button)`
  width: 100%;
  text-align: left;
` as unknown as typeof Button;

export const SelectedPath = styled.div`
  margin-top: 16px;
  color: #666;

  &:before {
    content: "Selected: ";
    color: #666;
  }

  span {
    color: #000;
    font-weight: 600;
  }
`;

export const LoadingText = styled.div`
  text-align: center;
  padding: 10px;
  color: #999;
`;

export const SearchContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
`;

import React, { useState } from 'react';
import { Modal } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import tinymce from 'tinymce/tinymce';
import { IS_DEBUG } from '@src/constants/constants';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// A theme is also required
import 'tinymce/themes/silver';
// Any plugins you want to use has to be imported
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/code';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/wordcount';
import ImageRichText from '@src/packages/pro-component/schema/ImageRichText';
(window as any).tinymce = tinymce;

const RichText: React.FC<{
  value?: any;
  onChange?: (val: any) => void;
  style?: Record<string, any>;
  [x: string]: any;
  height?: number;
}> = ({ value, onChange, mode = 'simple', height }) => {
  const [fmVisible, setFmVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setIsVal] = useState(false);
  const [images, setImages] = useState([]);

  const showFileManager = (cb: any) => {
    setFmVisible(true);
    window.tinycmeCallback = cb;
  };

  const hideFileManager = () => {
    setFmVisible(false);
  };

  const handleChange = (val: any) => {
    let arrImg: any = cloneDeep(images);
    if (mode === 'multiple') {
      arrImg = concat(arrImg, val);
    } else {
      arrImg = concat([], (val || [])[0] || []);
    }
    setImages(arrImg);
    setIsVal(false);
    if (window.tinycmeCallback) {
      window.tinycmeCallback(`${val}`);
    }
  };

  const handleOk = () => {
    setFmVisible(false);
    setIsVal(true);
  };

  return (
    <>
      <Editor
        apiKey='cswezi492a2ax6zazo549bldqfazwrgrslsurvl1caolgntp'
        value={value}
        init={{
          height: height || 300,
          plugins: [
            'code advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen textcolor',
            'insertdatetime media table contextmenu paste imagetools wordcount',
          ],
          toolbar:
            'insertfile undo redo | styleselect forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code',
          extended_valid_elements: 'span[id|style|class]',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          convert_urls: false,
          noneditable_noneditable_class: 'mceNonEditable',
          file_picker_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            if (IS_DEBUG) {
              console.log('RichTextEditor file_picker_callback-> meta', meta);
              console.log('RichTextEditor file_picker_callback-> value', value);
            }
            showFileManager(callback);
          },
        }}
        onEditorChange={onChange}
      />
      <Modal
        zIndex={1310}
        style={{ top: '50px' }}
        width={500}
        title='Quản lý file'
        visible={fmVisible}
        onOk={handleOk}
        onCancel={hideFileManager}
        destroyOnClose={true}
      >
        <ImageRichText onChange={handleChange} />
      </Modal>
    </>
  );
};

export default RichText;

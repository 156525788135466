import React, { useState, useEffect } from 'react';
import { Modal, Input, List, Avatar, Tag, Spin } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { formatCurrency } from '../../../../../../util/format';
import { ORDER_STATUS } from '@src/types/chat';

const { Search } = Input;

const OrderContainer = styled.div`
  padding: 16px;
`;

const OrderItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #f5f5f5;
  }
`;

const OrderInfo = styled.div`
  flex: 1;
  margin-left: 12px;
`;

const OrderId = styled.div`
  font-weight: 500;
  margin-bottom: 4px;
`;

const OrderPrice = styled.div`
  color: #f5222d;
  font-weight: 500;
`;

const OrderStatus = styled(Tag)`
  margin-left: auto;
`;

const getStatusColor = (status: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.completed:
      return 'success';
    case ORDER_STATUS.shipping:
      return 'processing';
    case ORDER_STATUS.cancelled:
      return 'error';
    case ORDER_STATUS.wait_for_confirmation:
      return 'warning';
    default:
      return 'default';
  }
};

const getStatusText = (status: ORDER_STATUS) => {
  switch (status) {
    case ORDER_STATUS.completed:
      return 'Hoàn thành';
    case ORDER_STATUS.shipping:
      return 'Đang giao';
    case ORDER_STATUS.cancelled:
      return 'Đã hủy';
    case ORDER_STATUS.wait_for_confirmation:
      return 'Chờ xác nhận';
    case ORDER_STATUS.wait_for_pickup:
      return 'Chờ lấy hàng';
    case ORDER_STATUS.processing:
      return 'Đang xử lý';
    case ORDER_STATUS.pending:
      return 'Chờ thanh toán';
    case ORDER_STATUS.refund:
      return 'Hoàn tiền';
    case ORDER_STATUS.refunded:
      return 'Đã hoàn tiền';
    default:
      return status;
  }
};

interface OrderModalProps {
  visible: boolean;
  onClose: () => void;
  customerId?: number;
  onSelectOrder: (order: any) => void;
}

const OrderModal = React.memo(({ visible, onClose, customerId, onSelectOrder }: OrderModalProps) => {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  console.log('OrderModal rendered:')

  const fetchOrders = async (pageNumber: number, search?: string) => {
    if (!customerId) return;
    
    try {
      setLoading(true);
      const response = await axios.get('/admin/orders-ship/get-orders-ship', {
        params: {
          skip: (pageNumber - 1) * limit,
          limit,
          queryInput: {
            customerId:customerId.toString(),
            ...search ? { id: search.toString() } : {}
          }
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.code === 0) {
        const newOrders = response.data.data;
        if (pageNumber === 1) {
          setOrders(newOrders);
        } else {
          setOrders(prev => [...prev, ...newOrders]);
        }
        setHasMore(newOrders.length === limit);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible && customerId) {
      setPage(1);
      fetchOrders(1, searchValue);
    }
  }, [visible, customerId]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
    fetchOrders(1, value);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (!loading && hasMore) {
        const nextPage = page + 1;
        setPage(nextPage);
        fetchOrders(nextPage, searchValue);
      }
    }
  };

  return (
    <Modal
      title="Select order"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <OrderContainer>
        <Search
          placeholder="Search order id..."
          onSearch={handleSearch}
          style={{ marginBottom: 16 }}
          allowClear
          type="number"
          min={1}
        />
        
        <div style={{ height: '400px', overflowY: 'auto' }} onScroll={handleScroll}>
          <List
            dataSource={orders}
            loading={loading}
            renderItem={(order) => (
              <OrderItem onClick={() => onSelectOrder(order)}>
                <Avatar src={order.image} size={64} shape="square" />
                <OrderInfo>
                  <OrderId>Order #{order.id}</OrderId>
                  <div>{order.receiveName} - {order.receivePhone}</div>
                  <OrderPrice>{formatCurrency(order.totalMoney)}</OrderPrice>
                </OrderInfo>
                <OrderStatus color={getStatusColor(order.status)}>
                  {getStatusText(order.status)}
                </OrderStatus>
              </OrderItem>
            )}
          />
          {loading && <div style={{ textAlign: 'center', padding: '20px' }}><Spin /></div>}
        </div>
      </OrderContainer>
    </Modal>
  );
}, (prevProps, nextProps) => {
  // Chỉ re-render khi visible hoặc customerId thay đổi
  return prevProps.visible === nextProps.visible && 
         prevProps.customerId === nextProps.customerId;
});

OrderModal.displayName = 'OrderModal';

export default OrderModal; 
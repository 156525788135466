import React, { useEffect, useState } from 'react';
import ArrayImage from '@src/packages/pro-component/schema/ArrayImage';
import SingleSelect from '@src/packages/pro-component/schema/SingleSelect';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Space,
  Card,
  Typography,
  Collapse,
  Table,
  List,
  Tag,
} from 'antd';
import _ from 'lodash';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { helper } from '@src/controls/controlHelper';
import RichText from '@src/components/Widget/RichText';
import TableProductComponent from './components/TableProduct';
import Attribution from './components/Attribution';
import Loading from '@src/components/Loading';
import TextArea from 'antd/lib/input/TextArea';
import styled from 'styled-components';

const { Title } = Typography;
const { Panel } = Collapse;

const PAGE_ID = process.env.REACT_APP_PAGE_PRODUCT_ID
  ? Number(process.env.REACT_APP_PAGE_PRODUCT_ID)
  : 298;

export default function ProductFormCtrl(props: any) {
  const params = props.query;
  const [form] = Form.useForm();
  const [pageInfo, setPageInfo] = useState<any>({});
  const [attribution, setAttribution] = React.useState<any[]>([]);
  const [product, setProduct] = useState<any>({});
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toppings, setToppings] = useState<any[]>([]);

  const getProductInfo = async () => {
    try {
      const _pageInfo = await helper.getPage(PAGE_ID);
      setPageInfo(_pageInfo);
      const rs: any = await helper.callPageApi(_pageInfo, 'get_product_info', {
        productTypeId: params.id,
      });
      const data = rs.data.data;
      let attribution: any[] = [];
      for (const property in data) {
        if (property === 'productDetail') {
          attribution = data.productDetail.attrs.map((item: any) => {
            return {
              id: item.id,
              property: item.name,
              values: item.attributeValues.map((att: any) => att.name),
            };
          });
          if (data.productDetail && data.productDetail.productDetail) {
            const productList = data.productDetail.productDetail.map(
              (element: any) => {
                let attValues: any = {};
                for (let i in element.pAttrValue) {
                  if (!['key', 'id', 'isNew'].includes(i)) {
                    attValues[i] = element.pAttrValue[i];
                  }
                }
                let productKey = Object.values(attValues).sort().join('-');
                return {
                  key: productKey,
                  name: element.name,
                  images: element.images,
                  originPrice: element.originPrice,
                  paymentCash: element.paymentCash,
                  priceMembership: element.priceMembership,
                  attValues: attValues,
                };
              }
            );

            form.setFieldsValue({ productList });
          }
        }
        form.setFieldsValue({ [property]: data[property] });
      }
      form.setFieldsValue({ attribution });
      setInit(true);
      setProduct(data);
      setAttribution(attribution);
      if (data.toppings) {
        setToppings(data.toppings);
      }
      console.log(
        '🚀 ~ file: ProductFormCtrl.tsx:97 ~ getProductInfo ~ data.toppings:',
        data.toppings
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductInfo();
  }, []);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const dataForm = values;
      
      const updatedProductList = form.getFieldValue('productList');
      
      if (product.productDetail && product.productDetail.productDetail) {
        product.productDetail.productDetail = product.productDetail.productDetail.map((item: any) => {
          const updatedItem = updatedProductList.find((p: any) => {
            const itemAttValues = Object.values(item.pAttrValue)
              .filter((val: any) => typeof val === 'string')
              .sort()
              .join('-');
            return p.key === itemAttValues;
          });

          if (updatedItem) {
            return {
              ...item,
              name: updatedItem.name,
              images: updatedItem.images,
              originPrice: updatedItem.originPrice,
              paymentCash: updatedItem.paymentCash,
              priceMembership: updatedItem.priceMembership
            };
          }
          return item;
        });
      }

      let dataPatch = {
        ...product,
        accumlatePointType: dataForm.accumlatePointType,
        valueAccumulatePoints: dataForm.valueAccumulatePoints
          ? parseFloat(dataForm.valueAccumulatePoints)
          : 0,
        name: dataForm.name,
        description: dataForm.description,
        shortDescription: dataForm.shortDescription,
        images: dataForm.images,
        categoryId: dataForm.categoryId,
        categoryPartnerId: dataForm.categoryPartnerId,
        originPrice: dataForm.originPrice,
        paymentCash: dataForm.paymentCash,
        priceMembership: dataForm.priceMembership,
        hotSequence: dataForm.hotSequence
      };

      const rs: any = await helper.callPageApi(
        pageInfo,
        'update_product',
        dataPatch
      );
      if (rs.status !== 200 && rs?.data?.code !== 0) throw rs;
      helper.alert('Cập nhật sản phẩm thành công', 'success');
      props.onClose();
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        message.error(error.message);
      }
      if (error.response?.data?.errors) {
        message.error(error.response.data.errors);
      }
      helper.alert('Cập nhật sản phẩm thất bại', 'error');
    }
    setLoading(false);
  };
  if (!init) return <Loading />;

  return (
    <StyledForm
      form={form}
      name='dynamic_form_complex'
      onFinish={onFinish}
      layout='vertical'
    >
      {loading && <LoadingOverlay />}

      <Row gutter={24}>
        <Col span={8}>
          <Collapse defaultActiveKey={['1']} ghost accordion>
            <StyledPanel header='Thông tin cơ bản' key='1'>
              <FormSection>
                <Form.Item
                  name='images'
                  label='Hình ảnh'
                  rules={[{ required: true }]}
                >
                  <ArrayImage
                    value={form.getFieldValue('images')}
                    onChange={(newImages) => {
                      form.setFieldsValue({ images: newImages });
                      console.log('Current Images:', newImages);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name='name'
                  label='Tên sản phẩm'
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name='partnerId'
                  label={
                    <span>
                      Đối tác <Tag color='red'>View Only</Tag>
                    </span>
                  }
                  rules={[{ required: true }]}
                >
                  <SingleSelect
                    schema={{
                      pageId: PAGE_ID,
                      api: 'get_partner',
                      modelSelectMultiple: false,
                      disabled: true,
                    }}
                    placeholder='Select partner'
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item name='hotSequence' label='Thứ tự'>
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </FormSection>
            </StyledPanel>

            <StyledPanel header='Danh mục hiển thị' key='2'>
              <FormSection>
                <Form.Item
                  name='categoryId'
                  label='Danh mục'
                  rules={[{ required: true }]}
                >
                  <SingleSelect
                    schema={{
                      pageId: PAGE_ID,
                      api: 'get_categories',
                      modelSelectMultiple: false,
                      modelSelectField: 'id$$ID,name$$Tên danh mục',
                    }}
                    placeholder='Select category'
                  />
                </Form.Item>
                <Form.Item
                  name='categoryPartnerId'
                  label='Danh mục đối tác'
                  rules={[{ required: true }]}
                >
                  <SingleSelect
                    schema={{
                      pageId: PAGE_ID,
                      api: 'get_partner_categories',
                      modelSelectMultiple: false,
                      embed: [{ partnerId: form.getFieldValue('partnerId') }],
                      modelSelectField: 'id$$ID,name$$Tên danh mục đối tác',
                    }}
                    placeholder='Select partner category'
                  />
                </Form.Item>
              </FormSection>
            </StyledPanel>

            <StyledPanel header='Thông tin mô tả' key='3'>
              <FormSection>
                <Form.Item name='shortDescription' label='Mô tả ngắn'>
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>
                <Form.Item
                  name='description'
                  label='Mô tả chi tiết'
                  rules={[{ required: true }]}
                >
                  <RichText height={210} width={'100%'} />
                </Form.Item>
              </FormSection>
            </StyledPanel>

            <StyledPanel header='Thông tin giá' key='4'>
              <FormSection>
                <Form.Item name='originPrice' label='Giá gốc'>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `${value}đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
                <Form.Item name='paymentCash' label='Giá bán'>
                  <InputNumber
                    style={{ width: '100%' }}
                    formatter={(value) =>
                      `${value}đ`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </FormSection>
            </StyledPanel>

            <StyledPanel header='Thông tin tích điểm' key='5'>
              <FormSection>
                <Form.Item name='accumlatePointType' label='Loại tích'>
                  <Radio.Group>
                    <Radio value='value'> Tích điểm (mPoint) </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.accumlatePointType !==
                    curValues.accumlatePointType
                  }
                >
                  {() => (
                    <Form.Item
                      name='valueAccumulatePoints'
                      label={`Giá trị tích ${
                        form.getFieldValue('accumlatePointType') === 'percent'
                          ? '(%)'
                          : ''
                      }`}
                    >
                      <InputNumber
                        min={0}
                        max={
                          form.getFieldValue('accumlatePointType') === 'percent'
                            ? 100
                            : undefined
                        }
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </FormSection>
            </StyledPanel>

            <StyledPanel header='Thuộc tính hiện có' key='6'>
              <FormSection>
                <Attribution
                  attribution={attribution}
                  setAttribution={setAttribution}
                  form={form}
                />
              </FormSection>
            </StyledPanel>
          </Collapse>
        </Col>

        <Col span={16}>
          {Array.isArray(toppings) && toppings.length > 0 ? (
            <ToppingsSection>
              <Card title='Sản phẩm đính kèm'>
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={toppings}
                  renderItem={(topping) => (
                    <List.Item key={topping.id}>
                      <Card
                        type='inner'
                        title={
                          <Space>
                            <Tag color='blue'>{topping.name}</Tag>
                          </Space>
                        }
                      >
                        <Table
                          dataSource={
                            Array.isArray(topping.add_ons?.add_ons)
                              ? topping.add_ons.add_ons
                              : []
                          }
                          columns={[
                            { title: 'Tên', dataIndex: 'name', key: 'name' },
                            {
                              title: 'Giá',
                              dataIndex: 'price',
                              key: 'price',
                              render: (price) =>
                                new Intl.NumberFormat('vi-VN', {
                                  style: 'currency',
                                  currency: 'VND',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }).format(price),
                            },
                          ]}
                          pagination={false}
                          size='small'
                          rowKey={(record) => record.id || record.name}
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </Card>
            </ToppingsSection>
          ) : null}
          <ProductListSection>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                !_.isEqual(prevValues.attribution, curValues.attribution) ||
                prevValues.name !== curValues.name ||
                !_.isEqual(prevValues.images, curValues.images)
              }
            >
              {() => (
                <Card title='Sản phẩm chi tiết'>
                  <TableProduct
                    form={form}
                    productName={form.getFieldValue('name')}
                    productImages={form.getFieldValue('images')}
                    productOriginPrice={form.getFieldValue('originPrice')}
                    productPaymentCash={form.getFieldValue('paymentCash')}
                    attribution={attribution}
                    productList={form.getFieldValue('productList')}
                  />
                </Card>
              )}
            </Form.Item>
          </ProductListSection>
        </Col>
      </Row>

      <FormActions>
        <Button type='default' icon={<LeftOutlined />}>
          Quay lại
        </Button>
        <Button type='primary' icon={<PlusOutlined />} htmlType='submit'>
          Lưu thông tin
        </Button>
      </FormActions>
    </StyledForm>
  );
}

// Styled components for better appearance
const StyledForm = styled(Form)`
  max-width: 100%;
  margin: 0 auto;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    font-weight: 600;
    font-size: 16px;
    color: #1890ff;
    background-color: #f0f7ff;
    border-radius: 4px;
    padding: 12px 16px;
    margin-bottom: 8px;

    &:hover {
      background-color: #e6f7ff;
    }
  }

  .ant-collapse-content {
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
  }
`;

const FormSection = styled(
  ({
    className,
    children,
    title,
  }: {
    className?: string;
    children: React.ReactNode;
    title?: string;
  }) => (
    <div className={className}>
      {title && <Title level={4}>{title}</Title>}
      {children}
    </div>
  )
)`
  margin-bottom: 24px;
`;

const FormActions = styled(
  ({
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode;
  }) => (
    <div className={className}>
      <Space size='middle'>{children}</Space>
    </div>
  )
)`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const LoadingOverlay = styled(({ className }: { className?: string }) => (
  <div className={className}>
    <Loading />
  </div>
))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ProductListSection = styled.div`
  margin-top: 24px;

  .ant-card-head {
    background-color: #f0f2f5;
  }

  .ant-card-body {
    max-height: 400px;
    overflow-y: auto;
    padding: 0;
  }

  .ant-table-wrapper {
    .ant-table-thead > tr > th {
      background-color: #fafafa;
      font-weight: 600;
    }
  }
`;

// Assuming TableProduct is a custom component, let's create a styled version
const StyledTableProduct = styled(TableProductComponent)`
  .ant-table {
    .ant-table-thead > tr > th {
      padding: 12px 16px;
    }
    .ant-table-tbody > tr > td {
      padding: 12px 16px;
    }
  }
`;

// Replace the TableProduct component with the styled version
const TableProduct = (props: any) => <StyledTableProduct {...props} />;

const ToppingsSection = styled.div`
  margin-bottom: 24px;

  .ant-card-head {
    background-color: #f0f2f5;
  }

  .ant-card-body {
    max-height: 400px;
    overflow-y: auto;
  }

  .ant-table-wrapper {
    .ant-table-thead > tr > th {
      background-color: #fafafa;
      font-weight: 600;
    }
  }
`;

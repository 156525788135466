import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ArrayImage from "@src/packages/pro-component/schema/ArrayImage";
import SingleSelect from "@src/packages/pro-component/schema/SingleSelect";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Space,
  Card,
  Typography,
  Tabs,
  Switch,
  Modal,
} from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons/lib/icons";
import { helper } from "@src/controls/controlHelper";
import RichText from "@src/components/Widget/RichText";
import Loading from "@src/components/Loading";
import CategorySelect from "@src/components/CategorySelect";
import Image from "@src/packages/pro-component/schema/Image";
import { isEmpty, throttle, debounce } from "lodash";
import {
  StyledForm,
  FormActions,
  LoadingOverlay,
  StyledTable,
  StyledTabsContainer,
  FormContainer,
  SectionContainer,
  FormSection,
  StyledTabs,
} from "./styles";
import { CONSTANTS } from "./constants";
import {
  Variation,
  ShippingInfo,
  VariationCombination,
  TableRecord,
} from "./types";
import VariationOption from "./components/VariationOption";
import VariationNameInput from "./components/VariationNameInput";
import { useHistory } from "react-router-dom";
import InputTag from "@src/components/Widget/InputTag";

const { TabPane } = Tabs;
const { Title } = Typography;

const PAGE_ID = 303;

interface ProductVariationsProps {
  variations: Variation[];
  onAddVariation: () => void;
  onRemoveVariation: (key: number) => void;
  onVariationNameChange: (key: number, name: string) => void;
  onOptionChange: (
    variationKey: number,
    optionId: number,
    value: string
  ) => void;
  onOptionKeyPress: (
    e: React.KeyboardEvent,
    variationKey: number,
    optionId: number
  ) => void;
  onRemoveOption: (variationKey: number, optionId: number) => void;
  onDragStart: (variationKey: number, index: number) => void;
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (variationKey: number, dropIndex: number) => void;
}

const useScrollSpy = (refs: {
  [key: string]: React.RefObject<HTMLDivElement>;
}) => {
  const [activeSection, setActiveSection] = useState("1");

  const handleScroll = useCallback(
    throttle(() => {
      const scrollPosition = window.scrollY + 100; // Offset để trigger sớm hơn

      const sections = Object.entries(refs);
      for (let i = sections.length - 1; i >= 0; i--) {
        const [key, ref] = sections[i];
        if (ref.current && ref.current.offsetTop <= scrollPosition) {
          setActiveSection(key);
          break;
        }
      }
    }, 200),
    [refs]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return activeSection;
};

const BrandFormItem = React.memo(() => {
  return (
    <Form.Item name="brandId" label="Brand">
      <SingleSelect
        schema={{
          pageId: PAGE_ID,
          api: "get_brand",
          modelSelectMultiple: false,
          modelSelectField: "id$$ID,name$$Brand Name",
        }}
        placeholder="Select brand"
      />
    </Form.Item>
  );
});

const ProductVariations: React.FC<ProductVariationsProps> = React.memo(
  ({
    variations,
    onAddVariation,
    onRemoveVariation,
    onVariationNameChange,
    onOptionChange,
    onOptionKeyPress,
    onRemoveOption,
    onDragStart,
    onDragOver,
    onDrop,
  }) => {
    return (
      <Card title="Product Variations">
        <Space direction="vertical" style={{ width: "100%" }}>
          {variations.map((variation, index) => (
            <Card
              key={variation.key}
              type="inner"
              title={`Variation ${index + 1}`}
              extra={
                <Button
                  type="link"
                  onClick={() => onRemoveVariation(variation.key)}
                >
                  Delete
                </Button>
              }
            >
              <Form.Item
                label="Variation Group Name"
                required
                help={!variation.name && "Please enter variation group name"}
              >
                <VariationNameInput
                  placeholder="E.g.: Color, Size..."
                  value={variation.name}
                  onChange={(value) =>
                    onVariationNameChange(variation.key, value)
                  }
                  pageId={PAGE_ID}
                />
              </Form.Item>
              <Form.Item label="Options" required>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {variation.options.map((option, index) => (
                    <div
                      key={option.id}
                      style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                      }}
                      draggable
                      onDragStart={() => onDragStart(variation.key, index)}
                      onDragOver={onDragOver}
                      onDrop={() => onDrop(variation.key, index)}
                    >
                      <VariationOption
                        option={option}
                        onDelete={() =>
                          onRemoveOption(variation.key, option.id)
                        }
                        onChange={(e) =>
                          onOptionChange(
                            variation.key,
                            option.id,
                            e.target.value
                          )
                        }
                        onKeyPress={(e) =>
                          onOptionKeyPress(e, variation.key, option.id)
                        }
                        placeholder="Enter option"
                        data-option-id={option.id}
                        status={option.error ? "error" : undefined}
                      />
                      {option.error && (
                        <div style={{ color: "#ff4d4f", fontSize: "12px" }}>
                          {option.error}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </Form.Item>
              <div
                style={{ color: "#999", fontSize: "12px", marginTop: "-20px" }}
              >
                {variation.options.length}/{CONSTANTS.MAX_OPTIONS} options
              </div>
            </Card>
          ))}
          {variations.length < 2 && (
            <Button
              type="dashed"
              onClick={onAddVariation}
              block
              icon={<PlusOutlined />}
            >
              Add Variation Group ({variations.length}/
              {CONSTANTS.MAX_VARIATIONS})
            </Button>
          )}
        </Space>
      </Card>
    );
  }
);

const handleSave = (form: any, isShow: boolean) => {
  Modal.confirm({
    title: "Confirm",
    content: isShow
      ? "Are you sure you want to save and show this product?"
      : "Are you sure you want to save and hide this product?",
    okText: "Yes",
    cancelText: "No",
    onOk: () => {
      form.setFieldsValue({
        isActive: isShow,
        isApprove: isShow,
      });
      form.validateFields().then(() => {
        form.submit();
      });
    },
  });
};

export default function CreateProductFormCtrl(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [variations, setVariations] = useState<Variation[]>([]);
  const [draggedItem, setDraggedItem] = useState<{
    variationKey: number;
    index: number;
  } | null>(null);
  const [combinations, setCombinations] = useState<VariationCombination[]>([]);
  const [activeTab, setActiveTab] = useState("1");
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const productDetailsRef = useRef<HTMLDivElement>(null);
  const shippingRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [useVariationShipping, setUseVariationShipping] = useState(false);
  const history = useHistory();
  const [product, setProduct] = useState<any>({});
  const [init, setInit] = useState(false);

  const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {
    "1": basicInfoRef,
    "2": productDetailsRef,
    "3": shippingRef,
  };

  const currentSection = useScrollSpy(refs);

  useEffect(() => {
    setActiveTab(currentSection);
  }, [currentSection]);

  //cần sửa
  useEffect(() => {
    if (variations.length > 0) {
      const generateCombinations = () => {
        const validVariations = variations
          .map((v) => ({
            name: v.name,
            options: v.options
              .filter((opt) => opt.value.trim() !== "")
              .map((opt) => opt.value),
          }))
          .filter((v) => v.options.length > 0);

        if (validVariations.length === 0) {
          setCombinations([]);
          return;
        }

        const result: VariationCombination[] = [];

        if (validVariations.length === 1) {
          validVariations[0].options.forEach((opt) => {
            const findCombination = combinations?.find(
              (combination) => combination.name == opt
            );
            const currentCombination = !isEmpty(findCombination)
              ? findCombination
              : ({} as any);
            result.push({
              ...(currentCombination.id && { id: currentCombination.id }),
              name: opt,
              variation1: opt,
              price: currentCombination?.price || 0,
              stock: currentCombination?.stock || 0,
              sku: currentCombination?.sku || "",
              image: currentCombination?.image || "",
              shipping: currentCombination?.shipping || {},
            });
          });
        } else if (validVariations.length === 2) {
          validVariations[0].options.forEach((opt1) => {
            validVariations[1].options.forEach((size) => {
              const findCombination = combinations?.find(
                (combination) => combination.name == `${size}-${opt1}`
              );
              const currentCombination = !isEmpty(findCombination)
                ? findCombination
                : ({} as any);
              result.push({
                ...(currentCombination.id && { id: currentCombination.id }),
                name: `${opt1}-${size}`,
                variation1: opt1,
                variation2: size,
                price: currentCombination?.price || 0,
                stock: currentCombination?.stock || 0,
                sku: currentCombination?.sku || "",
                image: currentCombination?.image || "",
                shipping: currentCombination?.shipping || {},
              });
            });
          });
        }

        setCombinations(result);
      };

      generateCombinations();
    } else {
      if (!product?.combinations) {
        setCombinations([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variations, product?.combinations]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Kiểm tra xem đã cuộn đến cuối trang chưa (với ngưỡng 20px)
      const isBottom = scrollTop + windowHeight >= documentHeight - 20;
      setIsAtBottom(isBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onFinish = async (values: any) => {
    try {
      // Validate variations
      if (variations.length === 0) {
        message.error("Please add at least one variation group!");
        return;
      }

      // Validate options for each variation
      const hasEmptyOptions = variations.some(
        (v) =>
          v.options.length === 0 || v.options.every((opt) => !opt.value.trim())
      );

      if (hasEmptyOptions) {
        message.error("Each variation group must have at least one option!");
        return;
      }

      // Validate variation names
      const hasEmptyNames = variations.some((v) => !v.name.trim());
      if (hasEmptyNames) {
        message.error("Please enter names for all variation groups!");
        return;
      }

      setLoading(true);

      // Only validate common weight if not using variation shipping
      if (!useVariationShipping) {
        const weight = Number(values.weight);
        if (isNaN(weight)) {
          message.error("Invalid weight");
          return;
        }
      } else {
        // Validate weight for each combination when using variation shipping
        const invalidCombination = combinations.some((combo) => {
          const weight = Number(combo.shipping?.weight);
          return isNaN(weight) || weight <= 0;
        });

        if (invalidCombination) {
          message.error("Please enter valid weight for all variations");
          return;
        }
      }

      // Prepare variations data
      const formattedVariations = variations.map((variation) => ({
        name: variation.name,
        options: variation.options.map((opt) => ({
          id: opt.id,
          value: opt.value,
        })),
      }));

      // Prepare combinations data with shipping info
      const formattedCombinations = combinations.map((combo) => {
        const baseCombo = {
          ...(combo.id && { id: combo.id }), // Chỉ thêm id nếu nó tồn tại
          variation1: combo.variation1,
          variation2: combo.variation2,
          price: combo.price,
          stock: combo.stock,
          sku: combo.sku || "",
          image: combo.image || "",
        };

        if (useVariationShipping) {
          return {
            ...baseCombo,
            shipping: {
              weight: Number(combo.shipping?.weight),
              dimensions: {
                length: Number(combo.shipping?.dimensions?.length) || undefined,
                width: Number(combo.shipping?.dimensions?.width) || undefined,
                height: Number(combo.shipping?.dimensions?.height) || undefined,
              },
            },
          };
        }

        return baseCombo;
      });

      // Prepare common shipping data only if not using variation shipping
      const shippingData = !useVariationShipping
        ? {
            shipping: {
              weight: Number(values.weight),
              dimensions: {
                length: Number(values.length) || undefined,
                width: Number(values.width) || undefined,
                height: Number(values.height) || undefined,
              },
            },
          }
        : {};

      // Prepare data to send to API
      const dataForm = {
        ...product, // Keep old data from product
        name: values.name,
        description: values.description,
        categoryId: values.categoryId,
        subCategoryId: values.subCategoryId || 0,
        brandId: values.brandId,
        categoryStoreId: values.categoryStoreId,
        images: values.images,
        thumbnail: values.thumbnail,
        weight: Number(values.weight),
        originPrice: values.originPrice,
        paymentCash: values.paymentCash,
        variations: formattedVariations,
        combinations: formattedCombinations,
        isActive: values.isActive,
        isApprove: values.isApprove,
        tags: values.tags || [],
        ...shippingData,
      };

      // If editing (has props.query.id)
      if (props.query?.id) {
        const pageInfo = await helper.getPage(PAGE_ID);
        const rs: any = await helper.callPageApi(pageInfo, "update_product", {
          ...dataForm,
          id: props.query.id,
        });

        if (rs.status !== 200 && rs?.data?.code !== 0) throw rs;
        helper.alert("Update product successfully", "success");
      } else {
        // If creating new
        const pageInfo = await helper.getPage(PAGE_ID);
        const rs: any = await helper.callPageApi(
          pageInfo,
          "create_product",
          dataForm
        );

        if (rs.status !== 200 && rs?.data?.code !== 0) throw rs;
        helper.alert("Create product successfully", "success");
      }

      history.goBack();
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        message.error(error.message);
      }
      helper.alert(
        props.query?.id ? "Update product failed" : "Create product failed",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const addVariation = () => {
    if (variations.length < 2) {
      setVariations([
        ...variations,
        {
          key: Date.now(),
          name: "",
          options: [{ id: Date.now(), value: "" }],
        },
      ]);
    }
  };

  const removeVariation = (key: number) => {
    if (variations.length > 1) {
      setVariations(variations.filter((v) => v.key !== key));
    } else {
      message.error("Must have at least one variation group!");
    }
  };

  const handleVariationNameChange = (key: number, name: string) => {
    setVariations(variations.map((v) => (v.key === key ? { ...v, name } : v)));
  };

  const removeOption = (variationKey: number, optionId: number) => {
    setVariations(
      variations.map((v) => {
        if (v.key === variationKey) {
          if (v.options.length > 1) {
            return {
              ...v,
              options: v.options.filter((opt) => opt.id !== optionId),
            };
          } else {
            message.error("Must have at least one option for each variation!");
            return v;
          }
        }
        return v;
      })
    );
  };

  const handleOptionChange = useCallback(
    (variationKey: number, optionId: number, value: string) => {
      setVariations((prev) =>
        prev.map((v) => {
          if (v.key === variationKey) {
            const isDuplicate = v.options.some(
              (opt) =>
                opt.id !== optionId &&
                opt.value.trim().toLowerCase() === value.trim().toLowerCase()
            );

            return {
              ...v,
              options: v.options.map((opt) =>
                opt.id === optionId
                  ? {
                      ...opt,
                      value,
                      error: isDuplicate
                        ? "Variation values must be unique"
                        : undefined,
                    }
                  : opt
              ),
            };
          }
          return v;
        })
      );
    },
    []
  );

  const handleOptionKeyPress = (
    e: React.KeyboardEvent,
    variationKey: number,
    optionId: number
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const variation = variations.find((v) => v.key === variationKey);
      if (variation && variation.options.length < 20) {
        const newOptionId = Date.now();
        setVariations(
          variations.map((v) => {
            if (v.key === variationKey) {
              return {
                ...v,
                options: [...v.options, { id: newOptionId, value: "" }],
              };
            }
            return v;
          })
        );

        setTimeout(() => {
          const newInput = document.querySelector(
            `input[data-option-id="${newOptionId}"]`
          ) as HTMLInputElement;
          if (newInput) {
            newInput.focus();
          }
        }, 0);
      }
    }
  };

  const moveOption = (
    variationKey: number,
    fromIndex: number,
    toIndex: number
  ) => {
    setVariations(
      variations.map((v) => {
        if (v.key === variationKey) {
          const newOptions = [...v.options];
          const [movedItem] = newOptions.splice(fromIndex, 1);
          newOptions.splice(toIndex, 0, movedItem);
          return {
            ...v,
            options: newOptions,
          };
        }
        return v;
      })
    );
  };

  const handleDragStart = (variationKey: number, index: number) => {
    setDraggedItem({ variationKey, index });
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (variationKey: number, dropIndex: number) => {
    if (draggedItem && draggedItem.variationKey === variationKey) {
      moveOption(variationKey, draggedItem.index, dropIndex);
    }
    setDraggedItem(null);
  };

  const handleCombinationChange = useCallback(
    (index: number, field: keyof TableRecord, value: any) => {
      setCombinations((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        )
      );
    },
    []
  );

  const applyToAll = (
    price: number,
    stock: number,
    sku: string,
    weight?: number,
    length?: number,
    width?: number,
    height?: number
  ) => {
    setCombinations((prev) =>
      prev.map((item) => ({
        ...item,
        price: price || item.price,
        stock: stock || item.stock,
        sku: sku || item.sku,
        ...(useVariationShipping &&
          weight && {
            shipping: {
              ...item.shipping,
              weight: weight,
              dimensions: {
                length: length || item.shipping?.dimensions?.length,
                width: width || item.shipping?.dimensions?.width,
                height: height || item.shipping?.dimensions?.height,
              },
            },
          }),
      }))
    );
  };

  const scrollToSection = (key: string) => {
    const refs = {
      "1": basicInfoRef,
      "2": productDetailsRef,
      "3": shippingRef,
    };

    refs[key]?.current?.scrollIntoView({ behavior: "smooth" });
    setActiveTab(key);
  };

  const handleVariationShippingChange = (
    index: number,
    field: keyof ShippingInfo | "dimensions",
    value: number,
    dimensionField?: "length" | "width" | "height"
  ) => {
    setCombinations((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          const newShipping = item.shipping ? { ...item.shipping } : {};

          if (field === "dimensions") {
            newShipping.dimensions = {
              ...newShipping.dimensions,
              [dimensionField!]: value,
            };
          } else {
            newShipping[field] = value;
          }

          return {
            ...item,
            shipping: newShipping,
          };
        }
        return item;
      })
    );
  };

  const debouncedHandleVariationShippingChange = useCallback(
    debounce(
      (
        index: number,
        field: keyof ShippingInfo | "dimensions",
        value: number,
        dimensionField?: "length" | "width" | "height"
      ) => {
        handleVariationShippingChange(index, field, value, dimensionField);
      },
      1000
    ),
    []
  );

  const shippingColumns = useMemo(() => {
    if (!useVariationShipping) return [];
    return [
      {
        title: "Weight (gr)",
        key: "weight",
        render: (_: any, record: any, index: number) => (
          <InputNumber
            value={record.shipping?.weight}
            onChange={(value) => {
              if (value !== null) {
                debouncedHandleVariationShippingChange(index, "weight", value);
              }
            }}
            min={1}
            precision={0}
            placeholder="Weight"
          />
        ),
      },
      {
        title: "Dimensions (cm)",
        key: "dimensions",
        render: (_: any, record: any, index: number) => {
          return (
            <Space>
              <InputNumber
                value={record.shipping?.dimensions?.length}
                onChange={(value) => {
                  console.log(`Length: ${value}, Index: ${index}`);
                  if (value !== null) {
                    debouncedHandleVariationShippingChange(
                      index,
                      "dimensions",
                      value,
                      "length"
                    );
                  }
                }}
                min={0}
                precision={0}
                placeholder="L"
              />
              <InputNumber
                value={record.shipping?.dimensions?.width}
                onChange={(value) => {
                  console.log(`Width: ${value}, Index: ${index}`);
                  if (value !== null) {
                    debouncedHandleVariationShippingChange(
                      index,
                      "dimensions",
                      value,
                      "width"
                    );
                  }
                }}
                min={0}
                precision={0}
                placeholder="W"
              />
              <InputNumber
                value={record.shipping?.dimensions?.height}
                onChange={(value) => {
                  console.log(`Height: ${value}, Index: ${index}`);
                  if (value !== null) {
                    handleVariationShippingChange(
                      index,
                      "dimensions",
                      value,
                      "height"
                    );
                  }
                }}
                min={0}
                precision={0}
                placeholder="H"
              />
              <span>cm</span>
            </Space>
          );
        },
      },
    ];
  }, [useVariationShipping]);

  const getProductInfo = async () => {
    try {
      const _pageInfo = await helper.getPage(PAGE_ID);
      const rs: any = await helper.callPageApi(_pageInfo, "get_product_info", {
        productTypeId: props.query?.id,
      });

      if (rs?.code === 403) {
        message.error("You do not have permission to access this resource");
        history.goBack();
        return;
      }

      if (rs?.data?.data) {
        const data = rs.data.data;

        // Cập nhật form với dữ liệu từ API
        form.setFieldsValue({
          name: data.name,
          description: data.description,
          images: data.images,
          thumbnail: data.thumbnail,
          categoryId: data.categoryId,
          subCategoryId: data.subCategoryId,
          brandId: data.brandId,
          categoryStoreId: data.categoryStoreId,
          originPrice: data.originPrice,
          paymentCash: data.paymentCash,
          isActive: data.isActive,
          isApprove: data.isApprove,
          tags: data.tags,
        });

        // Lưu toàn bộ data vào state product
        setProduct(data);
        setInit(true);
      }
    } catch (error: any) {
      console.log("Error fetching product info:", error);

      // Kiểm tra error code 403 trong catch block
      if (error?.code === 403) {
        message.error("You do not have permission to access this resource");
        history.goBack();
        return;
      }

      helper.alert("Error loading product information", "error");
    }
  };

  // Call API when component mounts if there's productTypeId
  useEffect(() => {
    if (props.query?.id) {
      getProductInfo();
    } else {
      setInit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.query?.id]);

  useEffect(() => {
    if (product?.variations && product?.combinations) {
      console.log("Setting data from product:", product);

      // Khởi tạo lại variations
      const formattedVariations = product.variations.map((variation: any) => ({
        key: Date.now() + Math.random(),
        name: variation.name,
        options: variation.options.map((opt: any) => ({
          id: opt.id,
          value: opt.value,
        })),
      }));
      setVariations(formattedVariations);

      // Khởi tạo lại combinations và map với variations
      const formattedCombinations = product.combinations.map(
        (combo: any, index: number) => {
          // Lấy giá trị variation1 từ options của variation đầu tiên
          const variation1 = product.variations[0]?.options[index]?.value || "";

          return {
            id: combo.id,
            name: variation1,
            variation1: variation1,
            variation2: combo.variation2 || "",
            price: combo.price || 0,
            stock: combo.stock || 0,
            sku: combo.sku || "",
            image: combo.image || "",
            shipping: combo.shipping
              ? {
                  weight: combo.shipping.weight,
                  dimensions: {
                    length: combo.shipping.dimensions?.length,
                    width: combo.shipping.dimensions?.width,
                    height: combo.shipping.dimensions?.height,
                  },
                }
              : undefined,
          };
        }
      );

      console.log("Setting combinations:", formattedCombinations);
      setCombinations(formattedCombinations);

      // Kiểm tra shipping info để set switch
      const hasShippingInfo = formattedCombinations.some(
        (combo: any) =>
          combo.shipping?.weight ||
          combo.shipping?.dimensions?.length ||
          combo.shipping?.dimensions?.width ||
          combo.shipping?.dimensions?.height
      );
      setUseVariationShipping(hasShippingInfo);
    }
  }, [product]);

  // Add loading state when loading data
  if (!init) return <Loading />;

  return (
    <StyledForm
      form={form}
      name="create_product_form"
      onFinish={onFinish}
      layout="horizontal"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{
        isActive: false,
        isApprove: false,
      }}
    >
      {loading && <LoadingOverlay />}

      <Row gutter={24}>
        <Col span={24}>
          <StyledTabsContainer>
            <StyledTabs activeKey={activeTab} onChange={scrollToSection}>
              <TabPane tab="Basic Information" key="1" />
              <TabPane tab="Product Details" key="2" />
              <TabPane tab="Shipping" key="3" />
            </StyledTabs>
          </StyledTabsContainer>

          <FormContainer>
            <SectionContainer ref={basicInfoRef}>
              <FormSection>
                <Title level={4}>Basic Information</Title>
                <Form.Item
                  name="images"
                  label="Images"
                  rules={[{ required: true, message: "Please select images" }]}
                >
                  <ArrayImage />
                </Form.Item>

                <Form.Item
                  name="thumbnail"
                  label="Thumbnail"
                  rules={[
                    { required: true, message: "Please select thumbnail" },
                  ]}
                >
                  <Image width={100} height={100} />
                </Form.Item>

                <Form.Item
                  name="name"
                  label="Product Name"
                  rules={[
                    { required: true, message: "Please enter product name" },
                  ]}
                >
                  <Input placeholder="Enter product name" />
                </Form.Item>

                <Form.Item
                  name="originPrice"
                  label="Original Price"
                  rules={[
                    { required: true, message: "Please enter original price" },
                    {
                      type: "number",
                      transform: (value) => Number(value),
                      message: "Please enter valid number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    precision={0}
                    placeholder="Enter original price"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      Number(value!.replace(/\$\s?|(,*)/g, ""))
                    }
                    prefix="đ"
                  />
                </Form.Item>

                <Form.Item
                  name="paymentCash"
                  label="Selling Price"
                  rules={[
                    { required: true, message: "Please enter selling price" },
                    {
                      type: "number",
                      transform: (value) => Number(value),
                      message: "Please enter valid number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    precision={0}
                    placeholder="Enter selling price"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) =>
                      Number(value!.replace(/\$\s?|(,*)/g, ""))
                    }
                    prefix="đ"
                  />
                </Form.Item>

                <Form.Item
                  name="categoryId"
                  label="Category"
                  rules={[
                    { required: true, message: "Please select a category" },
                  ]}
                >
                  <CategorySelect
                    pageId={PAGE_ID}
                    valueInitial={{
                      categoryId: form.getFieldValue("categoryId"),
                      subCategoryId: form.getFieldValue("subCategoryId"),
                    }}
                    onChange={(value) => {
                      form.setFieldsValue({
                        categoryId: value.categoryId,
                        subCategoryId: value.subCategoryId,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item name="subCategoryId" hidden>
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item name="description" label="Product Description">
                  <RichText height={200} width={"100%"} />
                </Form.Item>

                <Form.Item
                  name="tags"
                  label="Tags"
                  tooltip="Enter tag and press Enter to add"
                >
                  <InputTag
                    placeholder="Enter tag and press Enter"
                    error="Tag" // Message shown when tag is duplicate
                  />
                </Form.Item>
              </FormSection>
            </SectionContainer>

            <SectionContainer ref={productDetailsRef}>
              <FormSection>
                <Title level={4}>Product Details</Title>
                <BrandFormItem />
                <ProductVariations
                  variations={variations}
                  onAddVariation={addVariation}
                  onRemoveVariation={removeVariation}
                  onVariationNameChange={handleVariationNameChange}
                  onOptionChange={handleOptionChange}
                  onOptionKeyPress={handleOptionKeyPress}
                  onRemoveOption={removeOption}
                  onDragStart={handleDragStart}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                />

                {combinations.length > 0 && (
                  <Card title="Variation List" style={{ marginTop: "24px" }}>
                    <div style={{ marginBottom: "16px" }}>
                      <Row gutter={16}>
                        <Col span={6}>
                          <Input
                            prefix="đ"
                            placeholder="Price"
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (!isNaN(value)) {
                                form.setFieldsValue({ allPrice: value });
                              }
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            placeholder="Stock"
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (!isNaN(value)) {
                                form.setFieldsValue({ allStock: value });
                              }
                            }}
                          />
                        </Col>
                        <Col span={6}>
                          <Input
                            placeholder="SKU"
                            onChange={(e) =>
                              form.setFieldsValue({ allSku: e.target.value })
                            }
                          />
                        </Col>
                        {useVariationShipping && (
                          <>
                            <Col span={24} style={{ marginTop: 16 }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 1,
                                  }}
                                >
                                  <InputNumber
                                    placeholder="Weight"
                                    style={{ flex: 1 }}
                                    min={0}
                                    keyboard={false}
                                    bordered={true}
                                    onChange={(value) =>
                                      form.setFieldsValue({ allWeight: value })
                                    }
                                  />
                                  <div style={{ marginLeft: 8 }}>gr</div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flex: 3,
                                    gap: "8px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    <InputNumber
                                      placeholder="L"
                                      style={{ flex: 1 }}
                                      min={0}
                                      keyboard={false}
                                      bordered={true}
                                      onChange={(value) =>
                                        form.setFieldsValue({
                                          allLength: value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    <InputNumber
                                      placeholder="W"
                                      style={{ flex: 1 }}
                                      min={0}
                                      keyboard={false}
                                      bordered={true}
                                      onChange={(value) =>
                                        form.setFieldsValue({ allWidth: value })
                                      }
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                    }}
                                  >
                                    <InputNumber
                                      placeholder="H"
                                      style={{ flex: 1 }}
                                      min={0}
                                      keyboard={false}
                                      bordered={true}
                                      onChange={(value) =>
                                        form.setFieldsValue({
                                          allHeight: value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div>cm</div>
                                </div>
                              </div>
                            </Col>
                          </>
                        )}
                        <Col span={6}>
                          <Button
                            type="primary"
                            style={{ marginBottom: 16 }}
                            onClick={() => {
                              const values = form.getFieldsValue([
                                "allPrice",
                                "allStock",
                                "allSku",
                                "allWeight",
                                "allLength",
                                "allWidth",
                                "allHeight",
                              ]);
                              applyToAll(
                                values.allPrice,
                                values.allStock,
                                values.allSku,
                                values.allWeight,
                                values.allLength,
                                values.allWidth,
                                values.allHeight
                              );
                            }}
                          >
                            Apply to all variations
                          </Button>
                        </Col>
                      </Row>

                      <StyledTable
                        dataSource={combinations}
                        pagination={false}
                        bordered
                        columns={[
                          {
                            title: variations[0]?.name || "Variation 1",
                            key: "variation1",
                            render: (_, record, index) => {
                              const rowSpan = combinations.filter(
                                (c) => c.variation1 === record.variation1
                              ).length;
                              const shouldRenderCell =
                                index === 0 ||
                                combinations[index - 1].variation1 !==
                                  record.variation1;

                              return shouldRenderCell
                                ? {
                                    children: (
                                      <div
                                        style={{
                                          display: "block",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span>{record.variation1}</span>
                                        <Image
                                          value={record.image}
                                          onChange={(url) =>
                                            handleCombinationChange(
                                              index,
                                              "image",
                                              url
                                            )
                                          }
                                          width={60}
                                          height={60}
                                        />
                                      </div>
                                    ),
                                    props: { rowSpan },
                                  }
                                : { props: { rowSpan: 0 } };
                            },
                          },
                          ...(variations.length > 1
                            ? [
                                {
                                  title: variations[1]?.name || "Variation 2",
                                  key: "variation2",
                                  render: (_, record) => record.variation2,
                                },
                              ]
                            : []),
                          {
                            title: (
                              <div style={{ color: "#ff4d4f" }}>
                                <span
                                  style={{ color: "#ff4d4f", marginRight: 4 }}
                                >
                                  *
                                </span>
                                Price
                              </div>
                            ),
                            key: "price",
                            render: (_, record, index) => (
                              <InputNumber
                                style={{ width: "100%" }}
                                value={record.price}
                                onChange={(value) => {
                                  if (value !== null) {
                                    handleCombinationChange(
                                      index,
                                      "price",
                                      value
                                    );
                                  }
                                }}
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value) =>
                                  Number(value!.replace(/\$\s?|(,*)/g, ""))
                                }
                                prefix="đ"
                                placeholder="Enter value"
                              />
                            ),
                          },
                          {
                            title: (
                              <div>
                                <span
                                  style={{ color: "#ff4d4f", marginRight: 4 }}
                                >
                                  *
                                </span>
                                Stock
                              </div>
                            ),
                            key: "stock",
                            render: (_, record, index) => {
                              const isEditing = !!props.query?.id;
                              return (
                                <InputNumber
                                  style={{ width: "100%" }}
                                  value={record.stock}
                                  onChange={(value) => {
                                    if (value !== null && !isEditing) {
                                      handleCombinationChange(
                                        index,
                                        "stock",
                                        value
                                      );
                                    }
                                  }}
                                  disabled={isEditing}
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  parser={(value) =>
                                    Number(value!.replace(/\$\s?|(,*)/g, ""))
                                  }
                                  placeholder="0"
                                />
                              );
                            },
                          },
                          {
                            title: "SKU",
                            key: "sku",
                            render: (_, record, index) => (
                              <Input
                                value={record.sku}
                                onChange={(e) =>
                                  handleCombinationChange(
                                    index,
                                    "sku",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter value"
                              />
                            ),
                          },
                          ...shippingColumns,
                        ]}
                      />
                    </div>
                  </Card>
                )}
              </FormSection>
            </SectionContainer>

            <SectionContainer ref={shippingRef}>
              <FormSection>
                <Title level={4}>Shipping</Title>

                <Form.Item
                  label={<div>Set weight & dimensions for each variation</div>}
                >
                  <Switch
                    checked={useVariationShipping}
                    onChange={setUseVariationShipping}
                  />
                </Form.Item>

                {!useVariationShipping && (
                  <>
                    <Form.Item
                      name="weight"
                      label="Weight (After packaging)"
                      rules={[
                        { required: true, message: "Please enter weight" },
                        {
                          transform: (value) => Number(value),
                          type: "number",
                          message: "Please enter number",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={1}
                        precision={0}
                        placeholder="Enter weight"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) =>
                          Number(value!.replace(/\$\s?|(,*)/g, ""))
                        }
                      />
                    </Form.Item>

                    <Form.Item label="Package Dimensions">
                      <Space>
                        <Form.Item name="length" noStyle>
                          <InputNumber placeholder="L" min={0} precision={0} />
                        </Form.Item>
                        <Form.Item name="width" noStyle>
                          <InputNumber placeholder="W" min={0} precision={0} />
                        </Form.Item>
                        <Form.Item name="height" noStyle>
                          <InputNumber placeholder="H" min={0} precision={0} />
                        </Form.Item>
                        <span>cm</span>
                      </Space>
                    </Form.Item>
                  </>
                )}
              </FormSection>
            </SectionContainer>
          </FormContainer>

          <Form.Item name="isActive" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="isApprove" hidden>
            <Input type="hidden" />
          </Form.Item>
        </Col>
      </Row>

      <FormActions isAtBottom={isAtBottom}>
        <Button
          type="default"
          icon={<LeftOutlined />}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
        <Button type="dashed" onClick={() => handleSave(form, false)}>
          Save & Hide
        </Button>
        <Button type="primary" onClick={() => handleSave(form, true)}>
          Save & Show
        </Button>
      </FormActions>
    </StyledForm>
  );
}

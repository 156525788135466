export default {
  moneySymbol: '₫',
  tableForm: {
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Enter data',
    selectPlaceholder: 'Please select',
  },
  alert: {
    clear: 'Clear',
    selected: 'selected',
    item: 'item',
  },
  pagination: {
    total: {
      range: ' ',
      total: 'on',
      item: 'record',
    },
  },
  tableToolBar: {
    leftPin: 'Pin left',
    rightPin: 'Pin right',
    noPin: 'Unpin',
    leftFixedTitle: 'Fixed left',
    rightFixedTitle: 'Fixed right',
    noFixedTitle: 'Not fixed',
    reset: 'Reset',
    columnDisplay: 'Column display',
    columnSetting: 'Column setting',
    fullScreen: 'Full screen mode',
    exitFullScreen: 'Exit full screen mode',
    reload: 'Reload',
    density: 'Display density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
};

import { useState, useCallback } from 'react';
import { OrderFilters } from '../types';

export const useOrderFilters = () => {
  const [filters, setFilters] = useState<OrderFilters>({
    status: '',
    subStatus: 'all',
    queryInput: {},
    dateRange: undefined,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const buildQueryParams = useCallback(() => {
    const queryParams: Record<string, any> = {
      skip: (currentPage - 1) * pageSize,
      limit: pageSize,
    };

    if (filters.queryInput && Object.keys(filters.queryInput).length > 0) {
      queryParams.queryInput = JSON.stringify(filters.queryInput);
    }

    if (filters.status) {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }
      
      const queryInputObj = JSON.parse(queryParams.queryInput);
      
      if (filters.status === 'wait_for_confirmation') {
        if (filters.subStatus === 'all') {
          queryInputObj.status = {
            in: ['wait_for_confirmation', 'confirmed']
          };
        } else if (filters.subStatus === 'pending') {
          queryInputObj.status = 'wait_for_confirmation';
        } else if (filters.subStatus === 'processed') {
          queryInputObj.status = 'confirmed';
        }
      } else if (filters.status !== 'all') {
        queryInputObj.status = filters.status;
      }
      
      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    // Xử lý shipCarrier
    if (filters.shipCarrier && filters.shipCarrier !== 'all') {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }
      
      const queryInputObj = JSON.parse(queryParams.queryInput);
      queryInputObj.shipCarrier = filters.shipCarrier;
      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    if (filters.dateRange) {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }
      
      const queryInputObj = typeof queryParams.queryInput === 'string' 
        ? JSON.parse(queryParams.queryInput) 
        : queryParams.queryInput;

      queryInputObj.createdAt = {
        gte: filters.dateRange[0],
        lte: filters.dateRange[1]
      };

      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    return queryParams;
  }, [filters, currentPage, pageSize]);

  const updateFilter = useCallback((key: keyof OrderFilters, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
    setCurrentPage(1); // Reset về trang 1 khi thay đổi filter
  }, []);

  const updatePagination = useCallback((page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({
      status: '',
      subStatus: 'all',
      queryInput: {},
      dateRange: undefined,
    });
    setCurrentPage(1);
  }, []);

  return {
    filters,
    currentPage,
    pageSize,
    updateFilter,
    updatePagination,
    resetFilters,
    buildQueryParams,
  };
}; 
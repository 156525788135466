import axios from 'axios';
import { IMessage, MESSAGE_TYPE, SENDER_TYPE, MESSAGE_STATUS, IOrder, IProduct } from '@src/types/chat';
import * as request from '@src/util/request';
import local from '@src/util/local';

interface Message {
  id: string;
  content: string;
  isMine: boolean;
  timestamp: Date;
  type: MESSAGE_TYPE;
  status?: MESSAGE_STATUS;
  attachments?: string[];
  senderId?: number;
  senderType?: SENDER_TYPE;
  recipientId?: number;
  orderInfo?: IOrder;
  productInfo?: IProduct;
}

export const fetchMessages = async (conversationId: string, page: number, limit: number) => {
  try {
    const response = await axios.get('/admin/chat/get-list-message', {
      params: {
        conversationId,
        skip: (page - 1) * limit,
        limit,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.data.code === 0) {
      const { data, total } = response.data;
      
      const formattedMessages = data.map((msg: any) => ({
        id: msg._id,
        content: msg.content || '',
        isMine: msg.senderType === 'SELLER',
        timestamp: new Date(Number(msg.timestamp)),
        type: msg.messageType,
        status: msg.status,
        attachments: msg.attachments,
        orderInfo: msg.orderInfo
      }));

      return {
        messages: formattedMessages,
        total,
        hasMore: data.length === limit
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

export const uploadImage = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("images", file);
    const response = await request.upload("/api/file/upload-image", formData);
    if (response.created?.[0]?.url) {
      return response.created[0].url;
    }
    return null;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const handleScroll = (messageListRef: React.RefObject<HTMLDivElement>, loading: boolean, hasMore: boolean, page: number, setPage: (page: number) => void) => {
  if (!messageListRef.current) return;

  const { scrollTop } = messageListRef.current;
  if (scrollTop === 0 && !loading && hasMore) {
    const nextPage = page + 1;
    setPage(nextPage);
  }
};

export const scrollToBottom = (messageListRef: React.RefObject<HTMLDivElement>) => {
  if (messageListRef.current) {
    setTimeout(() => {
      messageListRef.current?.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }
};
export const markMessageAsRead = async (conversationId: string, messageId?: string) => {
  try {
    const payload = messageId 
      ? { conversationId, messageId }
      : { conversationId };
      
    const response = await axios.post(
      '/admin/chat/update-is-read',
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    return response.data.code === 0;
  } catch (error) {
    console.error('Error marking message as read:', error);
    throw error;}}

export const createMessage = (
  content: string,
  type: MESSAGE_TYPE,
  conversationId: string,
  recipientId: number,
  attachments?: string[],
  orderInfo?: IOrder,
  productInfo?: IProduct
): IMessage => {
  const userInfo = local.get('user_id');
  const senderId = userInfo?.id;

  return {
    conversationId,
    senderId,
    senderType: SENDER_TYPE.SELLER,
    recipientId,
    content,
    messageType: type,
    timestamp: Date.now(),
    status: MESSAGE_STATUS.SENT,
    ...(attachments && { attachments }),
    ...(orderInfo && { orderInfo }),
    ...(productInfo && { productInfo })
  };
};

export const createLocalMessage = (
  content: string,
  type: MESSAGE_TYPE,
  senderId: number,
  recipientId: number,
  attachments?: string[],
  orderInfo?: IOrder,
  productInfo?: IProduct
): Message => {
  return {
    id: Date.now().toString(),
    content,
    isMine: true,
    timestamp: new Date(),
    type,
    status: MESSAGE_STATUS.SENT,
    senderId,
    senderType: SENDER_TYPE.SELLER,
    recipientId,
    ...(attachments && { attachments }),
    ...(orderInfo && { orderInfo }),
    ...(productInfo && { productInfo })
  };
};

export const getPinnedConversations = (): string[] => {
  const pinned = localStorage.getItem('pinnedConversations');
  return pinned ? JSON.parse(pinned) : [];
};

export const savePinnedConversations = (pinnedIds: string[]) => {
  localStorage.setItem('pinnedConversations', JSON.stringify(pinnedIds));
};

export const handlePinConversation = async (
  conversationId: string, 
  isPinned: boolean,
  updateConversations: (updater: (conversations: any[]) => any[]) => void
) => {
  try {
    const pinnedConversations = getPinnedConversations();
    const newPinnedConversations = isPinned
      ? pinnedConversations.filter(id => id !== conversationId)
      : [...pinnedConversations, conversationId];
    
    savePinnedConversations(newPinnedConversations);
    
    updateConversations(prev => prev.map(conv => 
      conv._id === conversationId 
        ? { ...conv, isPinned: !isPinned }
        : conv
    ));
    
  } catch (error) {
    console.error('Error pinning/unpinning conversation:', error);
    throw error;
  }
}; 
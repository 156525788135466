import React, { useEffect, useState } from 'react';
import Image from '@src/packages/pro-component/schema/Image';
import ArrayModel from '@src/packages/pro-component/schema/ArrayModel';
import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Space,
    Switch,
} from 'antd';
import _ from 'lodash';
import { helper } from '@src/controls/controlHelper';
import Loading from '@src/components/Loading';
import TextArea from 'antd/lib/input/TextArea';
import ArrayImage from '@src/packages/pro-component/schema/ArrayImage';
import SingleModel from '@src/packages/pro-component/schema/SingleModel';

const PAGE_ID = 240;

export default function PartnerFormCtrl(props: any) {
    const params = props.query;

    const [form] = Form.useForm();
    const [pageInfo, setPageInfo] = useState<any>({});
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProductInfo();
    }, []);

    const getProductInfo = async () => {
        try {
            const _pageInfo = await helper.getPage(PAGE_ID);
            setPageInfo(_pageInfo);

            if (params.mode === 'edit') {
                const rs: any = await helper.callPageApi(_pageInfo, 'find', {
                    queryInput: JSON.stringify({ id: params.id })
                });
                const data = rs.data.data[0];

                Object.entries(data).forEach(([property, value]) => {
                    form.setFieldsValue({ [property]: value });
                });

            }
            setInit(true);
        } catch (error) {
            console.error('Error fetching product info:', error);
            setInit(true);
        }
    };

    const onFinish = async (values: any) => {
        try {
            setLoading(true);
            console.log('Success:', values);
            const dataPatch = {
                isActive: values.isActive,
                isMonopoly: values.isMonopoly,
                providerId: values.providerId,
                logo: values.logo,
                images: values.images,
                phone: values.phone,
                deliciousFoodProductTypeId: values.deliciousFoodProductTypeId,
                website: values.website,
                email: values.email,
                hotSequence: values.hotSequence,
                slogan: values.slogan,
                name: values.name,
            };

            let rs: any;
            if (params.mode === 'edit') {
                rs = await helper.callPageApi(
                    pageInfo,
                    'edit',
                    { id: params.id, ...dataPatch }
                );
            } else {
                // Nếu đang tạo mới, gọi API tạo mới
                rs = await helper.callPageApi(
                    pageInfo,
                    'create',
                    dataPatch
                );
            }

            if (rs.status !== 200 && rs?.data?.code !== 0) throw rs;
            helper.alert('Cập nhật đối tác thành công', 'success');
            props.onClose();
        } catch (error: any) {
            console.log(error);
            if (error.message) {
                message.error(error.message);
            }
            if (error.response?.data?.errors) {
                message.error(error.response.data.errors);
            }
            helper.alert('Cập nhật đối tác sản phẩm thất bại', 'error');
        }
        setLoading(false);
    };
    if (!init) return <Loading />;

    return (
        <Form form={form} name='dynamic_form_complex' onFinish={onFinish}>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        backgroundColor: 'rgba(0,0,0,.25)',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Loading />
                </div>
            )}

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='isActive'
                        labelCol={{ span: 4 }}
                        labelAlign='left'
                        label='Trạng thái'
                        rules={[{ required: true }]}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='isMonopoly'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Là đối tác độc quyền'
                        rules={[{ required: true }]}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='providerId'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Thuộc nhà cung cấp'
                        rules={[{ required: true, message: 'Nhà cung cấp bắt buộc phải chọn' }]}
                    >
                        <SingleModel
                            schema={{
                                name: 'Nha cung cấp',
                                field: 'providerId',
                                widget: 'SingleModel',
                                type: 'number',
                                api: 'findProvider',
                                modelSelectField: 'id$$ID,name$$Tên',
                                pageId: PAGE_ID,
                            }}
                            value={'providerId'}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='logo'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Logo(100x100)'
                        rules={[{ required: true }]}
                    >
                        <Image />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='images'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Ảnh bìa (335x172)'
                        rules={[{ required: true, message: 'Trường ảnh bìa là bắt buộc' }]}
                    >
                        <ArrayImage />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='name'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Tên Đối Tác'
                        rules={[{ required: true, message: 'Trường Tên Đối Tác là bắt buộc' }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='deliciousFoodProductTypeId'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Món ngon'
                        rules={[{ required: true }]}
                        extra="Chọn tối đa 3 món ngon từ danh sách."
                    >
                        <ArrayModel
                            schema={{
                                name: 'Danh sách món ngon',
                                field: 'productIds',
                                widget: 'ArrayModel',
                                type: 'number',
                                api: 'findProductType',
                                modelSelectField: 'id$$ID,name$$Tên',
                                pageId: PAGE_ID,
                            }}
                            value={'productType'}
                            onChange={(ids) => {
                                form.setFieldsValue({ productIds: ids });
                            }}
                            clickedRecordsCount={3}
                            isClicked={true}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='phone'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Điện thoại'
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='website'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Website'
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='email'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                        label='Email'
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        name='hotSequence'
                        label='Thứ tự hot'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                    >
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name='slogan'
                        label='Khẩu hiệu'
                        labelCol={{ span: 8 }}
                        labelAlign='left'
                    >
                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                </Col>
            </Row>
            <Space
                style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 14 }}
            >
                <Button type='primary' htmlType='submit'>
                    Lưu
                </Button>
            </Space>
        </Form>
    );
}

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Button,
  message,
  Timeline,
  Card,
  Avatar,
} from "antd";
import {
  CalendarOutlined,
  CopyOutlined,
  LeftOutlined,
  CarOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  UserOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  CheckCircleFilled,
  TagOutlined,
  UpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { helper } from "@src/controls/controlHelper";
import Loading from "@src/components/Loading";
import "./OrderItemFormCtrlV2.css";
const { Text, Title } = Typography;

const PAGE_ID = process.env.REACT_APP_PAGE_ORDER_ITEM_ID
  ? Number(process.env.REACT_APP_PAGE_ORDER_ITEM_ID)
  : 301;

const OrderItemFormCtrlV2: React.FC = (props: any) => {
  const params = props.query;
  const [orderItem, setOrderItem] = useState<any>([]);
  const [orderShip, setOrderShip] = useState<any>({});
  const [init, setInit] = useState(false);
  const [, setStore] = useState<any>({});
  const [, setCustomer] = useState<any>({});
  const [, setPageInfo] = useState<any>({});

  const getOrderItemInfo = async () => {
    try {
      const _pageInfo = await helper.getPage(PAGE_ID);
      setPageInfo(_pageInfo);
      const orderShip: any = await helper.callPageApi(
        _pageInfo,
        "get_order_ship",
        {
          queryInput: JSON.stringify({ id: params.id }),
        }
      );
      const { orderItemInfos, storeInfo } = orderShip?.data?.data[0] || {};
      const orderShipData = orderShip?.data?.data[0] || {};
      const customerData: any = await helper.callPageApi(
        _pageInfo,
        "get_customer",
        {
          queryInput: JSON.stringify({ id: orderShipData.customerId }),
        }
      );

      setStore(storeInfo);
      setCustomer(customerData?.data?.data[0]);
      setOrderItem(orderItemInfos);
      setOrderShip(orderShipData);
      setInit(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderItemInfo();
  }, [params.id]);

  const formatNumber = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const statusOrder = (
    status: string
  ): { text: string; icon: React.ReactNode } => {
    switch (status) {
      case "wait_payment":
        return {
          text: "Chờ thanh toán",
          icon: <ClockCircleOutlined style={{ color: "#faad14" }} />,
        };
      case "pending":
        return {
          text: "Chờ xử lý",
          icon: <ClockCircleOutlined style={{ color: "#faad14" }} />,
        };
      case "wait_for_confirmation":
        return {
          text: "Chờ xác nhận",
          icon: <ClockCircleOutlined style={{ color: "#1890ff" }} />,
        };
      case "processing":
        return {
          text: "Đang xử lý",
          icon: <SyncOutlined spin style={{ color: "#1890ff" }} />,
        };
      case "completed":
        return {
          text: "Đã hoàn thành",
          icon: <CheckCircleOutlined style={{ color: "#52c41a" }} />,
        };
      case "shipping":
        return {
          text: "Đang giao hàng",
          icon: <CarOutlined style={{ color: "#52c41a" }} />,
        };
      case "refund":
        return {
          text: "Đang hoàn tiền",
          icon: <RedoOutlined style={{ color: "#ff4d4f" }} />,
        };
      case "refunded":
        return {
          text: "Đã hoàn tiền",
          icon: <RedoOutlined style={{ color: "#ff4d4f" }} />,
        };
      case "canceled":
        return {
          text: "Đã hủy",
          icon: <CloseCircleOutlined style={{ color: "#ff4d4f" }} />,
        };
      default:
        return {
          text: status,
          icon: <QuestionCircleOutlined style={{ color: "#8c8c8c" }} />,
        };
    }
  };

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp);
    return date.toLocaleString("vi-VN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const [isShipHistoryVisible, setIsShipHistoryVisible] = useState(false);

  const toggleShipHistory = () => {
    setIsShipHistoryVisible(!isShipHistoryVisible);
  };

  const statusMapping: Record<string, string> = {
    pending: "Đang chờ xử lý",
    picking: "Đang lấy hàng",
    delivering: "Đang giao hàng",
    cancelled: "Đã hủy",
    delivered: "Đã giao",
  };

  const parseShipMsg = (shipMsg: any): React.ReactNode => {
    if (Array.isArray(shipMsg) && shipMsg.length > 0) {
      const sortedShipMsg = [...shipMsg].sort((a, b) => b.time - a.time);
      return (
        <>
          <div className="ship-history-header">
            <Space align="center">
              <Title level={5} style={{ margin: "24px 0 0 0" }}>
                Lịch sử vận chuyển
              </Title>
              <Button
                type="text"
                icon={isShipHistoryVisible ? <UpOutlined /> : <DownOutlined />}
                onClick={toggleShipHistory}
                className="collapse-button"
                style={{ marginTop: "24px" }}
              />
            </Space>
          </div>
          <div style={{ 
            maxHeight: isShipHistoryVisible ? '1000px' : '0',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
            marginTop: isShipHistoryVisible ? '16px' : '0'
          }}>
            <Timeline>
              {sortedShipMsg.map((msg, index) => (
                <Timeline.Item
                  key={index}
                  dot={
                    index === 0 ? (
                      <CheckCircleFilled style={{ color: "#52c41a" }} />
                    ) : (
                      <CarOutlined style={{ color: "#1890ff" }} />
                    )
                  }
                >
                  <Space direction="vertical" size={0}>
                    <Text
                      strong
                      style={{ color: index === 0 ? "#52c41a" : "#262626" }}
                    >
                      {statusMapping[msg.status] || "Trạng thái không xác định"}
                    </Text>
                    <Text type="secondary">{formatDate(msg.time * 1000)}</Text>
                    <Text>{msg.message}</Text>
                  </Space>
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </>
      );
    }
    return null;
  };

  const renderOrderItems = () => {
    return orderItem.map((item: any, index: number) => (
      <div key={index} className="order-item">
        {/* <img src={item.images[0]} alt={item.name} className='item-image' /> */}
        <Avatar
          shape="square"
          size={64}
          src={item.images[0]}
          icon={<TagOutlined />}
        />
        <div className="item-details">
          <Text strong className="item-name">
            {item.name}
          </Text>
          <Text className="item-category">{item.category}</Text>
        </div>
        <div className="item-quantity">
          <Text>x{item.quantity}</Text>
        </div>
        <div className="item-price">
          <Text className="item-price-current">
            {formatNumber(item.itemPrice)}
          </Text>
          {item.originalPrice && item.originalPrice !== item.itemPrice && (
            <Text className="item-price-original">
              {formatNumber(item.originalPrice)}
            </Text>
          )}
        </div>
        <div className="item-total-price">
          <Text className="item-price-total">
            {formatNumber(item.itemPrice * item.quantity)}đ
          </Text>
        </div>
      </div>
    ));
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Đã sao chép mã thanh toán");
      },
      (err) => {
        console.error("Could not copy text: ", err);
        message.error("Không thể sao chép mã thanh toán");
      }
    );
  };

  const renderVouchers = () => {
    const voucherInfo = orderShip?.discountInfo?.voucherInfo;

    // const renderDiscountValue = () => {
    //   return voucherInfo?.description
    //     ? `Mô tả: ${voucherInfo.description}`
    //     : 'Không có mô tả';
    // };

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    return (
      (voucherInfo && (
        <Card className="voucher-card">
          <div className="voucher-content">
            <Avatar
              shape="square"
              size={64}
              src={voucherInfo.images[0]}
              icon={<TagOutlined />}
            />
            <div className="voucher-details">
              <Text strong>{voucherInfo.name}</Text>
              {/* <Text>{renderDiscountValue()}</Text> */}
              {voucherInfo.expiredAt && (
                <Text type="secondary">
                  Hạn sử dụng: {formatDate(voucherInfo.expiredAt)}
                </Text>
              )}
              {voucherInfo.category && (
                <Text type="secondary">Loại: {voucherInfo.category}</Text>
              )}
            </div>
          </div>
        </Card>
      )) || <Text>Không có voucher nào được áp dụng</Text>
    );
  };

  if (!init) return <Loading />;
  return (
    <div className="order-info-container">
      <Row gutter={[24, 24]}>
        <Col span={16}>
          <div className="order-main-info">
            <Title level={4}>Địa chỉ nhận hàng</Title>
            <Space direction="vertical" size="small" className="address-info">
              <Text strong>
                <UserOutlined className="info-icon" /> {orderShip?.receiveName}
              </Text>
              <Text>
                <PhoneOutlined className="info-icon" />{" "}
                {orderShip?.receivePhone}
              </Text>
              <Text>
                <EnvironmentOutlined className="info-icon" />{" "}
                {orderShip?.receiveAddress}
              </Text>
            </Space>
          </div>

          <Divider />

          <div className="order-status">
            <Title level={4}>Trạng thái đơn hàng</Title>
            <div className="current-status">
              {statusOrder(orderShip?.status).icon}
              <Text strong className="status-text">
                {statusOrder(orderShip?.status).text}
              </Text>
            </div>
            {/* <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <div>
                <Text strong>Mã vận đơn: </Text>
                <Text>{orderShip?.shipOrderCodeId || "Chưa có thông tin"}</Text>
              </div>
              <div>
                <Text strong>Theo dõi vận chuyển: </Text>
                {orderShip?.linkFulfillmentPartnerShip ? (
                  <a
                    className="link-tracking"
                    href={orderShip?.linkFulfillmentPartnerShip}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {orderShip?.linkFulfillmentPartnerShip ||
                      "Chưa có thông tin"}
                  </a>
                ) : (
                  <Text>Chưa có thông tin</Text>
                )}
              </div>
            </Space> */}
            {parseShipMsg(orderShip?.shipMsg)}
          </div>

          <Divider />

          <div className="order-items">
            <Title level={4}>Sản phẩm đặt hàng</Title>
            {renderOrderItems()}
          </div>
        </Col>

        <Col span={8}>
          <div className="order-summary">
            <Title level={4}>Thông tin đơn hàng</Title>
            <InfoItem label="Mã đơn hàng" value={`#${orderShip?.orderId}`} />
            <InfoItem
              label="Mã thanh toán"
              value={`${orderShip?.paymentTransactionId}`}
              copyable
              onCopy={() => copyToClipboard(orderShip?.paymentTransactionId)}
            />
            <InfoItem
              label="Thời gian đặt hàng"
              value={formatDate(orderShip?.createdAt)}
              icon={<CalendarOutlined />}
            />

            <Divider />

            <Title level={5}>Voucher sử dụng</Title>
            {renderVouchers()}

            <Divider />

            <InfoItem
              label="Tổng tiền sản phẩm"
              value={`${formatNumber(orderShip?.moneyCash)}`}
            />
            <InfoItem
              label="Phí vận chuyển"
              value={`${formatNumber(orderShip?.shipMoney)}`}
            />
            <InfoItem
              label="Điểm mPoint"
              value={`-${formatNumber(orderShip?.mpoint)}`}
            />
            <InfoItem
              label="Giảm giá phí vận chuyển"
              value={`-${formatNumber(orderShip?.discountShip)}`}
            />
            <InfoItem
              label="Giảm giá từ voucher"
              value={`-${formatNumber(orderShip?.discount)}`}
            />
            <InfoItem
              label="Phương thức thanh toán"
              value={`${orderShip?.paymentMethodShip}`}
            />
            <Divider />
            <div className="total-money">
              <Text className="total-money-label">Thành tiền</Text>
              <Text className="total-money-value">
                {formatNumber(orderShip?.totalMoney)}đ
              </Text>
            </div>
          </div>
        </Col>
      </Row>

      <Space
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 24 }}
      >
        <Button
          icon={<LeftOutlined />}
          onClick={() => props.onClose()}
          style={{ marginBottom: "16px" }}
        >
          Quay lại
        </Button>
      </Space>
    </div>
  );
};

interface InfoItemProps {
  label: string;
  value: string;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  copyable?: boolean;
  onCopy?: () => void;
}

const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  icon,
  style,
  copyable,
  onCopy,
}) => (
  <div className="info-item" style={style}>
    <Text type="secondary" className="label">
      {label}:
    </Text>
    <div className="info-value-container">
      <Text strong className="info-value">
        {icon && <span className="info-icon">{icon}</span>}
        {value}
      </Text>
      {copyable && <CopyOutlined className="copy-icon" onClick={onCopy} />}
    </div>
  </div>
);

export default OrderItemFormCtrlV2;

import { useState, useEffect } from "react";
import { helper } from "@src/controls/controlHelper";
import { Category, CategoryResponse } from "./types";

export const useCategorySelect = (
  pageId: number,
  initialValue?: { categoryId?: number; subCategoryId?: number }
) => {
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedPath, setSelectedPath] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedParentCategory, setSelectedParentCategory] =
    useState<Category | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [currentQuery, setCurrentQuery] = useState("{}");
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    const initializeCategories = async () => {
      await fetchCategories();
      setInitialLoadComplete(true);
    };
    initializeCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (visible && initialValue?.categoryId) {
      const category = initialValue.subCategoryId
        ? categories.find((cat) => cat.id === initialValue.subCategoryId)
        : categories.find((cat) => cat.id === initialValue.categoryId);

      if (category) {
        if (initialValue.subCategoryId) {
          const parent = categories.find(
            (cat) => cat.id === initialValue.categoryId
          );
          if (parent) {
            setSelectedParentCategory(parent);
            setSelectedCategory(category);
            setSelectedPath(`${parent.name} > ${category.name}`);
          }
        } else {
          setSelectedCategory(category);
          setSelectedPath(category.name);
        }
      }
    }
  }, [visible, initialValue, categories]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setPage(1);
      setCurrentQuery("{}");
      setSelectedParentCategory(null);
      setSelectedCategory(null);
      setSelectedPath("");
      fetchCategories(1, "{}");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const fetchCategories = async (pageNum = 1, query = "{}") => {
    try {
      setLoading(true);
      const LIMIT_PER_PAGE = 50;
      const pageInfo = await helper.getPage(pageId);
      const response = (await helper.callPageApi(pageInfo, "get_categories", {
        queryInput: query,
        limit: LIMIT_PER_PAGE,
        skip: (pageNum - 1) * LIMIT_PER_PAGE,
      })) as CategoryResponse;

      if (response?.status === 200) {
        const { data, count } = response.data;
        if (pageNum === 1) {
          setCategories(data);
        } else {
          setCategories((prev) => [...prev, ...data]);
        }
        setTotal(count);
        setHasMore(pageNum * LIMIT_PER_PAGE < count);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);
    const newQuery = searchQuery.trim()
      ? JSON.stringify({
          name: { contains: searchQuery.trim() },
          parentId: 0,
        })
      : "{}";
    setCurrentQuery(newQuery);
    fetchCategories(1, newQuery);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchCategories(nextPage, currentQuery);
    }
  };

  const getParentCategories = () => {
    return categories.filter((cat) => cat.parentId === 0);
  };

  const getChildCategories = (parentId: number) => {
    return categories.filter((cat) => cat.parentId === parentId);
  };

  const handleSelect = (category: Category) => {
    const parent = categories.find((cat) => cat.id === category.parentId);
    const path = parent ? `${parent.name} > ${category.name}` : category.name;
    setSelectedPath(path);
    setSelectedCategory(category);
  };

  const handleParentSelect = (category: Category) => {
    if (!category.children || category.children.length === 0) {
      handleSelect(category);
      setSelectedParentCategory(null);
    } else {
      setSelectedParentCategory(category);
      setSelectedCategory(null);
      setSelectedPath("");
    }
  };

  const getDisplayValue = () => {
    console.log("INitialValue", initialValue);
    console.log("selectedCategory", selectedCategory);
    console.log("categories", categories);
    if (selectedCategory) {
      if (selectedCategory.parentId === 0) {
        return selectedCategory.name;
      } else {
        const parent = categories.find(
          (cat) => cat.id === selectedCategory.parentId
        );
        return parent
          ? `${parent.name} > ${selectedCategory.name}`
          : selectedCategory.name;
      }
    }

    if (initialValue?.categoryId) {
      if (initialValue.subCategoryId) {
        const parentCategory = categories.find(
          (cat) => cat.id === initialValue.categoryId
        );
        const subCategory = parentCategory?.children?.find(
          (cat) => cat.id === initialValue.subCategoryId
        );
        if (subCategory && parentCategory) {
          return `${parentCategory.name} > ${subCategory.name}`;
        }
      } else {
        const category = categories.find(
          (cat) => cat.id === initialValue.categoryId
        );
        if (category) {
          return category.name;
        }
      }
    }

    return "Select Category";
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return {
    visible,
    setVisible,
    categories,
    loading,
    searchQuery,
    selectedCategory,
    selectedPath,
    selectedParentCategory,
    page,
    hasMore,
    total,
    handleSearch,
    handleSelect,
    handleParentSelect,
    handleScroll,
    handleKeyPress,
    getParentCategories,
    getChildCategories,
    getDisplayValue,
    setSearchQuery,
    initialLoadComplete,
  };
};

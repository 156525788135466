import React from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { VariationOptionProps } from '../types';
import { OptionContainer, DragHandle } from '../styles';

export const VariationOption: React.FC<VariationOptionProps> = React.memo(({
  option,
  onDelete,
  onChange,
  ...props
}) => {
  const {
    'data-option-id': dataOptionId,
    placeholder,
    status,
    onKeyPress,
  } = props;

  const inputProps = {
    value: option.value,
    onChange,
    placeholder,
    status,
    onKeyPress,
    'data-option-id': dataOptionId,
  };

  return (
    <OptionContainer>
      <DragHandle />
      <Input {...inputProps} />
      {onDelete && (
        <Button type="text" icon={<DeleteOutlined />} onClick={onDelete} />
      )}
    </OptionContainer>
  );
});

export default VariationOption; 
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  DatePicker,
  Table,
  Button,
  Input,
  Switch,
  Form,
  InputNumber,
  TableProps,
  message,
} from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./flashSaleProduct.module.css";
import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Loading from "@src/components/Loading";
import SingleSelect from "@src/packages/pro-component/schema/SingleSelect";
import ArrayModel from "@src/packages/pro-component/schema/ArrayModel";
import { helper } from "@src/controls/controlHelper";
import moment from "moment";
import axios from "axios";
axios.defaults.baseURL = `${
  process.env.REACT_APP_URL ? process.env.REACT_APP_URL : window.location.origin
}/api`;

const PAGE_ID = 260;

const { TextArea } = Input;

interface Product {
  id: string;
  name: string;
  price: number;
  image: string;
  quantitySale?: number;
  salePrice?: number;
  flashSaleProductId?: number;
}

interface ProductType {
  id: any;
  name: string;
  image: string;
  products: Product[];
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

interface InputCreateFlashSale {
  name: string;
  description?: string;
  dayFlashSale: string;
  timeFrameId: number;
  isActive: boolean;
  products: {
    productId: number;
    productTypeId: number;
    normalPrice: number;
    salePrice: number;
    quantity: number;
    sequence: number;
  }[];
}

interface InputUpdateFlashSale {
  flashSaleProductId: number;
  name: string;
  description?: string;
  isActive?: boolean;
  flashSaleProductDetais: {
    flashSaleProductDetailId: number;
    productId: number;
    productTypeId: number;
    normalPrice: number;
    salePrice: number;
    quantity: number;
    sequence: number;
  }[];
}
const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const formatPriceToVND = (price: number): string => {
  return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);
};

const FlashSaleProductTypeFormCtrl: React.FC = (props: any) => {
  const params = props.query;
  const isUpdate = params.mode === "update";
  const token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const [selectedProductTypes, setSelectedProductTypes] = useState<
    ProductType[]
  >([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState<any>();
  const [isPageInfoLoaded, setIsPageInfoLoaded] = useState(false);
  const [init, setInit] = useState(false);
  const [storeIds, setStoreIds] = useState<any[]>([]);
  const cachedFlashIds = useRef<any>({});

  useEffect(() => {
    const fetchPageInfo = async () => {
      const _pageInfo = await helper.getPage(PAGE_ID);
      setPageInfo(_pageInfo);
      setIsPageInfoLoaded(true);
    };

    fetchPageInfo();
  }, []);

  useEffect(() => {
    if (isPageInfoLoaded) {
      const initializeData = async () => {
        if (isUpdate) {
          await getInfo();
        } else {
          setInit(true);
        }
      };

      initializeData();
    }
  }, [isPageInfoLoaded]);

  const getInfo = async () => {
    try {
      setLoading(true);
      let rs = await axios({
        method: "get",
        url: `/admin/product/flashsale/get-info?flashSaleProductId=${params.id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      let { data } = rs.data;
      const productTypeIdsSelected = data.flashSaleProductDetails.map(
        (item: any) => item.productTypeId
      );
      const productTypeIdsUnique: any = [...new Set(productTypeIdsSelected)];
      const partnerIdsSelected = data.flashSaleProductDetails.map(
        (item: any) => item.partnerId
      );
      const partnerIdsUnique: any = [...new Set(partnerIdsSelected)];
      form.setFieldsValue({
        flashSaleName: data.name,
        description: data.description,
        isActive: data.isActive,
        dayFlashSale: moment(data.dayFlashSale, "YYYY-MM-DD"),
        timeFrameId: data.timeFrameFlashSaleId,
        productTypes: productTypeIdsUnique,
        partnerIds: partnerIdsUnique,
      });
      console.log("Start get product types");
      await getProductTypes(productTypeIdsUnique);
      console.log("End get product types");
      const productsSelected: Product[] = data.flashSaleProductDetails.map(
        (item: any) => {
          cachedFlashIds.current[item.productId] = item.id;
          return {
            id: item.productId,
            name: item.productInfo.name,
            price: item.normalPrice,
            image: item.productInfo.images[0],
            quantitySale: item.quantity,
            salePrice: item.salePrice,
            flashSaleProductId: item.id,
          };
        }
      );
      console.log("productsSelected", productsSelected);
      setSelectedProducts(productsSelected);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setInit(true);
  };

  const handleProductSelection = (product: Product, checked: boolean) => {
    if (checked) {
      setSelectedProducts((prev) => [
        ...prev,
        { ...product, quantitySale: 1, salePrice: product.price },
      ]);
    } else {
      setSelectedProducts((prev) => prev.filter((p) => p.id !== product.id));
    }
    console.log("selectedProducts", selectedProducts);
  };

  const handleProductUpdate = (id: string, field: string, value: any) => {
    setSelectedProducts((prev) =>
      prev.map((p) => (p.id === id ? { ...p, [field]: value } : p))
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (isUpdate) {
        await handleUpdateFlashSale();
      } else {
        await handleCreateFlashSale();
      }
    } catch (error: any) {
      console.log(error.response);
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      }
      if (error.response?.data?.errors) {
        message.error(error.response.data.errors);
      }
    }
    setLoading(false);
  };

  const handleCreateFlashSale = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const data: InputCreateFlashSale = {
      name: values.flashSaleName,
      description: values.description,
      dayFlashSale: moment(values.dayFlashSale).format("YYYY-MM-DD"),
      timeFrameId: values.timeFrameId,
      isActive: values.isActive ?? false,
      products: selectedProducts.map((product, index) => {
        const productTypeId: any = selectedProductTypes.find((type) =>
          type.products.some((p) => p.id === product.id)
        )?.id;
        return {
          productId: parseInt(product.id),
          productTypeId: productTypeId || 0,
          normalPrice: product.price,
          salePrice: product.salePrice || product.price,
          quantity: product.quantitySale || 1,
          sequence: index + 1,
        };
      }),
    };
    const result = await axios({
      method: "post",
      url: "/admin/product/flashsale/create",
      headers: {
        Authorization: "Bearer " + token,
      },
      data,
    });
    if (result?.data?.code === 0) {
      message.success(result?.data?.message || "Tạo mới thành công");
      props.onClose();
    } else {
      message.error(result?.data?.message || "Đã có lỗi xảy ra");
    }
  };

  const handleUpdateFlashSale = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const data: InputUpdateFlashSale = {
      flashSaleProductId: parseInt(params.id),
      name: values.flashSaleName,
      description: values.description,
      isActive: values.isActive ?? false,
      flashSaleProductDetais: selectedProducts.map((product, index) => {
        const productTypeId: any = selectedProductTypes.find((type) =>
          type.products.some((p) => p.id === product.id)
        )?.id;
        return {
          flashSaleProductDetailId:
            cachedFlashIds.current[product.id] || moment().valueOf(),
          productId: parseInt(product.id),
          productTypeId: productTypeId || 0,
          normalPrice: product.price,
          salePrice: product.salePrice || product.price,
          quantity: product.quantitySale || 1,
          sequence: index + 1,
        };
      }),
    };
    const result = await axios({
      method: "post",
      url: "/admin/product/flashsale/update",
      headers: {
        Authorization: "Bearer " + token,
      },
      data,
    });
    if (result?.data?.code === 0) {
      message.success(result?.data?.message || "Cập nhật thành công");
      props.onClose();
    } else {
      message.error(result?.data?.message || "Đã có lỗi xảy ra");
    }
  };

  const renderFlashSaleForm = () => (
    <>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          name="flashSaleName"
          label="Tên FlashSale"
          required
          rules={[{ required: true, message: "Vui lòng nhập tên FlashSale" }]}
          style={{ width: "50%" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="isActive"
          label="Trạng thái"
          style={{ marginBottom: 24, marginLeft: "auto", width: "50%" }}
        >
          <Switch defaultChecked={form.getFieldValue("isActive")} />
        </Form.Item>
      </div>
      <Form.Item name="description" label="Miêu tả">
        <TextArea rows={4} />
      </Form.Item>
      <div style={{ display: "flex", gap: "16px" }}>
        <Form.Item
          name="dayFlashSale"
          label="Thiết lập thời gian"
          required
          rules={[{ required: true, message: "Vui lòng chọn ngày" }]}
          style={{ flex: 1 }}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Chọn ngày"
            picker="date"
            disabled={isUpdate}
          />
        </Form.Item>
        <Form.Item
          name="timeFrameId"
          label="Chọn khung giờ"
          rules={[{ required: true, message: "Chọn khung giờ" }]}
          required
          style={{ flex: 1 }}
        >
          <CustomSelect isUpdate={isUpdate} />
        </Form.Item>
      </div>
    </>
  );

  const renderProductTypeSelection = () => (
    <div style={{ display: "flex", gap: "16px" }}>
      <Form.Item
        name="storeIds"
        label="Chọn cửa hàng"
        required={false}
        style={{ width: "50%" }}
      >
        <CustomerModelStore onChangeStore={onChangeStore} />
      </Form.Item>
      <Form.Item
        name="productTypes"
        label="Chọn loại sản phẩm"
        required
        rules={[
          {
            required: true,
            message: "Vui lòng chọn ít nhất một loại sản phẩm",
          },
        ]}
      >
        <CustomModelProductType
          getProductTypes={getProductTypes}
          storeIds={storeIds}
        />
      </Form.Item>
    </div>
  );

  const onChangeStore = async (ids: any[]) => {
    setStoreIds(ids);
  };

  const getProductTypes = async (ids: any[]) => {
    try {
      console.log("pageInfo", pageInfo);
      if (!ids.length) return setSelectedProductTypes([]);
      const rs: any = await helper.callPageApi(pageInfo, "get-product", {
        queryInput: JSON.stringify({
          id: ids,
          storeId:
            storeIds && Array.isArray(storeIds) && storeIds.length
              ? storeIds
              : undefined,
        }),
        limit: 2000,
      });
      if (rs.status !== 200) throw rs;
      const newProductTypes: ProductType[] = rs.data.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          image: item.images[0],
          products: item.products.map((product: any) => {
            return {
              id: product.id,
              name: product.name,
              price: product.paymentCash,
              image: product.images[0],
            };
          }),
        };
      });
      console.log("newProductTypes", newProductTypes);
      setSelectedProductTypes(newProductTypes);
    } catch (error) {
      console.log("error get product types info: ", error);
    }
  };

  const renderProductSelectionTable = () => {
    const expandedRowRender = (record: ProductType) => {
      const columns = [
        { title: "ID", dataIndex: "id", key: "id" },
        { title: "Tên sản phẩm", dataIndex: "name", key: "name" },
        {
          title: "Ảnh",
          dataIndex: "image",
          key: "image",
          render: (text: string) => (
            <img src={text} alt="Product" className={styles.productImage} />
          ),
        },
        {
          title: "Giá",
          dataIndex: "price",
          key: "price",
          render: (_: any, record: Product) => formatPriceToVND(record.price),
        },
        {
          title: "Tham gia FlashSale",
          key: "status",
          render: (_: any, product: Product) => (
            <Switch
              checked={selectedProducts.some((p) => p.id === product.id)}
              onChange={(checked) => handleProductSelection(product, checked)}
            />
          ),
        },
      ];

      return (
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={record.products}
          pagination={false}
          rowSelection={{ ...rowSelection }}
        />
      );
    };

    const rowSelection: TableProps<Product>["rowSelection"] = {
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          setSelectedProducts((prev) => [
            ...prev,
            { ...record, quantitySale: 1, salePrice: record.price },
          ]);
        } else {
          setSelectedProducts((prev) => prev.filter((p) => p.id !== record.id));
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        if (selected) {
          setSelectedProducts((prev) => [
            ...prev,
            ...changeRows.map((product) => ({
              ...product,
              quantitySale: 1,
              salePrice: product.price,
            })),
          ]);
        } else {
          setSelectedProducts((prev) =>
            prev.filter((p) => !changeRows.some((c) => c.id === p.id))
          );
        }
        console.log("selectedProducts", selectedProducts);
      },
    };

    return (
      <Form.Item
        name="products"
        label="Chọn sản phẩm"
        required
        rules={[{ required: true, message: "Vui lòng chọn sản phẩm" }]}
      >
        <Table
          rowKey={"id"}
          dataSource={selectedProductTypes}
          columns={[
            { title: "Id", dataIndex: "id" },
            { title: "Loại sản phẩm", dataIndex: "name" },
          ]}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: false,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
        />
      </Form.Item>
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setSelectedProducts((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const RowTableSelectedProduct: React.FC<RowProps> = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props["data-row-key"] });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
      () => ({ setActivatorNodeRef, listeners }),
      [setActivatorNodeRef, listeners]
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const renderSelectedProductsTable = () => (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={selectedProducts.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <div style={{ flex: 1 }}>
          <h3>Danh sách sản phẩm đã chọn</h3>
          <div style={{ maxHeight: "600px", overflow: "auto" }}>
            <Table
              rowKey={"id"}
              dataSource={selectedProducts}
              components={{ body: { row: RowTableSelectedProduct } }}
              columns={[
                {
                  key: "sort",
                  align: "center",
                  width: 40,
                  render: () => <DragHandle />,
                },
                { title: "ID", dataIndex: "id", key: "id" },
                { title: "Tên", dataIndex: "name", key: "name" },
                {
                  title: "Ảnh",
                  dataIndex: "image",
                  key: "image",
                  render: (text: string) => (
                    <img
                      src={text}
                      alt="Product"
                      className={styles.productImage}
                    />
                  ),
                },
                {
                  title: "Giá gốc",
                  dataIndex: "price",
                  key: "price",
                  render: (_, record) => formatPriceToVND(record.price),
                },
                {
                  title: "Giá bán",
                  dataIndex: "salePrice",
                  key: "salePrice",
                  render: (_, record) => (
                    <Form.Item
                      name={["products", record.id, "salePrice"]}
                      rules={[
                        { required: true, message: "Vui lòng nhập giá bán" },
                        {
                          type: "number",
                          max: record.price,
                          message: "Giá bán không thể cao hơn giá gốc",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={record.salePrice || record.price}
                    >
                      <InputNumber
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value);
                          handleProductUpdate(record.id, "salePrice", value);
                        }}
                        max={record.price}
                      />
                    </Form.Item>
                  ),
                },
                {
                  title: "Số lượng",
                  dataIndex: "quantitySale",
                  key: "quantitySale",
                  render: (_, record) => (
                    <Form.Item
                      name={["products", record.id, "quantitySale"]}
                      rules={[
                        { required: true, message: "Vui lòng nhập số lượng" },
                        {
                          type: "number",
                          min: 1,
                          message: "Số lượng phải lớn hơn 0",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                      initialValue={record.quantitySale || 1}
                    >
                      <InputNumber
                        onBlur={(e) => {
                          const value = parseInt(e.target.value);
                          handleProductUpdate(
                            record.id,
                            "quantitySale",
                            isNaN(value) ? 1 : Math.max(1, value)
                          );
                        }}
                        min={1}
                      />
                    </Form.Item>
                  ),
                },
              ]}
              pagination={false}
              scroll={{ y: 450 }}
            />
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );

  if (!init || !isPageInfoLoaded) return <Loading />;

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "rgba(0,0,0,.25)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      )}
      <div className={styles.flashSaleProduct}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {renderFlashSaleForm()}
          {renderProductTypeSelection()}
          <div style={{ display: "flex", gap: "24px", alignItems: "stretch" }}>
            <div style={{ width: "40%" }}>{renderProductSelectionTable()}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 8px",
                width: "5%",
              }}
            >
              <RightOutlined style={{ fontSize: "24px" }} />
            </div>
            <div style={{ width: "55%" }}>{renderSelectedProductsTable()}</div>
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.saveButton}
            >
              {isUpdate ? "Cập nhật" : "Tạo mới"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

const CustomSelect = ({ value, onChange, isUpdate }: any) => {
  return (
    <SingleSelect
      schema={{
        pageId: PAGE_ID,
        api: "get-times",
        modelSelectMultiple: true,
      }}
      placeholder="Chọn khung thời gian"
      onChange={(value) => {
        onChange(value);
      }}
      value={value}
      disabled={isUpdate}
    />
  );
};

const CustomModelProductType = ({
  value,
  onChange,
  getProductTypes,
  storeIds,
}: any) => {
  console.log("storeIds", storeIds);
  return (
    <ArrayModel
      schema={{
        name: "Danh sách sản phẩm",
        field: "productIds",
        widget: "ArrayModel",
        type: "number",
        api: "get-product",
        modelSelectField:
          "id$$ID,name$$Tên sản phẩm,images$$Ảnh,partnerName$$Tên đối tác",
        pageId: PAGE_ID,
        embed: [
          {
            key: "storeId",
            value:
              storeIds && Array.isArray(storeIds) && storeIds.length
                ? storeIds
                : undefined,
          },
        ],
      }}
      value={value}
      onChange={(ids) => {
        onChange(ids);
        getProductTypes(ids);
      }}
    />
  );
};

const CustomerModelStore = ({ value, onChange, onChangeStore }: any) => {
  return (
    <ArrayModel
      schema={{
        name: "Danh sách cửa hàng",
        field: "storeIds",
        widget: "ArrayModel",
        type: "number",
        api: "get-store",
        modelSelectField: "id$$ID,name$$Tên cửa hàng,logo$$Ảnh",
        pageId: PAGE_ID,
      }}
      value={value}
      onChange={(ids) => {
        onChange(ids);
        onChangeStore(ids);
      }}
    />
  );
};
export default FlashSaleProductTypeFormCtrl;

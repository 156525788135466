import { Avatar } from 'antd'
import { IMessage } from '@src/types/chat'

interface UserCellProps {
  chat: {
    id: number;
    name: string;
    thumb?: string;
    avatar?: string;
    status: string;
    lastMessage?: IMessage;
    lastMessageTime?: string;
    unreadMessage?: number;
  };
  selectedSectionId: number;
  onSelectUser: (chat: any) => void;
}

const UserCell = ({ chat, selectedSectionId, onSelectUser }: UserCellProps) => {
  return (
    <div
      className={`gx-chat-user-item ${
        selectedSectionId === chat.id ? 'active' : ''
      }`}
      onClick={() => {
        onSelectUser(chat)
      }}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Avatar 
              src={chat.avatar || chat.thumb} 
              className="gx-size-40" 
              alt={chat.name}
              onError={(e) => {
                e.currentTarget.src = '/assets/images/placeholder.png';
              }}
            />
            <span className={`gx-status gx-small gx-${chat.status}`} />
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h4">{chat.name}</span>
          <div className="gx-chat-info-des gx-text-truncate">
            {chat.lastMessage?.content 
              ? chat.lastMessage.content.substring(0, 25) + '...'
              : ''}
          </div>
          <div className="gx-last-message-time">{chat.lastMessageTime}</div>
        </div>

        {chat.unreadMessage > 0 ? (
          <div className="gx-chat-date">
            <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">
              {chat.unreadMessage}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default UserCell

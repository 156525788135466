import { io as socketIOClient } from 'socket.io-client'
import { IS_DEBUG } from '@src/constants/constants'
import { SOCKET_EVENTS } from '@src/constants/socket'

type SocketProps = {
  ENDPOINT?: string
  socket?: any
  newUserCallback?: any
  receiveMessageCallback?: any
  roomId?: any
  token: string
}

const generateUrl = () => {
  return process.env.REACT_APP_URL || 'http://localhost:7879'
}

export default class Socket {
  _socket: any

  constructor(opts: SocketProps) {
    const { token } = opts

    this._socket = socketIOClient(process.env.REACT_APP_URL
      ? process.env.REACT_APP_URL
      : window.location.origin || 'https://muamua-product-api.mediaone.dev', {
      auth: {
        token: token
      },
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    })

    this.init(opts)
  }

  async init(opts: SocketProps) {
    this.handleSocket(opts)
  }

  async handleSocket({ socket, ...rest }: SocketProps) {
    this._socket.on('connect', () => {
      console.log('Socket connected:', this._socket.id)
    })

    this._socket.on('disconnect', () => {
      console.log('Socket disconnected')
    })

    this._socket.on('connect_error', (error: any) => {
      console.error('Socket connection error:', error)
    })

    this._socket.on(SOCKET_EVENTS.NEW_MESSAGE, (msg: any) => {
      console.log('New message received:', msg)
      rest.receiveMessageCallback?.(msg)
    })
  }

  getSocketAdapter() {
    return this._socket
  }
}

let socket: any

export const connect = (roomId: any) => {
  socket = socketIOClient(generateUrl(), {
    query: {
      roomId,
    },
    forceNew: true,
  })
  return socket
}

export const getSocket = () => socket

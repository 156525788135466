import styled from 'styled-components';
import { Form, Table, Tabs } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const StyledForm = styled(Form)`
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const FormActions = styled.div<{isAtBottom: boolean}>`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  background: white;
  border-top: 1px solid #f0f0f0;
  position: ${(props) => (props.isAtBottom ? "relative" : "fixed")};
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.06);
  max-width: 100%;
  margin: 0 auto;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const StyledTable = styled(Table)`
  .ant-table-container {
    border: 1px solid #f0f0f0;
  }

  .ant-table-cell {
    border-right: 1px solid #f0f0f0 !important;
  }

  .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 500;
  }
`;

export const StyledTabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  width: 100%;
`;

export const FormContainer = styled.div`
  margin-top: 16px;
  padding: 0 24px 80px 24px;
  flex: 1;
`;

export const SectionContainer = styled.div`
  margin-bottom: 32px;
  scroll-margin-top: 64px;
`;

export const FormSection = styled.div`
  padding: 24px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
  margin: 0 auto;
  max-width: 1200px;

  h4.ant-typography {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
  }

  .ant-form-item-explain-error {
    color: #ff4d4f;
  }

  .ant-input-group {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ant-input-suffix {
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 24px;
  margin-bottom: 0;

  .ant-tabs-nav {
    margin-bottom: 0;
    background: white;
  }

  .ant-tabs-tab {
    padding: 12px 16px;

    &.ant-tabs-tab-active {
      font-weight: 500;
    }
  }

  .ant-tabs-ink-bar {
    height: 3px;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DragHandle = styled(MenuOutlined)`
  cursor: move;
  color: #999;
  padding: 4px 8px;
  font-size: 16px;
`;
import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Select, DatePicker, Form, Button, message, Statistic } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { helper } from '@src/controls/controlHelper';
import { PlayCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface VideoReportData {
  date: string;
  totalWatches: number;
  completedWatches: number;
  averageWatchPercent: number;
  completionRate: number;
}

interface OverallStats {
  totalWatches: number;
  totalCompleted: number;
  averageWatchPercent: number;
  completionRate: number;
}

interface ApiResponse {
  success: boolean;
  data: VideoReportData[];
}

const PAGE_ID = 296;
const VideoReport: React.FC = () => {
  const [form] = Form.useForm();
  const [reportData, setReportData] = useState<VideoReportData[]>([]);
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [overallStats, setOverallStats] = useState<OverallStats | null>(null);
  const [pageInfo, setPageInfo] = useState<any>();

  useEffect(() => {
    getPageInfo();
  }, []);

  const getPageInfo = async () => {
    const _pageInfo = await helper.getPage(PAGE_ID);
    setPageInfo(_pageInfo);
  };

  const calculateOverallStats = (data: VideoReportData[]) => {
    const avgWatchPercent = data.reduce((sum, item) => sum + item.averageWatchPercent, 0) / data.length;
    const avgCompletionRate = data.reduce((sum, item) => sum + item.completionRate, 0) / data.length;

    return {
      totalWatches: data.reduce((sum, item) => sum + item.totalWatches, 0),
      totalCompleted: data.reduce((sum, item) => sum + item.completedWatches, 0),
      averageWatchPercent: Number(avgWatchPercent.toFixed(2)),
      completionRate: Number(avgCompletionRate.toFixed(2))
    };
  };

  const fetchData = useCallback(async (values: any) => {
    if (!pageInfo) return;
    
    setLoading(true);
    try {
      const { timeFrame, dateRange } = values;
      const params: any = {
        type: timeFrame,
      };

      if (timeFrame === 'custom' && dateRange) {
        params.startDate = moment(dateRange[0]).format('YYYY-MM-DD');
        params.endDate = moment(dateRange[1]).format('YYYY-MM-DD');
      }

      const response = await helper.callPageApi(pageInfo, 'video-report', params);

      if (response && response.data && 'success' in response.data) {
        const apiResponse = response.data as ApiResponse;
        if (apiResponse.success) {
          const processedData = apiResponse.data.map((item: VideoReportData) => ({
            ...item,
            date: item.date,
          }));

          setReportData(processedData);
          setOverallStats(calculateOverallStats(processedData));
        } else {
          message.error('Không thể tải dữ liệu báo cáo');
        }
      } else {
        message.error('Không thể tải dữ liệu báo cáo');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Có lỗi xảy ra khi tải dữ liệu');
    } finally {
      setLoading(false);
      setInit(true);
    }
  }, [pageInfo]);

  useEffect(() => {
    form.setFieldsValue({ timeFrame: '7days' });
    fetchData({ timeFrame: '7days' });
  }, [form, fetchData]);

  const onFinish = (values: any) => {
    fetchData(values);
  };

  if (!init || loading) return <Loading />;

  return (
    <div>
      <Card 
        title="Thống kê lượng xem Video" 
        extra={
          <Form form={form} onFinish={onFinish} layout="inline">
            <Form.Item name="timeFrame" initialValue="7days">
              <Select style={{ width: 200 }}>
                <Option value="7days">7 ngày gần nhất</Option>
                <Option value="this_month">Tháng này</Option>
                <Option value="last_month">Tháng trước</Option>
                <Option value="this_year">Năm nay</Option>
                <Option value="custom">Tùy chỉnh</Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => 
              prevValues.timeFrame !== currentValues.timeFrame
            }>
              {({ getFieldValue }) => {
                return getFieldValue('timeFrame') === 'custom' ? (
                  <Form.Item name="dateRange">
                    <RangePicker />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Áp dụng
              </Button>
            </Form.Item>
          </Form>
        }
      >
        {reportData.length > 0 ? (
          <>
            <Row gutter={16} style={{ marginBottom: 16 }}>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Tổng lượt xem"
                    value={overallStats?.totalWatches}
                    prefix={<PlayCircleOutlined style={{ color: '#3f8600' }} />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Tổng lượt xem hoàn thành"
                    value={overallStats?.totalCompleted}
                    prefix={<CheckCircleOutlined style={{ color: '#cf1322' }} />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Tỷ lệ xem trung bình"
                    value={overallStats?.averageWatchPercent}
                    suffix="%"
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Tỷ lệ hoàn thành"
                    value={overallStats?.completionRate}
                    suffix="%"
                  />
                </Card>
              </Col>
            </Row>

            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={reportData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar 
                  yAxisId="left" 
                  dataKey="totalWatches" 
                  fill="#8884d8" 
                  name="Tổng lượt xem" 
                />
                <Bar 
                  yAxisId="left" 
                  dataKey="completedWatches" 
                  fill="#82ca9d" 
                  name="Lượt xem hoàn thành" 
                />
                <Bar 
                  yAxisId="right" 
                  dataKey="averageWatchPercent" 
                  fill="#ffc658" 
                  name="Tỷ lệ xem trung bình (%)" 
                />
                <Bar 
                  yAxisId="right" 
                  dataKey="completionRate" 
                  fill="#ff8042" 
                  name="Tỷ lệ hoàn thành (%)" 
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            Không có dữ liệu để hiển thị
          </div>
        )}
      </Card>
    </div>
  );
};

export default VideoReport; 
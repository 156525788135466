import React, { Component } from "react";
import { Modal, Button, Tag, Badge, Image } from "antd";
import { CloseOutlined, PlusOutlined, EyeOutlined } from "@ant-design/icons";
import get from "lodash/get";
import clone from "lodash/clone";
import join from "lodash/join";
import isEqual from "lodash/isEqual";
import includes from "lodash/includes";
import ProTable from "@src/packages/pro-table/Table";
import { helper } from "@src/controls/controlHelper";
import { ActionType, RequestData } from "@src/packages/pro-table";
import { COLORS } from "@src/constants/constants";
import { random } from "@src/util/helpers";
import _ from "lodash";
import { DATA_TYPE } from "@src/constants/enums";

export interface ArraySortProps {
  schema: any;
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange?: (val: any) => void;
  dataForm?: any;
  clickedRecordsCount?: number | 3;
  isClicked?: boolean;
}

export interface ArraySortState {
  names: Array<any>;
  output: any;
  outputShadow: [];
  value?: any;
  modal: boolean;
  data: Array<any>;
  loading: boolean;
  search: string;
  pageId: string;
  embeds: any[];
  schema: any;
  count: number;
  columns: Array<any>;
  nPage: number;
  display: any;
  mode: "select" | "view";
  pageInfo: any;
  pagination: {
    pageSize: number;
    total: number;
    totalPages: number;
    current: number;
  };
  clickedRecordsCount: number;
  isClicked?: boolean;
}

class ArraySort extends Component<ArraySortProps, ArraySortState> {
  form = React.createRef<any>();
  actionRef = React.createRef<ActionType | undefined>();
  constructor(props: any) {
    super(props);
    this.state = {
      modal: false,
      data: [],
      names: [],
      loading: true,
      search: "",
      pageId: props.schema.pageId,
      schema: props.schema,
      embeds: props.embeds,
      count: 0,
      columns: this.calculateColumns(props.schema),
      output: clone(props.value || []),
      outputShadow: clone(props.value || []),
      nPage: 0,
      display: null,
      mode: "select",
      pageInfo: null,
      pagination: {
        pageSize: this.itemsPerPage,
        total: 0,
        totalPages: 0,
        current: 1,
      },
      clickedRecordsCount: 0,
    };
    this.init(props.schema.pageId, props.schema, clone(props.value || []));
  }
  itemsPerPage = 10;
  pageInfo: any = null;

  static getDerivedStateFromProps(
    nextProps: ArraySortProps,
    prevState: ArraySortState
  ) {
    if (nextProps.value) {
      if (!isEqual(nextProps.value, prevState.output)) {
        return {
          output: nextProps.value,
        };
      } else return null;
    } else return null;
  }

  componentDidMount() {
    this.setState({
      outputShadow: this.props.value || [],
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.output !== this.state.output ||
      this.state.pageInfo !== prevState.pageInfo
    ) {
      this.fetchItemName(
        this.state.pageInfo,
        this.state.schema,
        this.state.output
      );
    }
    const embed = this.props.schema?.embed || [];
    for (let item of embed) {
      if (
        item.value &&
        typeof item.value === "string" &&
        item.value.substr(0, 2) === "--"
      ) {
        const k = item.value.substr(2);
        if (
          !["true", "false"].includes(k) &&
          prevProps.dataForm[k] !== this.props.dataForm[k]
        ) {
          this.start();
          this.actionRef?.current?.reload?.();
        }
      }
    }
  }

  async init(pageId: number, schema: any, output: any) {
    const _pageInfo = await helper.getPage(pageId);
    this.pageInfo = _pageInfo;
    this.setState(
      {
        pageInfo: _pageInfo,
      },
      () => {
        this.fetchItemName(_pageInfo, schema, output);
      }
    );
  }

  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      const output: any = [];
      this.setState({
        output,
        names: [],
        loading: false,
      });
      this.onChange(output);
      this.fetchItemName(this.pageInfo, this.state.schema, output);
    }, 1000);
  };

  toggle = (e: any, mode?: "select" | "view") => {
    if (mode) {
      this.setState({
        mode,
        modal: !this.state.modal,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };

  fetchData = async (
    params: any,
    sorter: {
      [key: string]: "ascend" | "descend";
    },
    filtered: { [key: string]: React.ReactText[] }
  ) => {
    try {
      let filter: Record<string, any> = {};
      let sort: Array<any> = [];
      const _filtered = Object.keys(filtered).reduce((obj, key) => {
        const newObj: any = { ...obj };
        if (filtered[key] !== null)
          newObj[key] = helper.getValue(filtered[key]);
        return newObj;
      }, {});
      const _params = _.omit(params, [
        "current",
        "pageSize",
        "showSizeChanger",
        "total",
        "totalPages",
        "position",
        "_timestamp",
      ]);
      filter = { ..._filtered, ..._params };
      if (sorter) {
        sort = Object.keys(sorter).map((key) => {
          return { [key]: sorter[key] === "descend" ? "desc" : "asc" };
        });
      }
      if (sort.length === 0) sort = [{ id: "desc" }];
      if (this.state.mode === "view") {
        if (filter.id) {
          if (includes(this.state.output, filter.id)) {
            filter.id = 0;
          }
        } else {
          filter.id = this.state.output;
        }
      }
      filter = this.calculateFilter(filter);
      if (this.props.schema?.embed && this.props.schema.embed.length) {
        this.props.schema.embed.forEach((_embed: any) => {
          if (_embed.value === undefined) return;
          if (
            typeof _embed.value == "string" &&
            _embed.value.substr(0, 2) === "--"
          ) {
            const k = _embed.value.substr(2);
            if (["true", "false"].includes(k)) {
              filter[_embed.key] = k === "true";
            } else {
              const _embedValue = this.props.dataForm[k];
              if (_embedValue) filter[_embed.key] = _embedValue;
            }
          } else {
            filter[_embed.key] = _embed.value;
          }
        });
      }
      const nameFieldSelectObj = helper.transformModelSelectField(
        this.state.schema.modelSelectField
      );
      const rs: any = await helper.callPageApi(
        this.pageInfo,
        this.state.schema?.api,
        {
          select: Object.keys(nameFieldSelectObj).join(",").toString(),
          sort,
          queryInput: JSON.stringify(filter),
          limit: params.pageSize,
          skip: params.pageSize * (params.current - 1),
        }
      );
      const data = this.calculateCheck(rs?.data?.data ?? [], this.state.output);
      // data.sort((a: any, b: any) => {
      //   if (a.checked === b.checked) return 0;
      //   return a.checked ? -1 : 1;
      // });
      this.setState({
        data,
        count: rs?.data.count,
        loading: false,
        nPage: Math.ceil(rs?.data.count / params.pageSize),
        pagination: {
          pageSize: params.pageSize,
          total: rs?.data.count,
          totalPages: Math.floor(
            (get(rs, "data.count", 0) + params.pageSize - 1) / params.pageSize
          ),
          current: params.current,
        },
      });
      return {
        data,
        success: true,
        total: rs?.data.count,
      } as RequestData<any>;
    } catch (error) {
      console.log(`🚀 ~ file: ArraySort.tsx ~ line 173 ~ error`, error);
      return {
        data: [],
        success: true,
        total: 0,
      } as RequestData<any>;
    }
  };

  transformFied() {
    const selectField: string = this.props.schema.modelSelectField;
    if (!selectField) return "id,name";
    return selectField
      .split(",")
      .map((item) => {
        const [key] = item.split("$$");
        return key;
      })
      .join(",");
  }

  fetchItemName = async (pageInfo: any, schema: any, output: any) => {
    if (!pageInfo || !schema || !output || !output.length) return;
    const filter: Record<string, any> = {};
    filter.id = output;
    try {
      const rs: any = await helper.callPageApi(pageInfo, schema.api, {
        queryInput: JSON.stringify(filter),
        select: this.transformFied(),
      });
      const display: Array<any> = [];
      rs?.data?.data.map((d: Record<string, any>) => {
        return display.push(d.name);
      });
      this.setState({ names: rs?.data?.data, display: join(display, "-") });
    } catch (err) {
      console.error(
        `🚀 ~ file: ArraySort.tsx ~ line 176 ~ fetchItemName ~ err`,
        err
      );
    }
  };

  calculateCheck(data: any, output: any) {
    data.map((d: any) => {
      if (includes(output, d.id)) return (d.checked = true);
      return (d.checked = false);
    });
    return data;
  }

  onCheckboxChanged = (keys: Array<any>, _rows: Array<any>) => {
    const arrs = [...new Set([...this.state.output, ...keys])];
    const output = [].concat(...arrs);

    this.setState({ output });
    this.onChange(output);
    this.fetchItemName(this.pageInfo, this.state.schema, output);
  };

  calculateColumns(schema: any) {
    const nameFieldSelectObj = helper.transformModelSelectField(
      schema.modelSelectField
    );
    return Object.keys(nameFieldSelectObj).map((keyField): any => {
      const baseColumn = {
        title: nameFieldSelectObj[keyField],
        dataIndex: keyField,
      };

      // Handle special column types
      if (keyField === "id") {
        return {
          ...baseColumn,
          sorter: true,
          valueType: DATA_TYPE.NUMBER,
        };
      }

      if (["logo", "image", "images"].includes(keyField)) {
        return {
          ...baseColumn,
          sorter: false,
          valueType: DATA_TYPE.STRING,
          render: (value: any) => {
            const valueArray = Array.isArray(value) ? value : [value];
            return (
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #d9d9d9",
                  borderRadius: "2px",
                  overflow: "hidden",
                }}
              >
                <Image
                  src={valueArray[0]}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  preview={{
                    src: valueArray[0],
                    mask: <EyeOutlined style={{ fontSize: "16px" }} />,
                  }}
                />
              </div>
            );
          },
        };
      }

      // Default column configuration
      return {
        ...baseColumn,
        sorter: true,
        valueType: DATA_TYPE.STRING,
      };
    });
  }

  calculateFilter(filter: { [key: string]: React.ReactText[] }) {
    const obj: any = {};
    Object.keys(filter).map((f: any) => {
      const value: any = filter[f];
      for (let i = 0; i < (this.state.columns || []).length; i++) {
        const gridInfo = (this.state.columns || [])[i];
        if (gridInfo.dataIndex === f) {
          switch (gridInfo.valueType) {
            case DATA_TYPE.STRING: {
              obj[f] = { contains: value };
              break;
            }
            case DATA_TYPE.NUMBER:
            default: {
              obj[f] = value;
              break;
            }
          }
        }
      }
      return 0;
    });
    return obj;
  }

  confirm() {
    this.onChange(this.state.output);
    this.fetchItemName(this.pageInfo, this.state.schema, this.state.output);
    this.toggle(null);
  }

  onChange(dt: any) {
    if (this.props.onChange) {
      this.props.onChange(dt);
    }
  }

  onRemoveClick(id: number) {
    const names = this.state.names.filter((i) => i.id !== id);
    const output = names.map((i) => i.id);
    this.setState({ output, names });
    this.onChange(output);
  }

  renderNames() {
    if (this.state.output.length === 0) return null;
    if (this.state.output.length <= 4) {
      // Calculate total length of all names
      const TOTAL_MAX_NAME_LENGTH = 100;
      const totalLength = this.state.names.reduce(
        (acc, item) => acc + (item?.name?.length || 0),
        0
      );
      // Return null if total length exceeds 100 characters
      if (totalLength > TOTAL_MAX_NAME_LENGTH) return null;
      return (
        <React.Fragment>
          {this.state.names.map((item) => {
            return item ? (
              <Tag
                key={item.id}
                closable
                color={COLORS[random(11)]}
                onClose={() => {
                  this.onRemoveClick(item.id);
                }}
              >
                {item.name}
              </Tag>
            ) : null;
          })}
        </React.Fragment>
      );
    }
    return null;
  }

  renderButtonSelect = () => {
    return null;
  };

  render() {
    const { loading, output } = this.state;
    const { disabled, isClicked, clickedRecordsCount = 0 } = this.props;

    // Row selection configuration for ProTable
    const rowSelection = {
      selectedRowKeys: output ?? [],
      getCheckboxProps: (record: any) => ({
        disabled: this.isRecordSelectionDisabled(
          record,
          output,
          isClicked,
          clickedRecordsCount
        ),
      }),
      onSelect: this.handleRowSelect,
      onSelectAll: this.handleSelectAll,
    };

    return (
      <div className="gx-array-model">
        {/* Selection display area */}
        <div className="gx-array-model-display">
          {this.renderNames()}
          <Badge count={output.length || 0}>
            <Tag
              className="selection-trigger-tag"
              style={{
                background: "#fff",
                borderStyle: "dashed",
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              onClick={() => !disabled && this.toggle("select")}
            >
              <PlusOutlined />
              {` Chọn...`}
            </Tag>
          </Badge>
        </div>

        {/* Selection modal */}
        <Modal
          width="70vw"
          visible={this.state.modal}
          title="Chọn"
          onCancel={this.toggle}
          footer={this.renderModalFooter()}
        >
          <div>
            {/* Selection summary and clear button */}
            <div className="selection-summary" style={{ marginBottom: 16 }}>
              <Button
                type="dashed"
                size="small"
                danger
                loading={loading}
                onClick={this.start}
                disabled={!output.length}
              >
                Xóa Chọn
              </Button>
              <span style={{ marginLeft: 8 }}>
                {`${output.length} bản ghi được chọn`}
              </span>
            </div>

            {/* Selection table */}
            <ProTable
              actionRef={this.actionRef as any}
              formRef={this.form}
              tableClassName="gx-table-responsive"
              request={this.fetchData}
              search={true}
              headerTitle="Danh sách đơn vị"
              rowKey="id"
              toolBarRender={false}
              tableAlertRender={false}
              pagination={this.state.pagination}
              columns={this.state.columns}
              loading={loading}
              rowSelection={rowSelection}
              dateFormatter="string"
              type="table"
            />
          </div>
        </Modal>
      </div>
    );
  }

  // Helper methods
  private isRecordSelectionDisabled(
    record: any,
    output: any[],
    isClicked?: boolean,
    clickedRecordsCount: number = 0
  ): boolean {
    return (
      !output.includes(record.id ?? "") &&
      (isClicked ?? false) &&
      output.length >= clickedRecordsCount
    );
  }

  private handleRowSelect = (record: any, selected: boolean) => {
    const newOutput = selected
      ? [...this.state.output, record.id]
      : this.state.output.filter((i: any) => i !== record.id);

    this.setState((prevState) => ({
      output: _.uniq(newOutput),
      clickedRecordsCount: selected
        ? prevState.clickedRecordsCount + 1
        : prevState.clickedRecordsCount - 1,
    }));

    this.onChange(newOutput);
    this.fetchItemName(this.pageInfo, this.state.schema, newOutput);
  };

  private renderModalFooter() {
    return this.state.mode === "select" ? (
      this.renderButtonSelect()
    ) : (
      <Button
        type="default"
        icon={<CloseOutlined />}
        onClick={this.handleModalClose}
      >
        Đóng
      </Button>
    );
  }

  private handleModalClose = (e: React.MouseEvent) => {
    this.onChange(this.state.outputShadow);
    this.fetchItemName(
      this.pageInfo,
      this.state.schema,
      this.state.outputShadow
    );
    this.toggle(e);
  };

  private handleSelectAll = (
    selected: boolean,
    selectedRows: any[],
    changeRows: any[]
  ) => {
    let newOutput = [...this.state.output];
    if (selected) {
      if (this.props.isClicked) {
        const availableSlots =
          (this.props.clickedRecordsCount || 0) - newOutput.length;
        const newIds = changeRows.slice(0, availableSlots).map((row) => row.id);
        newOutput = _.uniq([...newOutput, ...newIds]);
      } else {
        newOutput = _.uniq([...newOutput, ...changeRows.map((row) => row.id)]);
      }
    } else {
      newOutput = newOutput.filter(
        (id) => !changeRows.map((row) => row.id).includes(id)
      );
    }

    this.setState({ output: newOutput });
    this.onChange(newOutput);
    this.fetchItemName(this.pageInfo, this.state.schema, newOutput);
  };
}

export default ArraySort;

import React, { useRef, useEffect, useState } from "react";
import { Avatar, Modal } from "antd";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import Socket from "@src/util/Socket";
import { ITypingStatus, SOCKET_EVENTS } from "@src/constants/socket";
import { IMessage, IConversation, SENDER_TYPE, MESSAGE_TYPE, MESSAGE_STATUS, ORDER_STATUS } from "@src/types/chat";
import local from "@src/util/local";
import ChatInput from '@src/components/chat/ChatInput';
import OrderModal from "./OrderModal";
import ProductModal from "./ProductModal";
import MessageList from '@src/components/chat/MessageList';
import * as request from "@src/util/request";
import axios from "axios";
import { fetchMessages, handleScroll, scrollToBottom, createMessage, createLocalMessage } from '@src/utils/chatHelpers';

const ChatWindow = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 0;
  right: ${(props) => (props.visible ? "320px" : "-320px")};
  width: 320px;
  height: 480px;
  background: white;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 998;
  transition: right 0.3s ease;
`;

const ChatHeader = styled.div`
  padding: 8px 12px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .username {
    font-weight: 500;
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #999;
  transition: all 0.3s;

  &:hover {
    color: #666;
  }
`;

const ChatContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f7f7f7;
`;

const TypingIndicator = styled.div`
  padding: 8px 12px;
  color: #666;
  font-size: 13px;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 8px;

  .word {
    display: inline-block;
    animation: jump 1.4s linear infinite;
  }

  .word:nth-child(2) { animation-delay: 0.2s; }
  .word:nth-child(3) { animation-delay: 0.4s; }
  .word:nth-child(4) { animation-delay: 0.6s; }
  .word:nth-child(5) { animation-delay: 0.8s; }

  @keyframes jump {
    0%, 80%, 100% { 
      transform: translateY(0);
    }
    40% {
      transform: translateY(-4px);
    }
  }
`;

interface ChatDialogProps {
  visible: boolean;
  onClose: () => void;
  userInfo?: {
    id: number;
    name: string;
    avatar: string;
  };
  conversation?: IConversation & {
    unreadCount?: number;
  };
  messages: IChatMessage[];
  onSendMessage: (content: string, type?: MESSAGE_TYPE, attachments?: string[]) => void;
  conversationId?: string;
}

interface IChatMessage {
  id: string;
  content: string;
  isMine: boolean;
  timestamp: Date;
  type: MESSAGE_TYPE;
  status?: MESSAGE_STATUS;
  attachments?: string[];
  senderId?: number;
  senderType?: SENDER_TYPE;
  recipientId?: number;
  orderInfo?: {
  orderId: number;
  image: string;
    price: number;
    orderStatus: ORDER_STATUS;
    orderItemInfos: Array<{
      images: string[];
      name: string;
      attributeValues: string;
      quantity: number;
      totalPrice: number;
    }>;
  };
  productInfo?: {
    productTypeId: number;
    name: string;
    image: string;
    price: number;
    originalPrice: number;
  };
}

const ChatDialog: React.FC<ChatDialogProps> = ({
  visible,
  onClose,
  userInfo,
  conversation,
  messages: propMessages,
  onSendMessage,
  conversationId,
}) => {
  const messageListRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [socketInstance, setSocketInstance] = useState<any>(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>();
  const [isTyping, setIsTyping] = useState(false);
  const [expandedMessages, setExpandedMessages] = useState<{[key: string]: boolean}>({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;
  const fetchRef = useRef(false);
  const [showProductModal, setShowProductModal] = useState(false);

  // Fetch tin nhắn khi conversation thay đổi
  const loadMessages = async (pageNumber: number) => {
    if (!conversation?._id || fetchRef.current) return;

    try {
      setLoading(true);
      fetchRef.current = true;
      const response = await axios.get('/admin/chat/get-list-message', {
        params: {
          conversationId: conversation._id,
          skip: (pageNumber - 1) * limit,
          limit,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.code === 0) {
        const { data, total: totalItems } = response.data;
        
        const formattedMessages = data.map((msg: any) => ({
          id: msg._id,
          content: msg.content || '',
          isMine: msg.senderType === 'SELLER',
          timestamp: new Date(Number(msg.timestamp)),
          type: msg.messageType,
          status: msg.status,
          attachments: msg.attachments,
          senderId: msg.senderId,
          senderType: msg.senderType,
          recipientId: msg.recipientId,
          orderInfo: msg.orderInfo,
          productInfo: msg.productInfo
        }));

        const reversedMessages = [...formattedMessages].reverse();
        
        if (pageNumber === 1) {
          setMessages(reversedMessages);
        } else {
          setMessages(prev => [...reversedMessages, ...prev]);
        }
        
        setHasMore(formattedMessages.length === limit);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
      fetchRef.current = false;
    }
  };

  useEffect(() => {
    if (visible && conversation?._id) {
      setPage(1);
      setMessages([]);
      loadMessages(1);
    }
    
    return () => {
      fetchRef.current = false;
    };
  }, [visible, conversation?._id]);

  // useEffect để scroll xuống cuối khi messages thay đổi
  useEffect(() => {
    if (messageListRef.current && messages.length > 0) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  // Xử lý scroll để load thêm tin nhắn
  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      const handleScrollEvent = () => handleScroll(messageListRef, loading, hasMore, page, setPage);
      messageList.addEventListener('scroll', handleScrollEvent);
      return () => messageList.removeEventListener('scroll', handleScrollEvent);
    }
  }, [page, loading, hasMore]);

  // Load thêm tin nhắn khi page thay đổi
  useEffect(() => {
    if (page > 1) {
      loadMessages(page);
    }
  }, [page]);

  // Xử lý gửi tin nhắn
  const handleSendMessage = (content: string, type: MESSAGE_TYPE = MESSAGE_TYPE.TEXT, attachments?: string[]) => {
    if ((content.trim() || attachments?.length) && conversation) {
      const userInfo = local.get('user_id');
      const senderId = userInfo?.id;

      if (socketInstance && conversation._id && senderId) {
        const messageData = createMessage(content.trim(), type, conversation._id, conversation.userId, attachments);
        console.log('Sending message:', messageData);
        socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

        const newMessage = createLocalMessage(
          content.trim(),
          type,
          senderId,
          conversation.userId,
          attachments
        );

        setMessages(prev => [...prev, newMessage]);
        scrollToBottom(messageListRef);
      }
    }
  };

  useEffect(() => {
    if (conversation?._id) {
      const token = localStorage.getItem('token_chat');
      const socket = new Socket({
        token: token || '',
        receiveMessageCallback: (msg: IMessage) => {
          if (msg.conversationId === conversation._id) {
            if (msg.senderType !== SENDER_TYPE.SELLER) {
              console.log('Received new message:', msg);
              
              const newMessage: IChatMessage = {
                id: msg._id?.toString() || Date.now().toString(),
                content: msg.content || '',
                isMine: false,
                timestamp: new Date(msg.timestamp),
                type: msg.messageType,
                status: msg.status,
                attachments: msg.attachments,
                senderId: msg.senderId,
                senderType: msg.senderType,
                recipientId: msg.recipientId,
                orderInfo: msg.orderInfo,
                productInfo: msg.productInfo
              };

              setMessages(prev => {
                const messageExists = prev.some(m => m.id === newMessage.id);
                if (!messageExists) {
                  return [...prev, newMessage];
                }
                return prev;
              });

              if (messageListRef.current) {
                setTimeout(() => {
                  messageListRef.current?.scrollTo({
                    top: messageListRef.current.scrollHeight,
                    behavior: 'smooth'
                  });
                }, 100);
              }
            }
          }
        }
      });

      const socketAdapter = socket.getSocketAdapter();
      setSocketInstance(socketAdapter);

      console.log('Joining room:', conversation._id);
      socketAdapter.emit(SOCKET_EVENTS.JOIN_ROOM, conversation._id);

      socketAdapter.on(SOCKET_EVENTS.ERROR, (error: any) => {
        console.error('Socket error:', error);
      });

      socketAdapter.on('connect', () => {
        console.log('Socket connected');
      });

      socketAdapter.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      socketAdapter.on(SOCKET_EVENTS.TYPING_START, (data: ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(true);
        }
      });

      socketAdapter.on(SOCKET_EVENTS.TYPING_END, (data: ITypingStatus) => {
        if (data.conversationId === conversation._id && data.userType !== SENDER_TYPE.SELLER) {
          setIsTyping(false);
        }
      });

      return () => {
        console.log('Leaving room:', conversation._id);
        socketAdapter.emit(SOCKET_EVENTS.LEAVE_ROOM, conversation._id);
        socketAdapter.disconnect();
        setSocketInstance(null);
      };
    }
  }, [conversation?._id]);

  const handleExpandMessage = (messageId: string) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [messageId]: !prev[messageId]
    }));
  };

  const handleImageClick = (imageUrl: string) => {
    setPreviewImage(imageUrl);
    setPreviewVisible(true);
  };

  const handleImageUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("images", file);
      const rs = await request.upload("/api/file/upload-image", formData);
      if (rs.created[0]?.url) {
        return rs.created[0].url;
      }
      return null;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleTypingStart = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.TYPING_START, {
        conversationId: conversation._id,
        userId: local.get('user_id')?.id,
        userType: SENDER_TYPE.SELLER
      });
    }
  };

  const handleTypingEnd = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.TYPING_END, {
        conversationId: conversation._id,
        userId: local.get('user_id')?.id,
        userType: SENDER_TYPE.SELLER
      });
    }
  };

  const handleInputFocus = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.FOCUS, conversation._id);
    }
  };

  const handleInputBlur = () => {
    if (socketInstance && conversation?._id) {
      socketInstance.emit(SOCKET_EVENTS.BLUR, conversation._id);
    }
  };

  const handleSelectOrder = (order: any) => {
    if (socketInstance && conversation?._id && userInfo?.id && conversation?.userId) {
      const orderInfo = {
        orderId: order.id,
        image: order.image,
        price: order.totalMoney,
        orderStatus: order.status,
        orderItemInfos: order.orderItemInfos
      };

      const messageData = createMessage(
        `Order #${order.id}`,
        MESSAGE_TYPE.ORDER,
        conversation._id,
        conversation.userId,
        undefined,
      );
      messageData.orderInfo = orderInfo;

      socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

      const newMessage = createLocalMessage(
        `Order #${order.id}`,
        MESSAGE_TYPE.ORDER,
        userInfo.id,
        conversation.userId,
        undefined,
        orderInfo
      );

      setMessages(prev => [...prev, newMessage]);
      scrollToBottom(messageListRef);
    }
    setShowOrderModal(false);
  };

  const handleSelectProduct = (product: any) => {
    if (socketInstance && conversation?._id && userInfo?.id && conversation?.userId) {
      const productInfo = {
        productTypeId: product.id,
        name: product.name,
        image: product.thumbnail || product.images?.[0],
        price: product.paymentCash,
        originalPrice: product.originPrice
      };

      const messageData = createMessage(
        `${product.name}`,
        MESSAGE_TYPE.PRODUCT,
        conversation._id,
        conversation.userId,
        undefined,
        undefined,
        productInfo
      );

      socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

      const newMessage = createLocalMessage(
        `${product.name}`,
        MESSAGE_TYPE.PRODUCT,
        userInfo.id,
        conversation.userId,
        undefined,
        undefined,
        productInfo
      );

      setMessages(prev => [...prev, newMessage]);
      scrollToBottom(messageListRef);
    }
    setShowProductModal(false);
  };

  return (
    <ChatWindow visible={visible}>
      <ChatHeader>
        <UserInfoContainer>
          <UserInfo>
            <Avatar icon={<UserOutlined />} src={userInfo?.avatar} size={32} />
            <span className="username">{userInfo?.name}</span>
          </UserInfo>
        </UserInfoContainer>
        <CloseButton onClick={onClose}>×</CloseButton>
      </ChatHeader>

      <ChatContainer>
        <MessageList
          ref={messageListRef}
          messages={messages}
          loading={loading}
          userAvatar={userInfo?.avatar}
          userName={userInfo?.name}
          expandedMessages={expandedMessages}
          onExpandMessage={handleExpandMessage}
          onImageClick={handleImageClick}
          locale="en"
        />

        {isTyping && (
          <TypingIndicator>
            <span className="word">Is</span>
            <span className="word">typing</span>
            <span className="word">...</span>
          </TypingIndicator>
        )}

        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
        </Modal>

        <ChatInput
          onSendMessage={handleSendMessage}
          onImageUpload={handleImageUpload}
          onTypingStart={handleTypingStart}
          onTypingEnd={handleTypingEnd}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          showOrderButton={true}
          onOrderButtonClick={() => setShowOrderModal(true)}
          showProductButton={true}
          onProductButtonClick={() => setShowProductModal(true)}
        />
      </ChatContainer>

      {showOrderModal && (
        <OrderModal
          visible={showOrderModal}
          onClose={() => setShowOrderModal(false)}
          onSelectOrder={handleSelectOrder}
          customerId={conversation?.userId}
        />
      )}

      {showProductModal && (
        <ProductModal
          visible={showProductModal}
          onClose={() => setShowProductModal(false)}
          onSelectProduct={handleSelectProduct}
        />
      )}
    </ChatWindow>
  );
};

export default ChatDialog;
